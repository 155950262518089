<template>
  <div class="cart__email-for-save-items-wrapper">
    <p class="cart__email-for-save-items-title mt-0">
      Enter Email to Save Cart for Later
    </p>
    <form @submit.prevent="onSubmit" ref="form">
      <div class="cart__email-for-save-items">
        <sib-form-item
          :style="`flex:1;margin-bottom:0`"
          label="Email"
          input-id="email-for-save-items"
          :value="email"
          is-required
          :validator="isEmail"
          ref="itemEmail"
          error-message="Email is invalid"
        >
          <sib-input
            id="email-for-save-items"
            size="sm"
            placeholder="Email to save cart"
            v-model="email"
            name="email"
          ></sib-input>
        </sib-form-item>

        <sib-button
          class="sib-button--width-auto"
          role="primary"
          :loading="loading"
          :disabled="!email"
        >
          Save
        </sib-button>

        <p
          v-show="message"
          class="cart__email-for-save-items-message"
          :class="{
            'cart__email-for-save-items-message--success':
              message.includes('successfully'),
            'cart__email-for-save-items-message--error':
              !message.includes('successfully'),
          }"
        >
          {{ message }}
        </p>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import useUserStore from '@/store/user'
import Analytics from '@/services/analytics'
import { ref, nextTick } from 'vue'
import Logger from '@/services/log'
import FormItem from '@/ui-kits/FormItem.vue'
import {
  FORM_ITEM_INVALID_SELECTOR,
  HEADER_HEIGHT,
  destinationIntegrations,
  isEmail,
  EVENT_TRACKING_CART_SAVED,
  MIXPANEL_DESTINATION,
} from '@/utils'
import SibFormItem from '@/ui-kits/FormItem.vue'
import LocationService, { UserLocation } from '@/services/location'
import Provider from '@/provider'

const userStore = useUserStore()

const loading = ref(false)
const email = ref('')
const itemEmail = ref<typeof FormItem | null>(null)
const form = ref<HTMLFormElement | null>(null)
const isSuccess = ref(false)
const message = ref('')

const userTraits = Analytics.getUserTraits()
if (userTraits?.email) {
  email.value = userTraits?.email
  const inputEmail = document.querySelector('#email-for-save-items')
  if (inputEmail) {
    inputEmail.value = email.value
    const event = new Event('input')
    inputEmail.dispatchEvent(event)
  }
}

async function onSubmit() {
  message.value = ''
  const isEmailValid = itemEmail.value?.validate()
  const isValid = isEmailValid
  if (!isValid) {
    await nextTick()
    const firstInvalidElement: HTMLElement | undefined | null =
      form.value?.querySelector(FORM_ITEM_INVALID_SELECTOR)
    if (firstInvalidElement) {
      window.scrollTo({
        top:
          window.scrollY +
          firstInvalidElement.getBoundingClientRect().top -
          HEADER_HEIGHT,
        behavior: 'smooth',
      })
    }
    return
  }
  if (!form.value) return

  try {
    loading.value = true
    await saveEmailForCart()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    isSuccess.value = true
    message.value = `Your cart has been successfully saved with the email ${email.value}`
  } catch (error: any) {
    message.value = `We encountered an issue saving your cart with the email ${email.value}`
    Logger.error('Error when save email for cart', error)
    Analytics.error(error)
    isSuccess.value = false
  }
  loading.value = false

  Analytics.track(
    EVENT_TRACKING_CART_SAVED,
    {
      email: email.value,
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}

async function saveEmailForCart() {
  if (!email.value) return
  LocationService.onLocationReady(async (location: UserLocation | null) => {
    if (location?.ip) {
      userStore.identifyUserByEmail(email.value, location.ip)
    }
  })
  const provider = await Provider.getInstance()
  await provider.subscribeUserToListKlaviyo({
    email: email.value,
  })
}
</script>
<style lang="scss">
.cart__email-for-save-items-wrapper {
  margin-bottom: 2rem;
  .cart__email-for-save-items {
    display: grid;
    grid-template-columns: 1fr 110px;
    gap: 0 1rem;
    .sib-form-item__label {
      display: none;
    }
    .sib-input {
      --input-padding: var(--input-md-padding);
      height: unset;
    }
    .sib-button {
      width: 110px;
    }
  }
  .cart__email-for-save-items-message {
    font-size: 12.25px;
    margin-top: 3px;
    line-height: 1;
    &--success {
      color: var(--color-primary);
    }
    &--error {
      color: var(--color-secondary);
    }
  }
  .cart__email-for-save-items-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block;
  }
  .cart__email-for-save-items
    .sib-form-item--invalid
    ~ .cart__email-for-save-items-message {
    display: none;
  }
}
</style>
