<template>
  <nav
    v-if="isShowSecondaryHeaderMenu"
    class="header__secondary-menu container app__child"
  >
    <div class="header__secondary-menu-items">
      <div
        class="header__secondary-menu-item"
        v-for="(item, index) of menuItems"
        :key="index"
      >
        <span class="menu__item-text" @click="showMenu(+item.position - 1)">
          <span @click="trackingClickSecondMenu(item)">{{ item.title }}</span>
          <MenuDownIcon class="menu__down-icon" />
        </span>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import useSettingStore from '@/store/setting'
import { computed } from 'vue'
import useComponentStore from '@/store/component'
import MenuDownIcon from '@/assets/images/icons/menu-down.svg'
import useDeviceStore from '@/store/device'
import { useRoute } from 'vue-router'
import Analytics from '@/services/analytics'
import {
  EVENT_TRACKING_CLICK_SECOND_MENU,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
} from '@/utils'
const settingStore = useSettingStore()
const componentStore = useComponentStore()
const deviceStore = useDeviceStore()
const route = useRoute()

const secondaryHeaderMenu = computed(
  () => settingStore.pages?.general?.secondaryHeaderMenu
)
const isShowSecondaryHeaderMenu = computed(
  () =>
    deviceStore.isMobile &&
    secondaryHeaderMenu.value?.isShow &&
    secondaryHeaderMenu.value?.items?.length
)

const menuItems = computed(() => secondaryHeaderMenu.value?.items || [])

function showMenu(position: number) {
  componentStore.setMenu({
    showingMenus: [0, position],
  })
}
function trackingClickSecondMenu(item: any) {
  // const currentPath = route.path
  // const menuName = item.title
  // Analytics.track(
  //   EVENT_TRACKING_CLICK_SECOND_MENU,
  //   {
  //     path: currentPath,
  //     name: menuName,
  //   },
  //   destinationIntegrations([MIXPANEL_DESTINATION])
  // )
}
</script>

<style lang="scss">
.header {
  &__secondary-menu {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1rem;
    border-top: solid 1px var(--border-color);
    border-bottom: solid 1px var(--border-color);
    &-items {
      display: flex;
      justify-content: space-between;
    }
    &-item {
      .menu {
        &__item-text {
          text-transform: none;
          font-size: 12px;
          letter-spacing: -0.36px;
          font-weight: normal;
        }
        &__down-icon {
          display: inline-block;
          margin-left: 4px;
        }
        &__icon {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
