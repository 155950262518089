import { Provider } from './provider'
import { getProvider } from './factory'
import {
  TIME_MARK_PROVIDER_START,
  MEASURE_NAME_PROVIDER_INITIATION,
} from '@/utils'

interface MainProviderFactory {
  instance: Provider | null
  initiating: boolean
  providerName: string
  initiationCallback: ((value: Provider | PromiseLike<Provider>) => void)[]
  init(): Promise<void>
  getInstance(): Promise<Provider>
  setProviderName(providerName: string): void
}

const mainProvider: MainProviderFactory = {
  instance: null,
  providerName: '',
  initiationCallback: [],
  initiating: false,

  async init(): Promise<void> {
    if (this.instance || this.initiating) {
      return
    }
    performance.mark(TIME_MARK_PROVIDER_START)
    this.initiating = true
    this.instance = await getProvider(this.providerName)
    this.initiationCallback.forEach((callback) => {
      callback(this.instance!)
    })
    this.initiationCallback = []
    this.initiating = false
    performance.measure(
      MEASURE_NAME_PROVIDER_INITIATION,
      TIME_MARK_PROVIDER_START
    )
  },

  setProviderName(providerName: string) {
    this.providerName = providerName
  },

  async getInstance(): Promise<Provider> {
    if (this.instance) return this.instance
    return new Promise((resolve) => {
      this.initiationCallback.push(resolve)
      this.init()
    })
  },
}

export default mainProvider
export { Provider } from './provider'
