import { defineStore } from 'pinia'
import Provider from '@/provider'
import { Collection, LandingPage } from '@/types'
import Logger from '@/services/log'
import { timeEnd, timeStart } from '@/utils/timing'
import Analytics from '@/services/analytics'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'

export interface SettingState {
  landingPage?: LandingPage
  isLandingPageNotFound: boolean
  collections: Collection[]
  campaignDonations: {
    campaignCode: string
    type: string
    all: {
      campaign_code: string
      campaign_name: string
      email: string
      order_name: string
      order_date: string
      total_amount: string
    }[]
  }[]
}

const useLandingPageStore = defineStore('landingPage', {
  state: (): SettingState => ({
    landingPage: undefined,
    isLandingPageNotFound: false,
    collections: [],
    campaignDonations: [],
  }),
  actions: {
    async getLandingPageByHandle({
      handle,
      isForceLoadNewData,
    }: {
      handle: string
      isForceLoadNewData?: boolean
    }) {
      timeStart('Get landing page by handle ' + handle)
      try {
        let landingPage = null
        const cacheKey = `landingPage_getLandingPageByHandle_${handle}`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const landingPageFromCache = getJSONfromCacheResponse(cacheResponse)

          // get landing page from cache first
          if (landingPageFromCache) {
            landingPage = landingPageFromCache
          }
        }

        // if can't get landing page from cache, get from provider
        if (!landingPage) {
          const provider = await Provider.getInstance()
          landingPage = await provider.getLandingPageByHandle(handle)

          if (landingPage) {
            CacheService.instance?.set(cacheKey, landingPage)
            landingPage.__cacheKey = cacheKey
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }

        timeEnd('Get landing page by handle ' + handle)
        return landingPage
      } catch (error: any) {
        Logger.error('Error on getLandingPageByHandle', { error, handle })
        Analytics.error(error)
        throw error
      }
    },

    // get and set landing page to store
    async fetchLandingPage({
      handle,
      isForceLoadNewData,
    }: {
      handle: string
      isForceLoadNewData?: boolean
    }) {
      this.landingPage = undefined
      this.isLandingPageNotFound = false
      const landingPage = await this.getLandingPageByHandle({
        handle,
        isForceLoadNewData,
      })
      this.landingPage = landingPage
      return landingPage
    },
  },
})

export default useLandingPageStore
