<template>
  <Popup
    class="search__popup"
    width="semi-full"
    :show="show"
    transition="slide-left"
    @update:show="togglePopup($event)"
  >
    <div class="search__input-bar">
      <span class="icon-wrapper" @click="togglePopup(false)">
        <ArrowLeftIcon class="search__back-icon" />
      </span>

      <sib-input
        ref="searchInput"
        @blur="searchInputBlur"
        v-model="searchQuery"
        size="md"
        round="md"
        placeholder="Search"
        :is-show-clear-button="true"
        @keyup.enter="redirectToSearchResultPage()"
      ></sib-input>
      <div class="search__loading" v-show="searching">
        <EllipsisLoading />
      </div>
    </div>
    <div
      class="search__section"
      v-if="!searchResult && recentlySearches.length"
    >
      <h3 class="search__section-header">Recent Searches</h3>
      <ul class="search__item-list">
        <li
          class="search__item"
          v-for="(item, index) of recentlySearches"
          :key="index"
        >
          <span @click="suggestionClick(item)"> {{ item }}</span>
        </li>
      </ul>
    </div>

    <div class="search__section">
      <h3 class="search__section-header">Popular Searches</h3>
      <ul class="search__item-list">
        <li
          class="search__item"
          v-for="(item, index) of poppularSearches"
          :key="index"
        >
          <span @click="suggestionClick(item)"> {{ item }}</span>
        </li>
      </ul>
    </div>

    <div class="search__section" v-if="!searchResult">
      <h3 class="search__section-header">Suggestions</h3>
      <FeaturedCollection
        @item-click="togglePopup(false)"
        :show-header="false"
        :items="featuredCollection"
      ></FeaturedCollection>
    </div>

    <div
      class="search__section"
      :class="{ hidden: !isSibSearch }"
      v-if="searchResult?.collections?.length"
    >
      <h3 class="search__section-header">Collections</h3>
      <ul class="search__item-list">
        <li
          class="search__item"
          v-for="(item, index) of searchResult?.collections?.slice(
            0,
            limitNumberOfItems
          )"
          :key="index"
        >
          <sib-link
            @click="onClickSearchResult(COLLECTION_TYPE, item.handle)"
            :to="item.handle"
            :title="item.title"
          >
            {{ item.title }}
          </sib-link>
        </li>
      </ul>
    </div>

    <div class="search__section" v-if="searchResult">
      <h3 class="search__section-header">Products</h3>
      <template v-if="searchResult.products?.length">
        <ProductGrid
          @item-click="onClickSearchResult(PRODUCT_TYPE, $event.handle)"
          :products="searchResult.products?.slice(0, limitNumberOfProducts)"
        ></ProductGrid>
        <sib-button role="primary" class="search__view-all-button">
          <sib-link
            :total-result-search="searchResult.totalProducts"
            @click="togglePopup(false)"
            :to="'/search?query=' + searchQuery"
            :title="SEARCH_RESULT_PAGE_TITLE"
          >
            View all {{ searchResult.totalProducts }} products
          </sib-link>
        </sib-button>
      </template>

      <div v-else class="search__no-product">No product match your search.</div>
    </div>

    <div class="search__section" v-if="searchResult?.pages?.length">
      <h3 class="search__section-header">Pages</h3>
      <ul class="search__item-list">
        <li
          class="search__item"
          v-for="(item, index) of searchResult?.pages?.slice(
            0,
            limitNumberOfItems
          )"
          :key="index"
        >
          <sib-link
            @click="onClickSearchResult(PAGE_TYPE, item.handle as string)"
            :to="item.handle"
            :title="item.title"
          >
            {{ item.title }}
          </sib-link>
        </li>
      </ul>
    </div>
  </Popup>
</template>

<script lang="ts" setup>
import ArrowLeftIcon from '@/assets/images/icons/arrow-left.svg'
import useSettingStore from '@/store/setting'
import { ref, computed, Ref, watch, onMounted } from 'vue'
import FeaturedCollection from '@/pages/home/FeaturedCollection.vue'
import useSearchStore from '@/store/search'
import { SearchResults } from '@/types'
import {
  debounce,
  EVENT_TRACKING_PRODUCTS_SEARCHED,
  getCurrentTimeStamp,
  CACHE_KEY_SEARCH_RECENTLY_SEARCHES,
  SEARCH_RESULT_PAGE_TITLE,
  EVENT_TRACKING_SEARCH_RESULT_CLICKED,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
  RESTRIC_VN_LOCATION_PRODUCT_TAGS,
  RESTRIC_TMN1_SEGMENT_PRODUCT_TAGS,
  TMN1_SEGMENT,
  VN_COUNTRY_CODE,
  SIB_SEARCH,
  SEARCHANISE,
} from '@/utils'
import ProductGrid from '../product/ProductGrid.vue'
import EllipsisLoading from '@/ui-kits/EllipsisLoading.vue'
import Input from '@/ui-kits/Input.vue'
import useDeviceStore from '@/store/device'
import Analytics from '@/services/analytics'
import CacheService from '@/services/cache'
import Popup from '@/ui-kits/Popup.vue'
import { useRouter } from 'vue-router'
import useRequestLocationStore from '@/store/requestLocation'
import useUserStore from '@/store/user'

const props = withDefaults(
  defineProps<{
    show: boolean
  }>(),
  {
    show: false,
  }
)

const emit = defineEmits<{
  (e: 'update:show', value: boolean): void
}>()

const router = useRouter()
const settingStore = useSettingStore()
const searchStore = useSearchStore()
const deviceStore = useDeviceStore()
const requestLocationStore = useRequestLocationStore()
const userStore = useUserStore()

const lastActionTimeStamp = ref(0)

const recentlySearches: Ref<string[]> = ref([])
const featuredCollection = computed(
  () => settingStore.pages?.homePage?.featuredCollection?.items
)
const defaultPopularSearches = computed(
  () => settingStore.pages?.search?.popularSearches || []
)

const limitNumberOfItems = 4
const limitNumberOfProducts = deviceStore.isMobile ? 6 : 10
const searchQuery = ref('')
const searchResult: Ref<SearchResults | null> = ref(null)
const searching = ref(false)
const poppularSearches = computed(() => {
  return (
    (searchResult.value?.suggestions?.length
      ? searchResult.value.suggestions
      : defaultPopularSearches.value) || []
  ).slice(0, 4)
})
const searchInput: Ref<typeof Input | null> = ref(null)

const COLLECTION_TYPE = 'collection'
const PAGE_TYPE = 'page'
const PRODUCT_TYPE = 'product'
const POPUP_COMPONENT = 'popup'

const isRequestVNLocation = computed(
  () => requestLocationStore.countryCode.toUpperCase() == VN_COUNTRY_CODE
)
const isSibSearch = computed(
  () => import.meta.env.VITE_SEARCH_TYPE == SIB_SEARCH
)

const isSearchanise = computed(
  () => import.meta.env.VITE_SEARCH_TYPE == SEARCHANISE
)

onMounted(async () => {
  const searchDebounce = debounce(search, 300)
  watch(searchQuery, searchDebounce)
  getRecentlySearches()
  watch(
    () => props.show,
    (value) => {
      if (value) {
        searchInput.value?.focus()
      }
    }
  )
})

async function search() {
  const timeStamp = getCurrentTimeStamp()
  lastActionTimeStamp.value = timeStamp
  searching.value = true
  if (!searchQuery.value) {
    searchResult.value = null
    searching.value = false
    return
  }
  const SORT_BY_QUERY_DEFAULT = 'sales_amount'
  const SORT_ORDER_QUERY_DEFAULT = 'desc'
  const start = performance.now()

  let restrictTags = isRequestVNLocation.value
    ? RESTRIC_VN_LOCATION_PRODUCT_TAGS.split(',')
        .map((tag) => `!${tag}`)
        .join(',')
    : ''

  if (
    isSearchanise.value &&
    !userStore.segments?.find((segment) => segment.key == TMN1_SEGMENT)
  ) {
    const restrictTMN1Segment = RESTRIC_TMN1_SEGMENT_PRODUCT_TAGS.split(',')
      .map((tag) => `!${tag}`)
      .join(',')
    restrictTags = restrictTags
      ? `${restrictTags},${restrictTMN1Segment}`
      : restrictTMN1Segment
  }

  const payload = {
    query: searchQuery.value,
    sortBy: SORT_BY_QUERY_DEFAULT,
    sortOrder: SORT_ORDER_QUERY_DEFAULT,
    restrictByTags: restrictTags || '',
  }
  const response = await searchStore.searchProduct(payload)
  const end = performance.now()
  const selfTime = end - start
  if (lastActionTimeStamp.value != timeStamp) return
  searching.value = false
  searchResult.value = response
  Analytics.track(EVENT_TRACKING_PRODUCTS_SEARCHED, {
    query: searchQuery.value,
    self_time: selfTime,
    number_of_result: searchResult.value.totalProducts,
    component: POPUP_COMPONENT,
  })
}

function searchInputBlur() {
  saveRecentlySearches()
}

function suggestionClick(item: string) {
  searchQuery.value = item
  saveRecentlySearches()
}

function saveRecentlySearches() {
  if (
    searchQuery.value &&
    !recentlySearches.value.includes(searchQuery.value)
  ) {
    if (recentlySearches.value.length >= limitNumberOfItems) {
      recentlySearches.value.pop()
    }
    recentlySearches.value.unshift(searchQuery.value)
    // cache recently search forever
    CacheService.instance?.set(
      CACHE_KEY_SEARCH_RECENTLY_SEARCHES,
      recentlySearches.value.join(','),
      -1
    )
  }
}

async function getRecentlySearches() {
  const recentlySearchesFromCache = await CacheService.instance?.get(
    CACHE_KEY_SEARCH_RECENTLY_SEARCHES
  )
  if (!recentlySearchesFromCache?.value) return
  recentlySearches.value = recentlySearchesFromCache.value.split(',')
}

function redirectToSearchResultPage() {
  togglePopup(false)
  router.push(`/search?query=${searchQuery.value}`)
}

function togglePopup(show: boolean) {
  emit('update:show', show)
}

function onClickSearchResult(type: string, url: string) {
  togglePopup(false)
  // Analytics.track(
  //   EVENT_TRACKING_SEARCH_RESULT_CLICKED,
  //   {
  //     query: searchQuery.value,
  //     component: POPUP_COMPONENT,
  //     type: type,
  //     target: window.location.origin + url,
  //   },
  //   destinationIntegrations([MIXPANEL_DESTINATION])
  // )
}
</script>

<style lang="scss">
.search {
  &__loading {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    .lds-ellipsis {
      --lds-ellipsis: 6px;
      --lds-ellipsis-top: 3px;
      height: 10px;
      position: absolute;
      top: 1em;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__input-bar {
    display: flex;
    gap: 1em;
    align-items: center;
    padding: 1em 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    border-bottom: solid 1px var(--border-color);
  }

  &__back-icon {
    width: 1.5em;
    cursor: pointer;

    path {
      fill: currentColor;
      stroke: currentColor;
      stroke-width: 1px;
    }
  }

  &__section {
    margin-bottom: 1em;
    .product-grid {
      @include media-md-up {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1.5em 1em;
      }
      @include media-lg-up {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.3em 0.8em;
      }
      @include media-xl-up {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.3em 0.8em;
      }
      @include media-xxl-up {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.5em 1em;
      }
    }
  }

  &__section-header {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--text-color-tertiary);
    font-weight: normal;
    margin: 1em 0 0.5em 0;
  }

  &__item-list {
    padding-left: 0;
    list-style: none;
    margin: 0;
  }

  &__item {
    font-weight: 600;
    margin-bottom: 0.25em;
    span {
      cursor: pointer;
    }
  }

  &__view-all-button {
    max-width: 300px;
    margin: 1em auto;
    display: block;
  }

  .featured-collection {
    margin-top: 1em;
  }

  .right-side-popup__content {
    max-width: none;
  }

  @include media-md-up {
    .right-side-popup__content {
      width: 80%;
      padding: 0 2em 3em 2em;
    }
  }

  @include media-lg-up {
    .right-side-popup__content {
      width: 70%;
      max-width: 1500px;
      padding: 0 2em 3em 2em;
    }
  }
}
</style>
