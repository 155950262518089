import useComponentStore from '@/store/component'
import {
  PROMOTION_BAR_PRIORITY_HIGHEST,
  PROMOTION_BAR_TYPE_ANNOUNCEMENT,
  QUERY_STRING_PROMOTION_MESSAGE,
} from '@/utils'
import { useRoute } from 'vue-router'

export function usePromotionBar() {
  const route = useRoute()
  const componentStore = useComponentStore()
  function showPromotionBarByQueryString() {
    const promotionMessage = route.query[
      QUERY_STRING_PROMOTION_MESSAGE
    ] as string
    if (promotionMessage) {
      componentStore.addPromotionBarContent({
        type: PROMOTION_BAR_TYPE_ANNOUNCEMENT,
        priority: PROMOTION_BAR_PRIORITY_HIGHEST,
        value: promotionMessage,
      })
    }
  }
  return { showPromotionBarByQueryString }
}
