import { removeProtocol } from '.'

interface ImageSizedUrlWithPixelDensity {
  src: string
  pixelDensity: string
}

export function buildSizedImageUrlWithPixelDensity(
  imageUrl: string,
  size: number
): ImageSizedUrlWithPixelDensity[] {
  const result: ImageSizedUrlWithPixelDensity[] = [
    {
      src: getSizedImageUrl(imageUrl, buildSizeString(size)),
      pixelDensity: '',
    },
  ]
  if (!size) return result
  result.push({
    src: getSizedImageUrl(imageUrl, buildSizeString(size * 2)),
    pixelDensity: '2x',
  })
  result.push({
    src: getSizedImageUrl(imageUrl, buildSizeString(size * 2)),
    pixelDensity: '3x',
  })
  return result
}

export function getSizedImageUrl(src: string, size: string) {
  if (!size) {
    return src
  }

  if (size === 'master') {
    return removeProtocol(src)
  }

  const match = src.match(
    /\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i
  )

  if (match) {
    const prefix = src.split(match[0])
    const suffix = match[0]

    return removeProtocol(`${prefix[0]}_${size}${suffix}`)
  } else {
    return src
  }
}

export function buildSizeString(size: number) {
  if (!size) return ''
  return `${size}x`
}
