import { DiscountValueType } from '@/types/discount'
import {
  CACHE_KEY_RECENTLY_DISCOUNT_CODE_APPLIED,
  DISCOUNT_CONDITION_CART_ITEM_COUNT,
  DISCOUNT_CONDITION_CART_VALUE,
  DISCOUNT_MESSAGE_DEFAULT_PLACEHOLDER,
  DISCOUNT_VALUE_PLACEHOLDER,
  DISCOUNT_VALUE_TYPE_PRODUCT_FIXED_AMOUNT,
  DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE,
  DISCOUNT_CODE,
  SEVEN_DAYS_IN_MILLISECONDS,
  SEVEN_DAY_IN_SECONDS,
} from './constants'
import { formatPrice } from './product'
import { cloneDeep } from '.'
import { CookieManager } from '@/services/cookie'

export function buildDiscountMessage(
  discountValueType: DiscountValueType,
  discountValue: number,
  messagePlaceholder: string = DISCOUNT_MESSAGE_DEFAULT_PLACEHOLDER
) {
  return messagePlaceholder.replaceAll(
    DISCOUNT_VALUE_PLACEHOLDER,
    buildDiscountValue(discountValueType, discountValue)
  )
}

export function buildDiscountValue(
  discountValueType: DiscountValueType,
  discountValue: number
) {
  let value = ''
  switch (discountValueType) {
    case DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE:
    case DISCOUNT_VALUE_TYPE_PRODUCT_FIXED_AMOUNT:
      value = `${discountValue}%`
      break
    default:
      return discountValue.toString()
  }
  return value
}

export function buildMakerLabel(condition: string, value: number) {
  if (condition == DISCOUNT_CONDITION_CART_ITEM_COUNT) {
    return value + ' ' + (value > 1 ? 'items' : 'item')
  }
  if (condition == DISCOUNT_CONDITION_CART_VALUE) {
    return formatPrice(value)
  }
  return ''
}

export async function saveDiscountCodeToCache(
  discountCode: string,
  currentDomain: string,
  isRemove?: boolean
) {
  if (!discountCode) return
  const maxNumberOfItems = 5
  const recentlyDiscountCodesFromCache = await getRecentlyDiscountCodesApplied()
  const recentlyDiscountCodes = cloneDeep(recentlyDiscountCodesFromCache)
  const index = recentlyDiscountCodes.findIndex(
    (item) => item.toLowerCase() === discountCode.toLowerCase()
  )
  // add the discount code to the head of the list
  if (index == -1) {
    if (!isRemove) {
      recentlyDiscountCodes.unshift(discountCode)
    }
  } else {
    if (isRemove) {
      recentlyDiscountCodes.splice(index, 1)
    }
  }
  // limit the list to the max number of items
  if (recentlyDiscountCodes?.length >= maxNumberOfItems)
    recentlyDiscountCodes.splice(
      maxNumberOfItems,
      recentlyDiscountCodes.length - maxNumberOfItems
    )
  // save the list to the cache

  const cookier = new CookieManager()
  cookier.setCookie(
    CACHE_KEY_RECENTLY_DISCOUNT_CODE_APPLIED,
    recentlyDiscountCodes.join(','),
    {
      maxage: SEVEN_DAY_IN_SECONDS,
      domain: currentDomain,
      path: '/',
    }
  )
}

export async function getRecentlyDiscountCodesApplied() {
  const cookier = new CookieManager()
  const recentlyDiscountCodesApplied = cookier.getCookie(
    CACHE_KEY_RECENTLY_DISCOUNT_CODE_APPLIED
  )

  return recentlyDiscountCodesApplied
    ? recentlyDiscountCodesApplied.split(',')
    : []
}

export function buildGoogleDiscountMessage(
  discountCode: string,
  discountValue: number,
  messagePlaceholder: string
) {
  return messagePlaceholder
    .replaceAll(DISCOUNT_VALUE_PLACEHOLDER, discountValue.toString())
    .replaceAll(DISCOUNT_CODE, discountCode)
}
