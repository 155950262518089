import { isOnServer } from '@/utils/ssr'

export interface EventBusService {
  events: { [key: string]: ((...args: any[]) => any)[] }
  on(event: string, callback: (...args: any[]) => any): void
  off(event: string, callback: (...args: any[]) => any): void
  clear(event: string): void
  flush(event: string, payload: any): void
  trigger(event: string, payload?: any): void
}

// this service should not work on server to prevent memory leak
const EventBus: EventBusService = {
  events: {},
  on(eventName, callback) {
    if (isOnServer) return
    if (!this.events[eventName]) {
      this.events[eventName] = []
    }
    this.events[eventName].push(callback)
  },
  off(eventName, callback) {
    if (isOnServer) return
    if (!this.events[eventName]) {
      return
    }
    const index = this.events[eventName].indexOf(callback)
    if (index >= 0) {
      this.events[eventName].splice(index, 1)
    }
  },
  clear(eventName) {
    if (isOnServer) return
    this.events[eventName] = []
  },
  flush(eventName, payload) {
    if (isOnServer) return
    this.trigger(eventName, payload)
    this.events[eventName] = []
  },
  trigger(eventName, payload) {
    if (isOnServer) return
    if (!this.events[eventName]) {
      return
    }
    this.events[eventName].forEach((callback) => callback(payload))
  },
}

export default EventBus
