import { defineStore } from 'pinia'
import Provider from '@/provider'
import {
  SettingPage,
  SettingShop,
  LocationRestricted,
  ABTest,
  MainSettings,
  PopupSettings,
  Freshchat,
  ABTestSettings,
  PopupSetting,
  CampaignDiscount,
  TagDiscountCodeApplication,
  StyleSettings,
  SettingsType,
  ScriptSettings,
  ProductSettings,
} from '@/types'
import Logger from '@/services/log'
import {
  SETTING_TYPE_AB_TEST,
  SETTING_TYPE_MAIN,
  SETTING_TYPE_POPUP,
  SETTING_TYPE_STYLE,
  SETTING_TYPE_SCRIPT,
  SETTING_TYPE_PRODUCT_PAGE,
  SETTING_TYPE_PRODUCT_LANDING_PAGE,
  SETTING_TYPE_CAMPAIGN_DISCOUNT,
} from '@/utils'
import { UserSegments } from '@/types/user'
import { PromotionBarSetings } from '@/types/promotionBar'

export interface SettingState {
  shop?: SettingShop
  pages?: SettingPage
  locationRestricted?: LocationRestricted
  popups?: PopupSetting[]
  freshchat?: Freshchat
  abTests?: ABTest[]
  styleSettings?: StyleSettings
  scriptSettings?: ScriptSettings
  campaigns?: CampaignDiscount[]
  tagDiscountCodes?: TagDiscountCodeApplication[]
  freeShippingMessage: string
  productSKUsExcludeCampaignDiscount?: string
  userSegments?: UserSegments
  promotionBar?: PromotionBarSetings
  productPage?: ProductSettings
  productLandingPage?: ProductSettings
}

const useSettingStore = defineStore('setting', {
  state: (): SettingState => ({
    shop: undefined,
    pages: undefined,
    locationRestricted: undefined,
    popups: undefined,
    freshchat: undefined,
    abTests: undefined,
    campaigns: undefined,
    tagDiscountCodes: undefined,
    freeShippingMessage: '',
    productSKUsExcludeCampaignDiscount: '',
    styleSettings: undefined,
    scriptSettings: undefined,
    userSegments: undefined,
    promotionBar: undefined,
    productPage: undefined,
    productLandingPage: undefined,
  }),
  getters: {
    tmProductTags(state) {
      return state.shop?.productTagsTM || []
    },
  },
  actions: {
    async loadSettings() {
      const provider = await Provider.getInstance()
      const settings = await provider.getSettings()
      Logger.log('Store load settings', settings, 2)

      const mainSettings = findSettingByType(settings, SETTING_TYPE_MAIN) as
        | MainSettings
        | undefined

      if (mainSettings) {
        this.shop = mainSettings.shop
        this.pages = mainSettings.pages
        this.locationRestricted = mainSettings.locationRestricted
        this.freshchat = mainSettings.freshchat
        this.userSegments = mainSettings.userSegments
        this.promotionBar = mainSettings.promotionBar
      }

      const popupSettings = findSettingByType(settings, SETTING_TYPE_POPUP) as
        | PopupSettings
        | undefined
      if (popupSettings) {
        this.popups = popupSettings.popups
      }

      const abTestSettings = findSettingByType(
        settings,
        SETTING_TYPE_AB_TEST
      ) as ABTestSettings | undefined
      if (abTestSettings) {
        this.abTests = abTestSettings.abTests
      }

      const styleSettings = findSettingByType(settings, SETTING_TYPE_STYLE) as
        | StyleSettings
        | undefined
      if (styleSettings) {
        this.styleSettings = styleSettings
      }

      const scriptSettings = findSettingByType(
        settings,
        SETTING_TYPE_SCRIPT
      ) as ScriptSettings | undefined

      if (scriptSettings) {
        this.scriptSettings = scriptSettings
      }

      const productPageSettings = findSettingByType(
        settings,
        SETTING_TYPE_PRODUCT_PAGE
      ) as ScriptSettings | undefined

      if (productPageSettings) {
        this.productPage = productPageSettings?.productPage
      }

      const productLandingPageSettings = findSettingByType(
        settings,
        SETTING_TYPE_PRODUCT_LANDING_PAGE
      ) as ScriptSettings | undefined

      if (productLandingPageSettings) {
        this.productLandingPage = productLandingPageSettings?.productLandingPage
      }

      const campaignDiscountSettings = findSettingByType(
        settings,
        SETTING_TYPE_CAMPAIGN_DISCOUNT
      ) as ScriptSettings | undefined

      if (campaignDiscountSettings) {
        this.campaigns = campaignDiscountSettings.campaignsDiscount?.campaigns
        this.freeShippingMessage =
          campaignDiscountSettings.campaignsDiscount.freeShippingMessage
        this.productSKUsExcludeCampaignDiscount =
          campaignDiscountSettings.campaignsDiscount.productSKUsExcludeCampaignDiscountBF9
        this.tagDiscountCodes =
          campaignDiscountSettings.campaignsDiscount.tagDiscountCodes
      }
    },
  },
})

function findSettingByType(settings: Array<SettingsType>, type: string) {
  return settings.find((elm) => elm._type === type)
}

export default useSettingStore
