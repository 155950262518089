<template>
  <div class="carousel" ref="carouselElement" v-if="items && items.length">
    <div class="carousel__slide" v-for="(item, index) of items" :key="index">
      <slot v-bind="{ item, index }">
        <component
          :is="item.link ? 'sib-link' : 'div'"
          :to="item.link"
          :title="item.title"
          class="carousel__item"
          :referer="props.refProp"
        >
          <sib-media
            :item="item"
            :preload="preloadFirstMedia(index, item.mediaContentType)"
            :autoplay="index === 0"
            :mediaIndex="index"
            :selectedMediaIndex="$props.index"
          >
          </sib-media>
        </component>
      </slot>
    </div>
    <slot name="customSlide"></slot>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, Ref, ref, watch } from 'vue'
import type {
  Carousel as CarouselType,
  Fancybox as FancyboxType,
} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'
import { Image, CarouselOptions } from '@/types'
import { debounce, PRODUCT_MEDIA_VIDEO } from '@/utils'
import { computed, ComputedRef } from '@vue/reactivity'
import Logger from '@/services/log'

interface SlideItem {
  image: Image
  video?: {
    format: string
    mimeType: string
    url: string
  }
  mediaContentType?: string
  link?: string
  title?: string
  [key: string]: any
}

interface CarouselProps {
  items: SlideItem[]
  index?: number
  preloadFirstSlide?: boolean
  options?: CarouselOptions
  breakpoints?: {
    [key: number]: CarouselOptions
  }
  disableSwipeSlides?: number[]
  refProp?: any
}

const props = withDefaults(defineProps<CarouselProps>(), {
  items: () => [],
  index: 0,
  options: () => ({}),
})

const emit = defineEmits(['slideChange'])

defineExpose({
  update,
})

let Fancybox: FancyboxType | null = null
let Carousel: CarouselType | null = null

const carouselOptions = computed(() => {
  return mergeOptions(props.options)
})

const breakpoints: ComputedRef<number[]> = computed(() => {
  const keys = Object.keys(props.breakpoints || {})
  keys.sort((a, b) => +b - +a)
  return keys.map((key) => +key)
})

const carouselElement = ref(null)
const carouselInstance: Ref<any> = ref(null)
const currentBreakpoint = ref(0)
onMounted(async () => {
  const FancyApp = await import('@fancyapps/ui')
  Carousel = FancyApp.Carousel
  Fancybox = FancyApp.Fancybox
  if (carouselElement.value) {
    if (props.breakpoints) {
      currentBreakpoint.value = findBreakpoint()
      if (props.breakpoints[currentBreakpoint.value] === false) return
      window.addEventListener('resize', debounce(onResize, 100))
    }

    let options = carouselOptions.value
    if (currentBreakpoint.value > 0) {
      options = mergeOptions(props.breakpoints![currentBreakpoint.value])
    }

    carouselInstance.value = new Carousel(carouselElement.value, options)

    if (carouselInstance.value) {
      carouselInstance.value.slideTo(props.index)
      const foundVideoMedia = props.items.find(
        (item) => item?.image?.mediaContentType === 'VIDEO'
      )
      if (foundVideoMedia) {
        const foundVideoIndex = props.items.indexOf(foundVideoMedia)

        const olElement = carouselElement.value.querySelector('ol')
        if (olElement) {
          const liElements = olElement.querySelectorAll('li')

          if (liElements.length > foundVideoIndex) {
            const liAtIndex = liElements[foundVideoIndex]
            liAtIndex.classList.replace('carousel__dot', 'play-icon')
          }
        }
      }
    }
  }
  Fancybox.bind('[data-fancybox="productSlider"]')

  watch(
    () => props.index,
    () => {
      if (carouselInstance.value) {
        carouselInstance.value.slideTo(props.index)
      }
    }
  )
})

onUnmounted(() => {
  if (carouselInstance.value) {
    carouselInstance.value.destroy()
  }
  window.removeEventListener('resize', onResize)
})

function findBreakpoint() {
  return (
    breakpoints.value.find((breakpoint) => {
      return document.body.clientWidth >= +breakpoint
    }) || 0
  )
}

function mergeOptions(options: CarouselOptions) {
  return Object.assign(
    {
      on: {
        change: (carousel: any) => {
          slideChange(carousel.page)
          emit('slideChange', carousel.page)
        },
      },
      friction: 0.8,
    },
    options || {},
    { initialPage: props.index }
  )
}

function onResize() {
  if (!carouselElement.value) return
  const targetBreakpoint = findBreakpoint()
  if (currentBreakpoint.value == targetBreakpoint) return
  currentBreakpoint.value = targetBreakpoint

  let options: CarouselOptions
  if (targetBreakpoint) {
    options = (props.breakpoints || {})[targetBreakpoint]
  } else {
    options = carouselOptions.value
  }

  for (const [key, option] of Object.entries(options)) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      carouselInstance.value.options[key] = option
    }
  }

  carouselInstance.value.updatePage()
}

function slideChange(index: number) {
  if (!props.disableSwipeSlides || !props.disableSwipeSlides.length) return
  if (props.disableSwipeSlides.includes(index)) {
    disableSwipe()
  } else {
    enableSwipe()
  }
}

function enableSwipe() {
  if (carouselInstance.value?.Panzoom?.options) {
    carouselInstance.value.Panzoom.options.touch = true
  }
}

function disableSwipe() {
  if (carouselInstance.value?.Panzoom?.options) {
    carouselInstance.value.Panzoom.options.touch = false
  }
}

function preloadFirstMedia(index: number, mediaContentType: string) {
  if (mediaContentType === PRODUCT_MEDIA_VIDEO) {
    return index === 0 ? 'auto' : 'none'
  }
  return index === 0 && props.preloadFirstSlide
}

function update() {
  Logger.log('Carousel', 'update', 2)
  carouselInstance.value?.updatePage()
}
</script>

<style lang="scss">
.carousel {
  width: 100%;
  display: flex;

  &:not(.has-dots) {
    overflow: hidden;
  }

  &__viewport {
    width: 100%;
  }

  &__slide {
    padding: 0;
  }

  &.is-dragging {
    .carousel__slide {
      pointer-events: none;
    }
  }

  &__slide,
  &__image {
    max-width: 100%;
  }

  &__image {
    line-height: 0;

    &--video {
      img {
        object-fit: contain;
        aspect-ratio: 1/1;
        background: var(--background-color);
      }
    }
  }

  &__video {
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  &__button {
    z-index: 2;

    &.is-next {
      right: 0;
    }

    &.is-prev {
      left: 0;
    }

    &:hover {
      --carousel-button-bg: rgba(0, 0, 0, 0.5);
    }
  }

  &__dots {
    .carousel__dot {
      width: 14px;
      height: 18px;
      color: var(--text-color-tertiary);
      opacity: 1;

      &::after {
        width: 7px;
        height: 7px;
      }

      &.is-selected {
        color: var(--color-primary);
      }

      &:nth-child(n + 9) {
        display: none;
      }
    }
  }

  &.has-dots {
    margin-bottom: var(--carousel-margin-bottom);
  }
  .play-icon {
    width: 11px;
    height: 8px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 7px solid var(--text-color-tertiary);
    margin-top: 5px;
    position: relative;
    opacity: 0.25;

    &.is-selected {
      border-left-color: var(--color-primary);
      opacity: 1;
    }
  }
}

@include media-sm-down {
  .fancybox__content {
    width: inherit !important;
    height: inherit !important;
  }
}
</style>
