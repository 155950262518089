import { defineStore } from 'pinia'
import { NullOrType, Page } from '@/types'
import Logger from '@/services/log'
import Provider from '@/provider'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import Analytics from '@/services/analytics'
export interface PageState {
  page: NullOrType<Page>
  isPageNotFound: boolean
}

const usePageStore = defineStore('page', {
  state: (): PageState => ({
    page: null,
    isPageNotFound: false,
  }),

  actions: {
    resetPageState() {
      this.page = null
      this.isPageNotFound = false
    },
    async getPageByHandle({
      handle,
      isForceLoadNewData,
    }: {
      handle: string
      isForceLoadNewData?: boolean
    }) {
      this.resetPageState()
      try {
        let page = null
        const cacheKey = `article_getPageByHandle_${handle}`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const pageFromCache = getJSONfromCacheResponse(cacheResponse)

          if (pageFromCache) {
            page = pageFromCache
          }
        }

        if (!page) {
          const provider = await Provider.getInstance()
          page = await provider.getPageByHandle(handle)

          // if (page) {
          //   CacheService.instance?.set(cacheKey, page)
          //   page.__cacheKey = cacheKey
          // } else {
          //   CacheService.instance?.delete(cacheKey)
          // }
        }

        this.page = page
        return page
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error on getPageByHandle', { error, handle })
        throw error
      }
    },
  },
})

export default usePageStore
