import { DefineComponent } from 'vue'
import DividerVue from './Divider.vue'
import BreadCrumbVue from './BreadCrumb.vue'

interface GlobalComponents {
  [key: string]: DefineComponent<any, any, any>
}
// define these components globally not only for use in multiple components
// but also prevent vite split them into single tiny build file that increase number of request on page load
// these following components should be small and usually be used above the fold
const components: GlobalComponents = {
  'sib-divider': DividerVue,
  'sib-breadcrumb': BreadCrumbVue,
}

export default components
