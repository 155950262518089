<template>
  <Teleport to="body" :disabled="!teleport">
    <div v-bind="$attrs" :class="classes">
      <div class="popup__content" ref="content">
        <slot></slot>
      </div>
      <div
        class="popup__backdrop"
        @click="clickOutsideClose && $emit('update:show', false)"
      ></div>
    </div>
  </Teleport>
</template>
<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script lang="ts" setup>
import useDeviceStore from '@/store/device'
import { computed } from '@vue/reactivity'
import { onMounted, ref, watch } from 'vue'
import { PopupPosition, PopupTransition } from './types'

type PopupWidth = 'default' | 'semi-full' | 'full'

const props = withDefaults(
  defineProps<{
    clickOutsideClose?: boolean
    show: boolean
    position?: PopupPosition
    transition?: PopupTransition
    width?: PopupWidth
    teleport?: boolean
  }>(),
  {
    clickOutsideClose: true,
    show: false,
    position: 'fixed',
    width: 'default',
    teleport: true,
    transition: 'zoom',
  }
)
const baseClass = 'popup'
const classes = computed(() => {
  const result = [baseClass]
  if (props.show) {
    result.push(`${baseClass}--show`)
  }
  result.push(`${baseClass}--position-${props.position}`)
  result.push(`${baseClass}--${props.transition}`)
  result.push(`${baseClass}--width-${props.width}`)
  return result
})

const content = ref<HTMLDivElement | null>(null)
defineExpose({
  content,
})
const deviceStore = useDeviceStore()

onMounted(() => {
  const sibPopupID = 'sib-popup'
  // add class to body when there is atlease one popup showing
  watch(
    () => props.show,
    (show) => {
      const index = deviceStore.showingPopups.indexOf(sibPopupID)
      if (show) {
        if (index === -1) {
          deviceStore.showingPopups.push(sibPopupID)
        }
      } else {
        if (index !== -1) {
          deviceStore.showingPopups.splice(index, 1)
        }
      }
    },
    { immediate: true }
  )
})
</script>

<style lang="scss">
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  --transition-duration: var(--animation-duration);
  --transition-delay: calc(var(--transition-duration) * 2);
  transition: z-index 0s var(--transition-delay) ease-in-out;

  $S: &;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color var(--transition-duration)
      var(--transition-delay) ease-in-out;
    --transition-delay: var(--transition-duration);
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 768px;
    height: 100%;
    padding: 0 1em;
    background: #fff;
    overflow: auto;
    z-index: 1;
    opacity: 0;
    transition: transform var(--transition-duration) var(--transition-delay)
        ease-in-out,
      opacity 0s var(--transition-duration);
    --transition-delay: 0s;
  }

  &--width-semi-full {
    #{$S}__content {
      width: 93%;
      max-width: 1000px;

      @include media-md-up {
        width: 70%;
      }

      @include media-xl-up {
        width: 50%;
      }
    }
  }

  &--slide-left {
    #{$S}__content {
      transform: translate3d(100%, 0, 0);
    }
  }

  &--slide-right {
    #{$S}__content {
      transform: translate3d(-100%, 0, 0);
    }
  }

  &--slide-up {
    #{$S}__content {
      transition: transform var(--transition-duration) var(--transition-delay)
          ease-in-out,
        opacity var(--transition-duration) 0s !important;
      transform: translate3d(0, 20%, 0);
      height: auto;
    }
  }

  &--slide-down {
    #{$S}__content {
      transition: transform var(--transition-duration) var(--transition-delay)
          ease-in-out,
        opacity var(--transition-duration) 0s !important;
      transform: translate3d(0, -20%, 0);
      height: auto;
    }
  }

  &--zoom {
    #{$S}__content {
      height: unset;
      overflow: unset;
      padding: 0;
      transition: transform var(--transition-duration),
        opacity var(--transition-duration) ease-in-out;
      @include media-md-up {
        right: 50%;
        top: 50%;
        width: unset;
        max-width: unset;
        transform: translate(50%, -50%) scale(0.7);
      }
      @include media-md-down {
        bottom: 0;
        right: 0;
        transform: scale(0.7);
      }
    }
  }

  &--zoom-default {
    #{$S}__content {
      @include media-md-down {
        bottom: unset;
        right: 14px;
        left: 14px;
        top: 50%;
        width: calc(100% - 2rem);
        transform: scale(0.7) translateY(-50%);
      }
    }
  }

  &--show {
    z-index: var(--popup-z-index);
    pointer-events: all;
    --transition-delay: 0s;

    #{$S}__backdrop {
      background-color: rgba(0, 0, 0, 0.5);
      --transition-delay: 0s;
    }

    #{$S}__content {
      transition: transform var(--transition-duration) var(--transition-delay)
          ease-in-out,
        opacity 0s 0s;
      transform: translate3d(0, 0, 0);
      opacity: 1;
      --transition-delay: 0s;
    }

    &#{$S}--zoom {
      #{$S}__content {
        opacity: 1;
        transform: translate(50%, -50%) scale(1);
        transition: transform var(--transition-duration),
          opacity var(--transition-duration) ease-in-out;
        @include media-md-down {
          transform: scale(1);
        }
      }
    }

    &#{$S}--zoom-default {
      #{$S}__content {
        @include media-md-down {
          transform: scale(1) translateY(-50%);
        }
      }
    }
  }

  &--position-absolute {
    position: absolute;
  }
}
</style>
