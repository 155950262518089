import { isOnServer } from '@/utils/ssr'

const logStyleBrowser: any = {
  level1: [
    'color: #fff',
    'background-color: #5b31f4',
    'padding: 2px 4px',
    'border-radius: 2px',
  ],
  level2: [
    'color: #fff',
    'background-color: #d504f8',
    'padding: 2px 4px',
    'border-radius: 2px',
  ],
  level3: [
    'color: #fff',
    'background-color: #38ba72',
    'padding: 2px 4px',
    'border-radius: 2px',
  ],
  error: [
    'color: #fff',
    'background-color: #eb2d1a',
    'padding: 2px 4px',
    'border-radius: 2px',
  ],
  warning: [
    'color: #e64a19',
    'background-color: #ffb300',
    'padding: 2px 4px',
    'border-radius: 2px',
  ],
}

// https://stackoverflow.com/a/50325607/4254681
const logStyleNodejs: any = {
  level1: [
    '\x1b[37m', // FgWhite
    '\x1b[44m', // BgBlue
  ],
  level2: [
    '\x1b[37m', // FgWhite
    '\x1b[45m', // BgMagenta
  ],
  level3: [
    '\x1b[37m', // FgWhite
    '\x1b[42m', // BgGreen
  ],
  error: [
    '\x1b[37m', // FgWhite
    '\x1b[41m', // BgRed
  ],
  warning: [
    '\x1b[31m', // FgRed
    '\x1b[43m', // BgYellow
  ],
}

type LogLevel = number | 'error' | 'warning'

const transformLogArguments = (args: any[]) => {
  if (!args) return []
  return args.map((arg) => {
    if (Array.isArray(arg)) {
      return 'Array'
    }
    if (typeof arg === 'object') {
      return transformLogArgument(arg, true)
    }
    return arg
  })
}

function transformLogArgument(arg: any, isShowChild?: boolean): any {
  if (arg instanceof Error) {
    return arg
  }
  if (Array.isArray(arg)) {
    if (!isShowChild) return 'Array'
    let result = ''
    arg.forEach((element, index) => {
      result += `\n  ${index}: ${transformLogArgument(element)}`
    })
    return `[
      ${result}
    ]`
  }
  if (typeof arg === 'object') {
    if (!isShowChild) return 'Object'
    let numberOfKeys = 0
    const maxNumberOfKeys = 5
    if (Object.keys(arg).length === 0) {
      return arg
    }
    let result = '{'
    for (const key in arg) {
      if (Object.prototype.hasOwnProperty.call(arg, key)) {
        if (numberOfKeys > maxNumberOfKeys) {
          result += '\n ...'
          break
        }
        const element = arg[key]
        numberOfKeys++
        result += `\n  ${key}: ${transformLogArgument(element)}`
      }
    }
    result += '\n}'
    return result
  }
  if (typeof arg === 'string') {
    const maxStringLength = 100
    if (!arg) return `""`
    if (arg.length > maxStringLength) {
      return `${arg.substring(0, maxStringLength)}...`
    }
  }
  return arg
}

function getLogStyle(level: LogLevel) {
  let style = []
  const logStyle = isOnServer ? logStyleNodejs : logStyleBrowser
  const spliter = isOnServer ? ' ' : ';'
  style = logStyle[level] || logStyle['level' + level] || logStyle.level3
  return style.join(spliter)
}

const Logger = {
  logLevel: 0,
  logFunction: console.log,
  setLogLevel(level: number) {
    this.logLevel = level
    console.superLog = this.logFunction
    if (!import.meta.env.DEV && level <= 2) {
      // disable all the other logs. Fuck customily
      console.log = () => {}
      console.warn = () => {}
    }
  },
  log(...args: any[]) {
    let level: LogLevel = 1
    if (typeof args[args.length - 1] === 'number') {
      level = args.pop()
    } else if (
      args[args.length - 1] === 'error' ||
      args[args.length - 1] === 'warning'
    ) {
      level = args.pop()
    }

    // Only print the log that have higher or equal level or error logs
    if (
      this.logLevel >= level ||
      level === 'error' ||
      (level === 'warning' && this.logLevel >= 1) ||
      (level == 1 && isOnServer)
    ) {
      let label = 'Log level ' + level
      const logStyle = getLogStyle(level)

      if (args.length >= 1) {
        label = args.shift()
      }

      if (isOnServer) {
        this.logFunction(
          logStyle,
          label,
          '\x1b[0m',
          ...transformLogArguments(args)
        )
      } else {
        this.logFunction(`%c${label}`, logStyle, ...args)
        console.groupCollapsed('stack trace')
        console.trace()
        console.groupEnd()
      }
    }
  },
  error(...args: any[]) {
    return this.log(...args, 'error')
  },
  warn(...args: any[]) {
    return this.log(...args, 'warning')
  },
}

export default Logger
