export const TIME_MARK_CSR_LOAD_PAGE = 'csr:load-page'
export const TIME_MARK_CART_START_LOADING = 'cart:start-loading'
export const TIME_MARK_CUSTOMILY_START_LOADING = 'customily:start-loading'
export const TIME_MARK_CUSTOMILY_LOADED = 'customily:loaded'
export const TIME_MARK_CUSTOMER_PERSONALIZED = 'customer:personalized'
export const TIME_MARK_SHOW_PRODUCT_DESCRIPTION = 'product-description:show'
export const TIME_MARK_SHOW_PRODUCT_RECENTLY_VIEWED =
  'product-recently-viewed:show'
export const TIME_MARK_SHOW_PRODUCT_RECOMMENDATION =
  'product-recommendation:show'
export const TIME_MARK_SHOW_PRODUCT_REVIEWS = 'product-reviews:show'
export const TIME_MARK_SHOW_PRODUCT_SHIPPING = 'product-shipping:show'
export const TIME_MARK_CSR_ANALYTICS_START = 'analytics:start'
export const TIME_MARK_PROVIDER_START = 'provider:start'
export const TIME_MARK_LOCATION_START = 'location:start'

export const MEASURE_NAME_CART_LOADED = 'Cart loaded'
export const MEASURE_NAME_CUSTOMILY_START_LOADING = 'Customily start loading'
export const MEASURE_NAME_CUSTOMILY_LOADED = 'Customily loaded'
export const MEASURE_NAME_CUSTOMER_PERSONALIZED = 'Customer personalized'
export const MEASURE_NAME_CUSTOMER_PREVIEW_PERSONALIZATION =
  'Customer preview personalization'
export const MEASURE_NAME_PRODUCT_DESCRIPTION_LOADED =
  'Product description loaded'
export const MEASURE_NAME_PRODUCT_RECENTLY_VIEWED_LOADED =
  'Product recently viewed loaded'
export const MEASURE_NAME_PRODUCT_RECOMMENDATION_LOADED =
  'Product recommendation loaded'
export const MEASURE_NAME_PRODUCT_REVIEWS_LOADED = 'Product reviews loaded'
export const MEASURE_NAME_PRODUCT_SHIPPING_LOADED = 'Product shipping loaded'
export const MEASURE_NAME_ANALYTICS_LOADED = 'Analytics loaded'

export const MEASURE_NAME_PROVIDER_INITIATION = 'Provider initiation'
export const MEASURE_NAME_LOCATION_INITIATION = 'Location initiation'
