import { createSSRApp, createApp as createClientApp } from 'vue'
import { App as VueApp } from '@vue/runtime-core'
import App from './App.vue'
import ClientOnly from '@duannx/vue-client-only'
import { createRouter } from './router'
import { createPinia } from 'pinia'
import useDeviceStore from './store/device'
import useRequestStore from './store/requestLocation'
import useRouteStore from './store/route'

import UIKits from './ui-kits'
import GlobalComponents from './components'
import Logger from './services/log'
import { isOnServer } from './utils/ssr'
import { createCookier } from './services/cookie'
import { getDomainName } from './utils/http'
import Analytics from './services/analytics'

export function createApp({
  isMobile = false,
  isPhone = false,
  logLevel = 0,
  url = '',
  siteSettings = null,
  cookies = '',
  countryCode = '',
}: {
  isMobile?: boolean
  isPhone?: boolean
  logLevel?: number
  url?: string
  siteSettings?: any
  cookies?: string
  countryCode?: string
}) {
  Logger.setLogLevel(logLevel || +(import.meta.env.VITE_LOG_LEVEL || 0))

  let app: VueApp
  const isClientSideRendering = import.meta.env.VITE_IS_CSR == '1'
  if (import.meta.env.SSR && isClientSideRendering) {
    Logger.error(
      'Error: wrong config',
      'SSR and CSR cannot be both enabled. VITE_IS_CSR cannot be set to 1 on SSR mode'
    )
  }

  // create app instance
  if (import.meta.env.DEV && !import.meta.env.SSR && isClientSideRendering) {
    app = createClientApp(App)
  } else {
    app = createSSRApp(App)
  }

  // handle app error
  app.config.errorHandler = (err, instance, info) => {
    Analytics.error(err)
    Logger.error('App error', err)
  }

  const router = createRouter()
  const store = createPinia()
  const cookier = createCookier(cookies)

  app.use(router)
  app.use(store)
  app.use(cookier)

  // set initial state for some stores
  // don't set any store in client side. It will cause bug that pinia can not hydrate the data from server
  if (isOnServer) {
    // set initial state for device store
    const deviceStore = useDeviceStore(store)
    deviceStore.isMobile = isMobile
    deviceStore.isPhone = isPhone

    // set initial state for route store
    const routeStore = useRouteStore(store)
    const urlObject = new URL(url)
    routeStore.currentUrl = url
    routeStore.currentOrigin = urlObject.origin
    routeStore.currentDomain = getDomainName(urlObject.hostname)

    // set initial state for request location store
    const requestLocation = useRequestStore(store)
    requestLocation.countryCode = countryCode

    // set initial state for setting store
    if (siteSettings) {
      store.state.value.setting = siteSettings
    }
  }

  // register uikit components
  for (const key in UIKits) {
    if (Object.prototype.hasOwnProperty.call(UIKits, key)) {
      const component = UIKits[key]
      app.component(key, component)
    }
  }

  // register global components
  for (const key in GlobalComponents) {
    if (Object.prototype.hasOwnProperty.call(GlobalComponents, key)) {
      const component = GlobalComponents[key]
      app.component(key, component)
    }
  }
  app.component('client-only', ClientOnly)
  return { app, router, store, cookier }
}
