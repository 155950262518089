import Provider from '@/provider'
import Logger from '@/services/log'
import useSettingStore from '@/store/setting'
import {
  isTruelyValue,
  QUERY_STRING_UTM_FB_FAKE_PURCHASE,
  QUERY_STRING_UTM_CAMPAIGN,
  QUERY_STRING_FBCLID,
} from '@/utils'
import { useRoute } from 'vue-router'

export function useFakeFacebookPixel() {
  const route = useRoute()
  const settingStore = useSettingStore()

  async function checkShouldFakeFBSale() {
    try {
      const fakeFbPurchaseQueryString =
        route.query[QUERY_STRING_UTM_FB_FAKE_PURCHASE]
      const utmCampaign = route.query[QUERY_STRING_UTM_CAMPAIGN]
      const fbclid = route.query[QUERY_STRING_FBCLID]
      // only fake sale if exists the query string
      if (!fbclid || !utmCampaign || !isTruelyValue(fakeFbPurchaseQueryString))
        return

      const key = `fb_${utmCampaign}`
      const provider = await Provider.getInstance()
      const count = await provider.getKeyCount(key)

      const firstTimeNumber =
        settingStore.shop?.fakeFbPixel?.firstTimeNumber || 10
      const nextTimeInterval =
        settingStore.shop?.fakeFbPixel?.nextTimeInterval || 20
      const maxTimes = settingStore.shop?.fakeFbPixel?.maxTimes || 11
      const maxNumber = firstTimeNumber + nextTimeInterval * (maxTimes - 1)
      const maxTrackingTimes =
        settingStore.shop?.fakeFbPixel?.maxTrackingTimes || 40

      // Choose the number based on the count value returned from the server
      if (
        count &&
        count <= maxNumber &&
        (count - firstTimeNumber) % nextTimeInterval == 0
      ) {
        let times = 0
        let tracking = false
        const actions = ['keydown', 'click', 'touchstart', 'scroll']

        // eslint-disable-next-line
        function fakeFBTracking() {
          if (tracking || !window.fbq) return
          if (times > maxTrackingTimes) {
            actions.forEach((type) => {
              window.removeEventListener(type, fakeFBTracking)
            })
          }
          times++
          tracking = true
          // debounce 50 ms between each run
          setTimeout(() => {
            tracking = false
          }, 50)

          window.fbq('track', 'Purchase', {
            value: 27.95 * times,
            currency: 'USD',
            content_type: 'product',
            contents: [
              {
                id: 7159466459310,
                quantity: times,
                currency: 'USD',
                item_price: 27.95,
              },
            ],
          })
        }

        actions.forEach((type) => {
          addEventListener(type, fakeFBTracking, { passive: true })
        })
      }
    } catch (error) {
      Logger.error('Error when faking FB pixel', error)
    }
  }

  return { checkShouldFakeFBSale }
}
