<template>
  <div class="verification">
    <div class="verification-form">
      <form @submit.prevent="onSubmit" ref="form">
        <sib-form-item
          label="Email"
          input-id="verification-form__email"
          :value="customer.email"
          is-required
          :validator="isEmail"
          ref="itemEmail"
          error-message="Email is invalid"
        >
          <sib-input
            id="verification-form__email"
            size="sm"
            placeholder="Enter your email"
            v-model="customer.email"
            name="email"
          ></sib-input>
        </sib-form-item>

        <sib-button
          class="sib-button--width-full mt-sm"
          role="primary"
          :loading="loading"
        >
          SUBMIT
        </sib-button>
        <p v-show="isError" class="verification-form__error-message">
          {{
            errorMessage?.includes(ERROR_MESSAGE_ACTIVATE_ACCOUNT_INCLUDE)
              ? `${
                  errorMessage.split(',')[0]
                }, please verify your email address to activate your account.`
              : errorMessage?.includes(RESPONSE_API_ERROR_MESSAGE)
              ? errorMessage
              : ''
          }}
        </p>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, nextTick, onUnmounted, computed } from 'vue'
import FormItem from '@/ui-kits/FormItem.vue'
import {
  FORM_ITEM_INVALID_SELECTOR,
  HEADER_HEIGHT,
  RESPONSE_API_ERROR_MESSAGE,
  isEmail,
} from '@/utils'
import SibFormItem from '@/ui-kits/FormItem.vue'
import useCustomerStore from '@/store/customer'
import Provider from '@/provider'
import LocationService, { UserLocation } from '@/services/location'
import useUserStore from '@/store/user'

const emit = defineEmits<{
  (e: 'email-existed', value: boolean): void
}>()

const userStore = useUserStore()
const customerStore = useCustomerStore()

const errorMessage = computed(() => customerStore.errorMessage)

const customer = reactive({
  email: '',
  password: '',
})
const itemEmail = ref<typeof FormItem | null>(null)
const form = ref<HTMLFormElement | null>(null)
const loading = ref(false)
const isError = ref(false)

// the customer will get this message when they create new account but the email existed
const ERROR_MESSAGE_ACTIVATE_ACCOUNT_INCLUDE = 'We have sent an email to'

onUnmounted(() => {
  customerStore.errorMessage = null
})

async function onSubmit() {
  const isEmailValid = itemEmail.value?.validate()
  const isValid = isEmailValid

  if (!isValid) {
    // wait a tick for Vue applying the classes
    await nextTick()
    const firstInvalidElement: HTMLElement | undefined | null =
      form.value?.querySelector(FORM_ITEM_INVALID_SELECTOR)
    if (firstInvalidElement) {
      window.scrollTo({
        top:
          window.scrollY +
          firstInvalidElement.getBoundingClientRect().top -
          HEADER_HEIGHT,
        behavior: 'smooth',
      })
    }
    return
  }

  if (!form.value) return

  loading.value = true
  isError.value = false

  customerStore.customerEmail = customer.email

  // subscribe user
  subscribeUser()

  await customerStore.createCustomer(customer)
  if (
    customerStore.errorMessage?.includes(ERROR_MESSAGE_ACTIVATE_ACCOUNT_INCLUDE)
  ) {
    emit('email-existed', true)
  } else if (customerStore.errorMessage != RESPONSE_API_ERROR_MESSAGE) {
    emit('email-existed', false)
  }

  isError.value = true
  loading.value = false
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

async function subscribeUser() {
  if (!customer.email) return

  LocationService.onLocationReady(async (location: UserLocation | null) => {
    if (location?.ip) {
      userStore.identifyUserByEmail(customer.email, location.ip)
    }
  })

  const provider = await Provider.getInstance()
  await provider.subscribeUserToListKlaviyo({
    email: customer.email,
  })
}
</script>

<style lang="scss">
.verification {
  &-form {
    &__error-message {
      color: var(--color-secondary);
    }
    .sib-form-item {
      margin: 1em 0;
      @include media-md-up {
        margin-top: 1.75em;
      }
    }
  }
}
</style>
