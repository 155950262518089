import { Search } from './search'
import { SibSearch } from './sib-search'
import { Seachanise } from './searchanise'
import { SIB_SEARCH } from '@/utils'

interface SearchProviderFactory {
  instance: Search | null
  initiating: boolean
  initiationCallback: ((value: Search | PromiseLike<Search>) => void)[]
  init(): Promise<void>
  getInstance(): Promise<Search>
}

const searchProvider: SearchProviderFactory = {
  instance: null,
  initiationCallback: [],
  initiating: false,
  async init(): Promise<void> {
    if (this.instance || this.initiating) {
      return
    }
    this.initiating = true

    if (import.meta.env.VITE_SEARCH_TYPE == SIB_SEARCH) {
      this.instance = new SibSearch()
    } else {
      this.instance = new Seachanise()
    }

    this.initiationCallback.forEach((callback) => {
      callback(this.instance!)
    })
    this.initiationCallback = []
    this.initiating = false
  },

  async getInstance(): Promise<Search> {
    if (this.instance) return this.instance
    return new Promise((resolve) => {
      this.initiationCallback.push(resolve)
      this.init()
    })
  },
}

export default searchProvider
