<template>
  <a v-if="isOtherPageLink" :href="url.toString()">
    <slot></slot>
  </a>
  <router-link v-else :to="url" @click="onClick">
    <slot></slot>
  </router-link>
</template>

<script setup lang="ts">
import { usePage } from '@/composables/page'
import { RouteLocationRaw } from 'vue-router'
import { computed } from '@vue/reactivity'

const props = defineProps<{
  to: RouteLocationRaw
  // title of the target page
  title: string
  referer?: string
}>()
const { setPageTitle } = usePage()

const url = computed(() => {
  if (props.referer) {
    return props.to?.toString().includes('?')
      ? `${props.to}&ref=${props.referer}`
      : `${props.to}?ref=${props.referer}`
  }
  return props.to
})

// we need to set the title of the target page before the route changed
// because GA is using page title as the identifier for a page
// and it automatically check it from history state
function onClick() {
  setPageTitle(props.title)
}

// check link to another page
const isOtherPageLink = computed(() => {
  const toUrl = props.to.toString()
  const URL_REGEX = new RegExp(
    "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$"
  )

  return (
    URL_REGEX.test(toUrl) ||
    toUrl.startsWith('https://') ||
    toUrl.startsWith('http://')
  )
})
</script>
