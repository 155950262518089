<template>
  <video
    ref="videoElement"
    class="video"
    :preload="preload"
    :autoplay="autoplay"
    playsinline
    controls
    controlslist="nodownload"
    muted
    :loop="loop"
  >
    <source :src="src" :type="type" />
  </video>
</template>

<script setup lang="ts">
import { ref, watch, Ref } from 'vue'

interface VideoProps {
  src: string
  autoplay: boolean
  type?: string
  preload: string
  mediaIndex?: number
  selectedMediaIndex?: number
  loop?: boolean
}

const props = withDefaults(defineProps<VideoProps>(), {
  src: '',
  autoplay: false,
  type: 'video/mp4',
  preload: 'none',
  loop: true,
})

const videoElement: Ref<HTMLVideoElement | null> = ref(null)

watch(
  () => props.selectedMediaIndex,
  (value) => {
    if (!videoElement.value) return
    if (value === props.mediaIndex) {
      videoElement.value.play()
    } else {
      videoElement.value.pause()
    }
  }
)
</script>
