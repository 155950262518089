<template>
  <div class="customer" title="Login" @click="open">
    <UserIcon />
    <span>{{ customer?.firstName || '' }}</span>
    <client-only>
      <AccountPopup ref="accountPopup" />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import UserIcon from '@/assets/images/icons/icon-user.svg'
import useCustomerStore from '@/store/customer'
import AccountPopup from './AccountPopup.vue'
import { EVENT_OPEN_POPUP_SIGN_IN } from '@/utils'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import EventBus from '@/services/eventbus'

const customerStore = useCustomerStore()
const router = useRouter()
const customer = computed(() => customerStore.customer)

const accountPopup = ref<typeof AccountPopup | null>(null)

function open() {
  if (customer.value) {
    router.push(`/account/customer`)
  } else {
    if (accountPopup.value) accountPopup.value.open()
  }
}

onMounted(() => {
  customerStore.getCurrentCustomer()

  EventBus.on(EVENT_OPEN_POPUP_SIGN_IN, () => {
    open()
  })
})

onUnmounted(() => {
  customerStore.errorMessage = null
})
</script>

<style lang="scss">
.customer {
  position: relative;
  cursor: pointer;

  span {
    position: absolute;
    font-size: 11px;
    width: 50px;
    font-weight: 600;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include media-desktop {
      width: 60px;
    }
  }
}
</style>
