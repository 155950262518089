import useRouteStore from '@/store/route'
import useSettingStore from '@/store/setting'
import { PAGE_TITLE, PAGE_DESCRIPTION } from '@/utils/constants'

export const usePage = () => {
  const settingStore = useSettingStore()
  const routeStore = useRouteStore()

  const setPageTitle = (pageName?: string) => {
    let title = settingStore.shop?.defaultTitle || PAGE_TITLE

    if (pageName) {
      title = `${pageName} | ${settingStore.shop?.name || ''}`
    }
    routeStore.pageTitle = title
  }

  const setPageDescription = (pageDescription?: string) => {
    let description = settingStore.shop?.shopDescription || PAGE_DESCRIPTION

    if (pageDescription) {
      description = pageDescription
    }
    routeStore.pageDescription = description
  }

  return { setPageTitle, setPageDescription }
}
