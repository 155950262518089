<template>
  <div class="sib-form-item" :class="{ 'sib-form-item--invalid': isInValid }">
    <label class="sib-form-item__label" :for="inputId">
      {{ label }}
      <span class="sib-form-item__label-asterisk" v-if="labelAsterisk"
        >*</span
      ></label
    >
    <div class="sib-form-item__input">
      <slot></slot>
    </div>
    <span class="sib-form-item__message" v-show="isInValid">
      <template v-if="!value">
        {{
          requiredFieldMessage ? requiredFieldMessage : `${label} is required!`
        }}
      </template>
      <template v-else> {{ errorMessage }} </template>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@vue/reactivity'
import { onMounted, ref, watch, WatchStopHandle } from 'vue'

interface FormItemProps {
  label: string
  inputId?: string
  isRequired?: boolean
  value?: string | boolean
  validator?: (value: string) => boolean
  autoValidate?: boolean
  errorMessage?: string
  labelAsterisk?: boolean
  requiredFieldMessage?: string
}

const props = withDefaults(defineProps<FormItemProps>(), {
  label: '',
})

defineExpose({
  validate,
})

const shouldValidate = ref(false)
const isInValid = computed(() => {
  return (
    shouldValidate.value &&
    ((props.isRequired && !props.value) ||
      (props.validator && !props.validator(props.value || '')))
  )
})

onMounted(() => {
  if (props.autoValidate) {
    let unWatch: WatchStopHandle | null = null
    unWatch = watch(
      () => props.value,
      (newValue, oldValue) => {
        if ((newValue || oldValue) && newValue != oldValue) {
          // Only validate when the user change the value
          shouldValidate.value = true
          setTimeout(() => {
            if (unWatch) {
              unWatch()
            }
          })
        }
      }
    )
  }
})

function validate() {
  shouldValidate.value = true
  return !isInValid.value
}
</script>
