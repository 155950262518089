<template>
  <div class="app-progress-bar" :style="elementStyle"></div>
</template>

<script setup lang="ts">
import EventBus from '@/services/eventbus'
import { EVENT_APP_PROGRESS_FINISH, EVENT_APP_PROGRESS_START } from '@/utils'
import { computed } from '@vue/reactivity'
import { onMounted, ref } from 'vue'

interface AppProgressBarProps {
  estimatedTime?: number
}
const props = withDefaults(defineProps<AppProgressBarProps>(), {
  estimatedTime: 2,
})

const transitionDuration = ref(0.6)
const width = ref(0)
const running = ref(false)
let timeOut: any = null

const elementStyle = computed(() => {
  return {
    width: width.value + '%',
    transitionDuration: transitionDuration.value + 's',
  }
})

onMounted(() => {
  EventBus.on(EVENT_APP_PROGRESS_START, start)
  EventBus.on(EVENT_APP_PROGRESS_FINISH, finish)
})

function start() {
  if (running.value) return
  if (timeOut) {
    clearTimeout(timeOut)
  }
  running.value = true
  const baseTimeout = 0
  const timeouts = [
    baseTimeout,
    2 * baseTimeout,
    3 * baseTimeout,
    4 * baseTimeout,
  ]
  const widths = [20, 25, 30, 40, 50, 80, 95]
  const durations = [0.2, 0.075, 0.1, 0.2, 0.15, 0.3, 1]
  const estimatedTime = props.estimatedTime || 2
  function run() {
    transitionDuration.value = durations.shift()! * estimatedTime
    width.value = widths.shift()!
    if (durations.length) {
      const delay =
        (transitionDuration.value + timeouts.shift()! * estimatedTime) * 1000
      timeOut = setTimeout(run, delay)
    } else {
      // automatic finish the progress bar
      // to prevent the case that the finish method is never called
      const delay = 20 * 1000
      timeOut = setTimeout(finish, delay)
    }
  }

  run()
}

function finish() {
  if (!running.value) return
  if (timeOut) clearTimeout(timeOut)
  running.value = false
  width.value = 100
  transitionDuration.value = 0.1
  timeOut = setTimeout(reset, transitionDuration.value * 1000 + 100)
}

function reset() {
  transitionDuration.value = 0
  width.value = 0
}
</script>

<style lang="scss">
:root {
  --app-progress-bar-height: 3px;
  --app-progress-bar-color: var(--color-primary);
}

.app-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--app-progress-bar-height);
  background-color: var(--app-progress-bar-color);
  width: 0;
  z-index: var(--app-progress-bar-z-index);
  border-radius: 20px;
  transition: width 0.6s linear;
}
.body--promotion-bar-showing {
  .app-progress-bar {
    // change the color of the progress bar when showing promotion bar
    background-color: #e91e63;
  }
}
</style>
