import { defineStore } from 'pinia'
import { PAGE_TITLE, PAGE_DESCRIPTION, SSR_LOAD_TYPE } from '@/utils/constants'

const useRouteStore = defineStore('route', {
  state: () => ({
    currentUrl: '',
    currentOrigin: '',
    currentDomain: '',
    pageTitle: PAGE_TITLE,
    pageDescription: PAGE_DESCRIPTION,
    loadType: SSR_LOAD_TYPE,
    redirectFromPath: '',
  }),
})

export default useRouteStore
