import useUserStore from '@/store/user'

export function useSettings() {
  const userStore = useUserStore()

  function filterSettingsByUserSegment(settings: any[]) {
    if (!settings?.length) return settings

    return settings.filter((setting) => {
      // remove setting if user segment contain excluded segment key
      if (setting.excludedUserSegment?.length) {
        return !setting.excludedUserSegment.some((segmentKey: string) =>
          userStore.segments.some((segment) => segment.key === segmentKey)
        )
      }

      // remove setting if required segment key is not in user segment
      if (setting.requiredUserSegment?.length) {
        return !setting.requiredUserSegment.some((segmentKey: string) =>
          userStore.segments.every((segment) => segment.key !== segmentKey)
        )
      }

      return true
    })
  }

  return { filterSettingsByUserSegment }
}
