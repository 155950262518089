<template>
  <div class="recover">
    <div class="recover-form">
      <form @submit.prevent="onSubmit" ref="form">
        <div class="note" v-if="type == 'page'">
          An email will be sent to you for password recovery.
        </div>
        <sib-form-item
          label="Email"
          input-id="recover-form__email"
          :value="email"
          is-required
          :validator="isEmail"
          ref="itemEmail"
          error-message="Email is invalid"
        >
          <sib-input
            id="recover-form__email"
            size="sm"
            placeholder="Enter your email"
            v-model="email"
            name="email"
          ></sib-input>
        </sib-form-item>

        <sib-button
          class="sib-button--width-full mt-sm"
          role="primary"
          :loading="loading"
        >
          SUBMIT
        </sib-button>
        <p v-show="isError" class="recover-form__error-message">
          {{ errorMessage }}
        </p>
        <p v-if="submited && !isError" class="recover-form__success-message">
          We have sent you an email to reset your password. Please check your
          inbox.
        </p>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, nextTick, onUnmounted, computed } from 'vue'
import Analytics from '@/services/analytics'
import Logger from '@/services/log'
import FormItem from '@/ui-kits/FormItem.vue'
import { FORM_ITEM_INVALID_SELECTOR, HEADER_HEIGHT, isEmail } from '@/utils'
import SibFormItem from '@/ui-kits/FormItem.vue'
import useCustomerStore from '@/store/customer'

const props = withDefaults(defineProps<{ type?: string }>(), {
  type: 'page',
})

const customerStore = useCustomerStore()

const errorMessage = computed(() => customerStore.errorMessage)

const email = ref('')
const itemEmail = ref<typeof FormItem | null>(null)
const form = ref<HTMLFormElement | null>(null)
const loading = ref(false)
const isError = ref(false)
const submited = ref(false)

onUnmounted(() => {
  customerStore.errorMessage = null
})

async function onSubmit() {
  const isEmailValid = itemEmail.value?.validate()
  const isValid = isEmailValid

  if (!isValid) {
    // wait a tick for Vue applying the classes
    await nextTick()
    const firstInvalidElement: HTMLElement | undefined | null =
      form.value?.querySelector(FORM_ITEM_INVALID_SELECTOR)
    if (firstInvalidElement) {
      window.scrollTo({
        top:
          window.scrollY +
          firstInvalidElement.getBoundingClientRect().top -
          HEADER_HEIGHT,
        behavior: 'smooth',
      })
    }
    return
  }

  if (!form.value) return

  loading.value = true
  isError.value = false
  submited.value = false

  try {
    const response = await customerStore.recoverCustomer(email.value)

    isError.value = !response
    submited.value = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error: any) {
    Logger.error('Error when recover customer', error)
    Analytics.error(error)
  }
  loading.value = false
}
</script>

<style lang="scss">
.recover {
  &-form {
    &__success-message {
      color: var(--color-primary);
      font-weight: 500;
    }
    &__error-message {
      color: var(--color-secondary);
    }
    .sib-form-item {
      margin: 1em 0;
      @include media-md-up {
        margin-top: 1.75em;
      }
    }
  }
  .note {
    font-weight: 600;
  }
}
</style>
