<template>
  <sib-link class="no-line-height" to="/" title="">
    <sib-image
      class="logo"
      :src="settingStore.shop?.logo"
      :alt="settingStore.shop?.name + ' Logo'"
      :width="210"
      :height="30"
      :sources="logoSources"
    ></sib-image>
  </sib-link>
</template>

<script setup lang="ts">
import useSettingStore from '@/store/setting'

const settingStore = useSettingStore()
const logoSources = [
  {
    size: 210,
  },
]
</script>
