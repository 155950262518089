import { isOnServer } from '@/utils/ssr'
import Logger from '../log'
import {
  CacheResponse,
  CACHE_RESPONSE_TYPE_ANY,
  CACHE_RESPONSE_TYPE_JSON,
  CACHE_RESPONSE_TYPE_STRING,
  MyCache,
} from './type'
import { RedisCache } from './redis'
import { defineIdleProperty } from 'idlize/defineIdleProperty.mjs'
import { IndexedDBCache } from './indexedDB'
import Analytics from '../analytics'

interface CacheFactory {
  instance: MyCache | null
  init(): void
}

const CacheService: CacheFactory = {
  instance: null,

  async init(): Promise<void> {
    if (this.instance) {
      return
    }
    if (isOnServer) {
      this.instance = new RedisCache()
      if (this.instance.init) {
        await this.instance.init()
      }
    } else {
      // defer the initation till idle or urgent to reduce main thread blocking time
      defineIdleProperty(this, 'instance', () => new IndexedDBCache())
    }
  },
}

export function getJSONfromCacheResponse(cacheResponse?: CacheResponse): any {
  if (!cacheResponse) return null
  try {
    if (cacheResponse.type == CACHE_RESPONSE_TYPE_JSON) {
      return cacheResponse.value
    }
    if (cacheResponse.type == CACHE_RESPONSE_TYPE_STRING) {
      return JSON.parse(cacheResponse.value)
    }

    if (cacheResponse.type == CACHE_RESPONSE_TYPE_ANY) {
      return cacheResponse.value
    }
    return cacheResponse.value
  } catch (error: any) {
    Logger.error('Error when get JSON from cache response', {
      cacheResponse,
      error,
    })
    Analytics.error(error)
    return null
  }
}

export default CacheService
