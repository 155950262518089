<template>
  <div class="container box-subscribe" v-if="!hiddenBoxSubscribe">
    <div class="divider mt-lg mb-lg"></div>
    <h3 v-if="submited && !isError" class="box-subscribe-message mt-sm">
      Thanks for subscribing!
    </h3>
    <template v-else>
      <div class="box-subscribe-email">
        <h3 class="box-subscribe-email-title mb-md">
          Stay in the loop with our latest promotions and new arrivals!
        </h3>
        <form @submit.prevent="onSubmit" ref="form">
          <sib-form-item
            label="Email"
            input-id="box-subscribe-form__email"
            :value="email"
            is-required
            :validator="isEmail"
            ref="itemEmail"
            error-message="Email is invalid"
          >
            <sib-input
              id="box-subscribe-form__email"
              size="md"
              placeholder="Enter your email"
              v-model="email"
              name="email"
            ></sib-input>
          </sib-form-item>
          <sib-button role="primary" :loading="loading"> Subscribe </sib-button>
        </form>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { isEmail } from '@/utils'
import LocationService, { UserLocation } from '@/services/location'
import useUserStore from '@/store/user'
import Provider from '@/provider'
import FormItem from '@/ui-kits/FormItem.vue'
import SibFormItem from '@/ui-kits/FormItem.vue'
import useSettingStore from '@/store/setting'
import useRequestLocationStore from '@/store/requestLocation'
import { VN_COUNTRY_CODE } from '@/utils'
import { useRoute } from 'vue-router'

const route = useRoute()
const userStore = useUserStore()

const email = ref('')
const itemEmail = ref<typeof FormItem | null>(null)
const form = ref<HTMLFormElement | null>(null)
const loading = ref(false)
const isError = ref(false)
const submited = ref(false)

const settingStore = useSettingStore()
const requestLocation = useRequestLocationStore()

const showNewHome = computed(
  () =>
    !!(
      requestLocation.countryCode &&
      requestLocation.countryCode.toUpperCase() != VN_COUNTRY_CODE &&
      settingStore.pages?.general?.homePageVersion?.toUpperCase() == 'V2'
    )
)

const hiddenBoxSubscribe = computed(() => {
  return (route.name == 'home' && showNewHome.value) || route.name == 'new-home'
})

async function onSubmit() {
  const isEmailValid = itemEmail.value?.validate()
  const isValid = isEmailValid

  if (!isValid) return
  if (!form.value) return

  loading.value = true
  isError.value = false
  submited.value = false

  try {
    LocationService.onLocationReady(async (location: UserLocation | null) => {
      if (location?.ip) {
        userStore.identifyUserByEmail(email.value, location.ip)
      }
    })

    const provider = await Provider.getInstance()
    await provider.subscribeUserToListKlaviyo({
      email: email.value,
    })

    isError.value = false
    submited.value = true
  } catch (error: any) {
    isError.value = true
  }
  loading.value = false
}
</script>

<style lang="scss">
.box-subscribe {
  &-email {
    max-width: 475px;
    margin: auto;
    label {
      display: none;
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin: auto;
      @include media-md-up {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 120px;
      }
      .sib-form-item {
        margin-bottom: 0;
      }
    }
  }
  &-message {
    margin: 0;
    text-align: center;
  }
  &-email-title {
    text-align: center;
  }
}
</style>
