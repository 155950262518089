import mainProvider, { Provider } from './main'
import searchProvider from './search'
import { Search } from './search/search'

interface ProviderFactory {
  init(): Promise<[Provider, Search]>
  setProviderName(providerName: string): void
  getInstance(): Promise<Provider>
  getSearchInstance(): Promise<Search>
}

const provider: ProviderFactory = {
  async getInstance() {
    return await mainProvider.getInstance()
  },
  async getSearchInstance() {
    return await searchProvider.getInstance()
  },
  setProviderName(providerName: string) {
    mainProvider.setProviderName(providerName)
  },
  async init() {
    return Promise.all([this.getInstance(), this.getSearchInstance()])
  },
}

export default provider
