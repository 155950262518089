<template>
  <sib-video
    v-if="item.video"
    class="carousel__video"
    :src="item.video.url"
    :preload="preload"
    :autoplay="autoplay"
    :mediaIndex="$props.mediaIndex"
    :selectedMediaIndex="selectedMediaIndex"
  ></sib-video>
  <sib-image
    v-else
    :preload="preload"
    class="carousel__image"
    :class="
      item.image.mediaContentType == PRODUCT_MEDIA_VIDEO
        ? 'carousel__image--video'
        : ''
    "
    v-bind="item.image"
  ></sib-image>
  <span
    class="play-video-button play-video-button--mobile"
    v-if="
      deviceStore.isMobile && item.image.mediaContentType == PRODUCT_MEDIA_VIDEO
    "
  ></span>
</template>

<script setup lang="ts">
import { Image } from '@/types'
import useDeviceStore from '@/store/device'
import { PRODUCT_MEDIA_VIDEO } from '@/utils'

const deviceStore = useDeviceStore()

interface MediaItem {
  image: Image
  video?: {
    format: string
    mimeType: string
    url: string
  }
  link?: string
  title?: string
}

interface MediaProps {
  item: MediaItem
  preload?: boolean | string
  autoplay: boolean
  mediaIndex?: number
  selectedMediaIndex?: number
}

withDefaults(defineProps<MediaProps>(), {})
</script>

<style lang="scss">
.play-video-button.play-video-button--mobile {
  pointer-events: none;

  &::after {
    border-width: 1.25rem 0 1.25rem 2rem;
  }
}
</style>
