import { PRODUCT_TYPE_QR_GREETING_CARD } from '@/utils'
import { isProductHasType, isProductHasTypeStartWith } from '@/utils/product'

import useProductStore from '@/store/product'
import useCheckoutStore from '@/store/checkout'

export const useQrGreetingCardAndDigitalContent = () => {
  const productStore = useProductStore()
  const checkoutStore = useCheckoutStore()

  function checkQrGreetingaCardAndDigitalContent() {
    // get list item has product type is QR Greeting Card or start with Digital
    const lineItemsQrGreetingCardAndDigitalContent =
      checkoutStore.localCartLineItems.filter(
        (lineItem) =>
          isProductHasType(lineItem.variant.product, [
            PRODUCT_TYPE_QR_GREETING_CARD,
          ]) || isProductHasTypeStartWith(lineItem.variant.product, 'digital')
      )

    if (!lineItemsQrGreetingCardAndDigitalContent.length) return []

    // get item id has bundled
    const qrGreetingCardIdsBundled: any = []
    const digitalContentIdsBundled: any = []
    const qrGreetingCardIdsExist: any = []

    lineItemsQrGreetingCardAndDigitalContent.forEach((lineItem) => {
      if (isProductHasTypeStartWith(lineItem.variant.product, 'digital')) {
        const digitalContentAttributeBundled = lineItem.customAttributes.find(
          (item) => item.key == '_qr-greeting-card-item-id' && item.value
        )
        if (digitalContentAttributeBundled) {
          qrGreetingCardIdsBundled.push(digitalContentAttributeBundled.value)
          digitalContentIdsBundled.push(lineItem.id)
        }
      }

      if (
        isProductHasType(lineItem.variant.product, [
          PRODUCT_TYPE_QR_GREETING_CARD,
        ])
      ) {
        qrGreetingCardIdsExist.push(lineItem.id)
      }
    })

    return lineItemsQrGreetingCardAndDigitalContent.map((lineItem) => {
      if (
        isProductHasType(lineItem.variant.product, [
          PRODUCT_TYPE_QR_GREETING_CARD,
        ])
      ) {
        return {
          type: 'QR Greeting Card',
          lineItemId: lineItem.id,
          isDelete: !qrGreetingCardIdsBundled.includes(lineItem.id),
          isHidden: !qrGreetingCardIdsBundled.includes(lineItem.id),
        }
      } else {
        const qrGreetingCardItemIdBundled = lineItem.customAttributes.find(
          (item) => item.key == '_qr-greeting-card-item-id'
        )?.value
        return {
          type: 'Digital Content',
          lineItemId: lineItem.id,
          isDelete:
            !digitalContentIdsBundled.includes(lineItem.id) ||
            !qrGreetingCardIdsExist.includes(qrGreetingCardItemIdBundled),
          isHidden: true,
        }
      }
    })
  }

  return {
    checkQrGreetingaCardAndDigitalContent,
  }
}
