import { ABTest } from '@/types'
import { defineStore } from 'pinia'

export interface ABTestState {
  assignedABTests?: ABTest[]
}

const useABTestStore = defineStore('abtest', {
  state: (): ABTestState => ({
    assignedABTests: undefined,
  }),
})

export default useABTestStore
