<template>
  <div
    class="image"
    :class="{
      'image--progressive-load': usePlaceholderImage,
      'image--loaded': isLoaded,
    }"
  >
    <picture class="image__placeholder" v-if="placeHolderImage">
      <source
        v-for="(source, index) of placeHolderImage.sources"
        :srcset="source.srcSet"
        :media="source.media"
        :width="source.width || undefined"
        :height="source.height || undefined"
        :key="index"
      />
      <img
        :loading="$attrs.loading"
        :src="placeHolderImage.src"
        :alt="placeHolderImage.alt"
        :width="placeHolderImage.width || undefined"
        :height="placeHolderImage.height || undefined"
        :fetchpriority="$attrs.loading === 'lazy' ? 'low' : 'auto'"
      />
    </picture>
    <picture
      v-if="
        image.src &&
        ($attrs.loading !== 'lazy' ||
          deviceStore.firstInputFired ||
          deviceStore.preloadImageLoaded)
      "
      class="image__main"
      @load="mainImageLoaded"
      @error="$emit('error', $event)"
    >
      <source
        v-for="(source, index) of image.sources"
        :srcset="source.srcSet"
        :media="source.media"
        :width="source.width || undefined"
        :height="source.height || undefined"
        :key="index"
      />
      <img
        ref="mainImage"
        :loading="$attrs.loading"
        :src="image.src"
        :alt="image.alt"
        @load="mainImageLoaded"
        :width="image.width || undefined"
        :height="image.height || undefined"
        :fetchpriority="preload ? 'high' : 'auto'"
        @error="$emit('error', $event)"
      />
    </picture>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, onMounted, ref } from 'vue'
import { buildSizedImageUrlWithPixelDensity } from '@/utils/image'
import { ImageSource } from '@/types'
import { appendToHead } from '@/composables/appendToHead'
import { SSR_CONTEXT_APPEND_TO_HEAD_IMAGE_PRELOAD_LINKS } from '@/utils/constants'
import useDeviceStore from '@/store/device'
const deviceStore = useDeviceStore()

interface ImageProps {
  width: number
  height: number
  src: string
  alt: string
  sources?: Array<ImageSource>
  preload?: boolean

  placeholderSrc?: string
  placeholderSources?: Array<ImageSource>
}

interface Source {
  srcSet: string
  media?: string
  width?: number
  height?: number
}

interface Image {
  width: number
  height: number
  alt: string
  sources: Source[]
  src: string
}

const props = withDefaults(defineProps<ImageProps>(), {
  width: 0,
  height: 0,
  src: '',
  sources: () => [],
})

defineEmits<{
  (e: 'error', event: any): void
}>()

const isLoaded = ref(false)
const mainImage = ref<HTMLImageElement | null>(null)

const usePlaceholderImage = computed(() => {
  return props.placeholderSrc || props.placeholderSources?.length
})

const image: ComputedRef<Image> = computed(() => {
  return buildImage(
    props.width,
    props.height,
    props.src,
    props.alt,
    props.sources,
    props.preload
  )
})
const placeHolderImage: ComputedRef<Image | null> = computed(() => {
  if (!usePlaceholderImage.value) {
    return null
  }
  return buildImage(
    props.width,
    props.height,
    props.placeholderSrc || props.src,
    props.alt,
    props.placeholderSources
  )
})

onMounted(() => {
  if (mainImage.value && mainImage.value.complete) {
    mainImageLoaded()
  }
})

function buildImage(
  width: number,
  height: number,
  src: string,
  alt: string,
  sources?: ImageSource[],
  preloadImage?: boolean
): Image {
  const preloadLinks: string[] = []
  const result: Image = {
    width: width,
    height: height,
    alt: alt,
    src: src,
    sources: [],
  }
  if (!sources || !sources.length) {
    return result
  }
  const newSources = [...sources]
  // sort the sources by media desc
  newSources.sort((a, b) => {
    if (!a.media) return 1
    if (!b.media) return -1
    return b.media - a.media
  })

  let lastSourceMedia: number | undefined = 0
  // auto generate sizes for each media and pixel density
  newSources.forEach((sourceProp) => {
    const sourceConverted = convertSourePropToSource(
      sourceProp,
      src,
      preloadImage,
      lastSourceMedia
    )
    lastSourceMedia = sourceProp.media
    result.sources.push(sourceConverted.source)
    preloadLinks.push(sourceConverted.preloadLink)
  })
  if (preloadImage) {
    appendToHead(preloadLinks, SSR_CONTEXT_APPEND_TO_HEAD_IMAGE_PRELOAD_LINKS)
  }
  return result
}

function convertSourePropToSource(
  sourceProp: ImageSource,
  srcProp?: string,
  preloadImage?: boolean,
  lastSourceMedia?: number
): {
  source: Source
  preloadLink: string
} {
  let preloadLink = ''
  const source: Source = {
    srcSet: '',
    media: sourceProp.media ? `(min-width: ${sourceProp.media}px)` : undefined,
    width: sourceProp.width,
    height: sourceProp.height,
  }
  const src = sourceProp.src || srcProp || ''
  if (!sourceProp.size) {
    source.srcSet = src || ''
  } else {
    const sizedUrlWithPixelDensity = buildSizedImageUrlWithPixelDensity(
      src,
      sourceProp.size
    )
    const sizedUrlWithPixelDensityToString = sizedUrlWithPixelDensity.map(
      (sizedUrl) => `${sizedUrl.src} ${sizedUrl.pixelDensity}`
    )
    source.srcSet = sizedUrlWithPixelDensityToString.join(', ')
  }
  if (preloadImage) {
    const mediaArr = []
    if (source.media) {
      mediaArr.push(source.media)
    }
    if (lastSourceMedia) {
      mediaArr.push(`(max-width: ${lastSourceMedia - 0.1}px)`)
    }
    preloadLink = `<link rel="preload" fetchpriority="high" href="${src}" as="image" imagesrcset="${
      source.srcSet
    }" media="${mediaArr.join(' and ')}">`
  }
  return { source, preloadLink }
}

function mainImageLoaded() {
  deviceStore.preloadImageLoaded = true
  isLoaded.value = true
}
</script>
