import { Image } from '@/types/image'

export const useMetaTag = () => {
  const renderImageMetaTags = (image?: Image) => {
    let imageMetaTags = ``
    if (!image) return imageMetaTags

    if (image.src) {
      imageMetaTags += ` <meta property="og:image" content="${image.src}" />
                         <meta property="og:image:secure_url" content="${image.src}" />
                         <meta name="twitter:image" content="${image.src}" />
                         <meta name="twitter:card" content="summary" />`
    }
    if (image.width) {
      imageMetaTags += ` <meta property="og:image:width" content="${image.width}" />
                         <meta name="twitter:image:width" content="${image.width}" />`
    }
    if (image.height) {
      imageMetaTags += ` <meta property="og:image:height" content="${image.height}" />
                         <meta name="twitter:image:height" content="${image.height}" />`
    }
    if (image.alt) {
      imageMetaTags += ` <meta property="og:image:alt" content="${image.alt}" />
                         <meta name="twitter:image:alt" content="${image.alt}" />`
    }

    return imageMetaTags
  }

  return {
    renderImageMetaTags,
  }
}
