<template>
  <div
    v-for="index in computedCount"
    :key="index"
    class="sib-ske-loading"
    :class="classes"
    :style="style"
  ></div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { computed, ref, Ref, useAttrs, watch } from 'vue'

type SkeletonLoadingSizePreBuilt = 'sm' | 'md' | 'lg' | 'square'
type SkeletonLoadingRoundPreBuilt = 'sm' | 'md' | 'lg' | 'circle' | 'none'

interface SkeletonLoadingProps {
  count?: number
  size?: SkeletonLoadingSizePreBuilt
  width?: string
  height?: string
  round?: SkeletonLoadingRoundPreBuilt | string[]
}
const props = withDefaults(defineProps<SkeletonLoadingProps>(), {
  count: 1,
  size: 'sm',
  round: 'sm',
})
const style = ref({})
const classes: Ref<string[]> = ref([])
const attrs = useAttrs()
const computedCount = computed(() => {
  if (!props.count || isNaN(props.count) || props.count < 1) return 1
  return +props.count
})

watch(
  () => props,
  () => {
    const tmpClasses: string[] = []
    const tmpStyle: {
      [key: string]: string
    } = {}

    if (attrs['class']) {
      tmpClasses.push(attrs['class'] as string)
    }

    if (props.size) {
      tmpClasses.push(`sib-ske-loading--size-${props.size}`)
    }

    if (props.width) {
      tmpStyle.width = props.width
    }

    if (props.height) {
      tmpStyle.height = props.height
    }

    if (props.round) {
      if (typeof props.round === 'string') {
        tmpClasses.push(`sib-ske-loading--round-${props.round}`)
      } else if (typeof props.round === 'object' && props.round.length >= 1) {
        tmpStyle.borderRadius = props.round.join(' ')
      }
    }

    style.value = tmpStyle
    classes.value = tmpClasses
  },
  { deep: true, immediate: true }
)
</script>
