import { buildCacheKey } from '@/utils/cache'
import Logger from '../log'
import {
  CacheResponse,
  CacheTTLType,
  CACHE_TTL_TYPE_EXPIRED,
  MyCache,
} from './type'
import { get as idbGet, set as idbSet, del as idbDelete } from 'idb-keyval'
import Analytics from '../analytics'

const DEFAULT_TTL = 3 * 60 * 60 * 1000 // 3 hour

interface IndexedDBCacheValue {
  isForever: boolean
  value: any
  ttl: number
  createdAt: number
}
export class IndexedDBCache implements MyCache {
  async get(key: string): Promise<CacheResponse> {
    key = buildCacheKey(key)
    const cacheResponseNull = {
      value: null,
      type: 'any' as const,
    }
    try {
      const value = await idbGet<IndexedDBCacheValue>(key)
      if (!value) return cacheResponseNull
      const response = {
        value: value.value,
        type: 'any' as const,
      }
      if (value.isForever) {
        Logger.log('Cache hit', { key, value }, 2)
        return response
      }
      if (value.createdAt + value.ttl < Date.now()) {
        this.delete(key)
        return cacheResponseNull
      }
      Logger.log('Cache hit', { key, value }, 2)
      return response
    } catch (error: any) {
      Logger.error('Error when get cache from indexedDB', {
        key,
        error,
      })
      Analytics.error(error)
      return cacheResponseNull
    }
  }
  async set(key: string, value: any, ttl: number = DEFAULT_TTL): Promise<void> {
    key = buildCacheKey(key)
    try {
      const cacheValue: IndexedDBCacheValue = {
        isForever: false,
        value: value,
        ttl: ttl,
        createdAt: Date.now(),
      }
      if (!ttl || ttl <= 0) cacheValue.isForever = true
      return await idbSet(key, cacheValue)
    } catch (error: any) {
      Analytics.error(error)
      Logger.error('Error when set cache to indexedDB', {
        key,
        value,
        ttl,
        error,
      })
    }
  }
  async delete(key: string): Promise<void> {
    key = buildCacheKey(key)
    try {
      return await idbDelete(key)
    } catch (error: any) {
      Logger.error('Error delete cache from indexedDB', {
        key,
        error,
      })
      Analytics.error(error)
    }
  }
  async getTTL(key: string): Promise<CacheTTLType> {
    Logger.error('Function getTTL are not implement yet')
    return CACHE_TTL_TYPE_EXPIRED
  }
}
