import Provider from '@/provider'
import { NullOrType, SearchProductParams, SearchResults } from '@/types'
import { defineStore } from 'pinia'
import { fillUpProductProperties } from '../product'

interface SearchState {
  searchResults: NullOrType<SearchResults>
}

const useSearchStore = defineStore('search', {
  state: (): SearchState => ({
    searchResults: null,
  }),
  actions: {
    async searchProduct(payload: SearchProductParams) {
      const provider = await Provider.getSearchInstance()
      const result = await provider.searchProduct(payload)
      if (result.products) {
        result.products = result.products.map(
          (product) => fillUpProductProperties(product)!
        )
      }
      return result
    },
    async searchProductFull(payload: SearchProductParams) {
      const provider = await Provider.getSearchInstance()
      const result = await provider.searchProductFull(payload)
      if (result.products) {
        result.products = result.products.map(
          (product) => fillUpProductProperties(product)!
        )
      }
      return result
    },
    async searchProductByCollection(payload: SearchProductParams) {
      const provider = await Provider.getSearchInstance()
      const result = await provider.searchProductByCollection(payload)
      if (result.products) {
        result.products = result.products.map(
          (product) => fillUpProductProperties(product)!
        )
      }
      return result
    },
  },
})

export default useSearchStore
