<template>
  <div class="item__quantity">
    <span class="item__quantity-control" @click="changeQuantity('minus')">
      -
    </span>
    <span> {{ quantity }} </span>
    <span class="item__quantity-control" @click="changeQuantity('plus')">
      +
    </span>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    quantity: number
  }>(),
  {
    quantity: 1,
  }
)
const emit = defineEmits<{
  (e: 'update', type: string): void
}>()
function changeQuantity(type: string) {
  emit('update', type)
}
</script>

<style lang="scss">
.item__quantity {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius-medium);
  border: solid 1px var(--border-color);
  height: var(--product-option-choice-height);

  span {
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 100%;
  }

  &-control {
    font-size: 18px;
    cursor: pointer;
    font-weight: normal;
  }
}
</style>
