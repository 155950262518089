<template>
  <div class="cart__items">
    <div class="cart__items-header">
      <span> Added Items </span>
      <div class="cart__items-header-quantity-container">
        <span></span>
        <div class="cart__items-header-quantity">
          <span>Quantity</span>
          <span></span>
        </div>
      </div>
    </div>
    <template v-if="!loading && !checkoutLineItems.length">
      <div class="cart__no-item mb-sm">
        <span>You have no items in your cart!</span>
      </div>
      <CartRecoveryEmail
        @cart-recovery="$emit('cart-recovery')"
        class="mb-sm"
      />
    </template>
    <template v-else>
      <CartItem
        v-for="(item, index) of loading ? placeholderItems : checkoutLineItems"
        :key="item?.id || index"
        @update-quantity="updateQuantity"
        @delete-item="deleteCheckoutItem"
        @link-clicked="$emit('link-clicked')"
        :item="item"
        v-show="isShowItem(item) || loading"
      ></CartItem>
    </template>
    <GreetingCardBox
      v-if="
        isGreetingCardFlow &&
        !isProductTypeGreetingCardInLineItems &&
        checkoutLineItems.length
      "
      class="mt-sm"
    />

    <ConfirmationPopup
      group="cart-delete-item-confirmation"
      content-id="cart-delete-item-confirmation__content"
      ref="cartDeleteItemConfirmationPopup"
    ></ConfirmationPopup>
  </div>
</template>

<script lang="ts" setup>
import useCheckoutStore from '@/store/checkout'
import { computed, Ref } from '@vue/reactivity'
import {
  CURRENCY,
  debounce,
  EVENT_TRACKING_PRODUCT_REMOVED,
  getCurrentTimeStamp,
  PRODUCT_TYPE_GREETING_CARD,
  PRODUCT_TYPE_QR_GREETING_CARD,
  MADEMINE_ATTRIBUTE_KEY,
} from '@/utils'
import { CheckoutLineItem } from '@/types'
import CartItem from './CartItem.vue'
import CartRecoveryEmail from './CartRecoveryEmail.vue'
import GreetingCardBox from '@/components/product/GreetingCardBox.vue'
import Analytics from '@/services/analytics'
import {
  buildProductUrl,
  getRawIdFromShopifyId,
  isProductHasType,
  isProductHasTypeStartWith,
  standardizeCartIdFromShopifyId,
} from '@/utils/product'
import ConfirmationPopup from '@/ui-kits/ConfirmationPopup.vue'
import { onMounted, ref } from 'vue'
import useDiscountStore from '@/store/discount'
import useMediaCampaignStore from '@/store/media'
import { useQrGreetingCardAndDigitalContent } from '@/pages/product/composables/useQrGreetingCardAndDigitalContent'
import { useRoute } from 'vue-router'
import Logger from '@/services/log'

const route = useRoute()

const mediaCampaignStore = useMediaCampaignStore()

const isGreetingCardFlow = computed(() => mediaCampaignStore.greetingCardFlow)

const isProductTypeGreetingCardInLineItems = computed(() => {
  const index = checkoutStore.localCartLineItems.findIndex((lineItem) =>
    isProductHasType(lineItem?.variant?.product, [PRODUCT_TYPE_GREETING_CARD])
  )
  return index != -1
})

defineProps<{ loading: boolean }>()
const emit = defineEmits<{
  (e: 'update:loading', value: boolean): void
  (e: 'link-clicked'): void
  (e: 'cart-recovery'): void
}>()

const { checkQrGreetingaCardAndDigitalContent } =
  useQrGreetingCardAndDigitalContent()
const checkoutStore = useCheckoutStore()
const discountStore = useDiscountStore()
const checkoutLineItems = computed(() => checkoutStore.localCartLineItems || [])
const placeholderItems: CheckoutLineItem[] = Array(3).fill(undefined)
const cartDeleteItemConfirmationPopup: Ref<typeof ConfirmationPopup | null> =
  ref(null)
const isBOGOCampaign = computed(() => {
  return discountStore.isBOGOCampaign
})

const isCartPage = computed(() => route.name == 'cart')

function isShowItem(lineItem: any) {
  if (!lineItem) return
  if (
    !isProductHasType(lineItem.variant.product, [
      PRODUCT_TYPE_QR_GREETING_CARD,
    ]) &&
    !isProductHasTypeStartWith(lineItem.variant.product, 'digital')
  )
    return true

  const lineItemsQrGreetingCardAndDigitalContent =
    checkQrGreetingaCardAndDigitalContent()
  const lineItemsQrGreetingCardAndDigitalContentNoHidden =
    lineItemsQrGreetingCardAndDigitalContent.filter((item) => !item.isHidden)
  return !!lineItemsQrGreetingCardAndDigitalContentNoHidden.find(
    (item) => item.lineItemId == lineItem.id
  )
}

onMounted(async () => {
  if (isCartPage.value) {
    const lineItemsQrGreetingCardAndDigitalContent =
      checkQrGreetingaCardAndDigitalContent()
    if (lineItemsQrGreetingCardAndDigitalContent.length) {
      const lineItemsNeedRemove =
        lineItemsQrGreetingCardAndDigitalContent.filter((item) => item.isDelete)
      if (lineItemsNeedRemove.length) {
        for (let i = 0; i < lineItemsNeedRemove.length; i++) {
          await updateCheckout(0, lineItemsNeedRemove[i].lineItemId)
        }
      }
    }
  }
})

async function updateQuantity(typeInput: string, idInput: string) {
  let id = idInput
  let type = typeInput
  let lineItem = checkoutLineItems.value.find((item) => item.id === id)

  if (!lineItem) return

  let quantityChanged = 0
  switch (type) {
    case 'plus':
      quantityChanged = 1
      break
    case 'minus':
      quantityChanged = -1
      break
    case 'delete':
      quantityChanged = -lineItem.quantity
  }

  if (quantityChanged == 0) return
  // decide the type of action based on the original line item
  if (lineItem.quantity + quantityChanged == 0) {
    type = 'delete'
  }

  // BOGO campaign currently has bug with removing lineItems with same variant ID
  // so we need special logic to cover it
  if (isBOGOCampaign.value) {
    // Check if the updating item is the item that got discounted
    if (lineItem.price === 0) {
      // Find if there are any line item that has same variant id with the updating line item
      // Make the update for that item because the API don't let we update for the line item that got discounted
      const sameVariantIdLineItem = checkoutLineItems.value.find(
        (item) => item.variant.id === lineItem?.variant.id
      )
      // switch to update the lineitem with same variant id but do not got discounted
      if (sameVariantIdLineItem) {
        lineItem = sameVariantIdLineItem
        id = lineItem.id
      }
    }
  }

  let newQuantity = lineItem.quantity + quantityChanged
  if (newQuantity < 0) newQuantity = 0

  let debounceFn = debounces[id]

  if (!debounceFn) {
    debounceFn = debounces[id] = debounce(updateCheckout, 300, true)
  }

  // show confirmation popup for deleting case
  if (type === 'delete') {
    const confirm = await confirmDelete()

    if (!confirm) {
      // do nothing in case cancel deleting action
      return
    }

    // Need to re-assign the lineItem here
    // because in some situation, the checkoutLineItems is updated with new value from API response
    lineItem =
      checkoutLineItems.value.find((item) => item.id === id) || lineItem
  }

  // set the line item quantity to new quantity to update the UI
  lineItem.quantity = newQuantity

  // Do not debounce the execution in case BOGO campaign and deleting item
  // because the number of items will change
  const shouldUseDebounce = !isBOGOCampaign.value && newQuantity != 0

  if (!shouldUseDebounce) {
    emit('update:loading', true)
    if (debounceFn) {
      debounceFn.clear()
    }
    await updateCheckout(newQuantity, lineItem.id, lineItem)
    if (type === 'delete') {
      trackDeletingCheckoutItem(lineItem)
    }
    emit('update:loading', false)
    return
  }

  // update the last update timestamp to tell the store that there are some incoming update are waiting
  // so it should not update the line item with the result from the server
  checkoutStore.lastUpdateTimeStamp = getCurrentTimeStamp()
  debounceFn(newQuantity, lineItem.id, lineItem)
}

const debounces: {
  [key: string]: ReturnType<typeof debounce>
} = {}

async function updateCheckout(
  quantity: number,
  id: string,
  lineItem?: CheckoutLineItem
) {
  try {
    // synchronize cart online store and shopify theme store
    if (lineItem) {
      const mademineKeyItemAttribute = lineItem.customAttributes?.find(
        (attribute) => attribute.key == MADEMINE_ATTRIBUTE_KEY
      )
      if (mademineKeyItemAttribute?.value) {
        // const provider = await Provider.getInstance()
        // await provider.updateCartLineItemShopifyTheme(
        //   mademineKeyItemAttribute.value,
        //   quantity
        // )
      }
    }
  } catch (err) {
    Logger.error('Error when update cart line item shopify theme', err)
  }

  const timeStamp = getCurrentTimeStamp()
  return checkoutStore.updateCartLineItem({ id, quantity, timeStamp })
}

async function deleteCheckoutItem(id: string) {
  return updateQuantity('delete', id)
}

async function trackDeletingCheckoutItem(lineItem: CheckoutLineItem) {
  if (lineItem) {
    const product = lineItem.variant.product
    const item = {
      product_id: lineItem.variant.sku,
      sku: lineItem.variant.sku,
      name: product?.title,
      variant: getRawIdFromShopifyId(lineItem.variant.id),
      price: lineItem.variant.price,
      quantity: lineItem.quantity,
      handle: product?.handle,
      url: window.location.origin + buildProductUrl(product?.handle || ''),
      image_url: product?.featuredImage?.src,
      type: product?.productType,
      tags: product?.tags,
      collections: product?.collections?.map((collection) => collection.title),
    }
    Analytics.track(EVENT_TRACKING_PRODUCT_REMOVED, {
      ...item,
      cart_id: standardizeCartIdFromShopifyId(checkoutStore.checkout?.id),
      currency: CURRENCY,
      products: [item],
    })
  }
}

async function confirmDelete() {
  try {
    if (!cartDeleteItemConfirmationPopup.value) return false
    return await cartDeleteItemConfirmationPopup.value.confirm()
  } catch (error) {
    return false
  }
}
</script>

<style lang="scss">
.cart {
  $S: &;

  &__items {
    grid-area: a;
    padding-top: 1em;

    &-header {
      display: none;
    }
  }

  @include media-desktop {
    &--full-page {
      #{$S} {
        &__items {
          &-header {
            display: grid;
            grid-template-columns: 135px 1fr;
            gap: 1.5em;
            border-bottom: solid 1px var(--border-color);
            font-weight: 600;
            padding-bottom: 0.5em;
            margin-bottom: 1.5em;

            &-quantity-container {
              display: grid;
              grid-template-columns: 5fr 3fr;
            }

            &-quantity {
              display: flex;
              justify-content: space-evenly;

              span {
                min-width: 2em;
              }
            }
          }
        }
      }
    }
  }
}
</style>
