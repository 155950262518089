import { defineStore } from 'pinia'
import {
  Collection,
  NullOrType,
  Product,
  CollectionAndProducts,
  CollectionAndProduct,
} from '@/types'
import Provider from '@/provider'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import { ProductCollectionSortKey, ProductFilter } from '@/provider/type'
import { buildCollectionCacheKeyByHandle } from '@/utils/cache'
import { fillUpProductProperties } from '../product'
import Logger from '@/services/log'
import Analytics from '@/services/analytics'
import { timeEnd, timeStart } from '@/utils/timing'

export interface CollectionState {
  collection: NullOrType<Collection>
  isCollectionNotFound: boolean
  utmProducts: Product[]
  upsellCollection: NullOrType<Collection>
}

export interface GetCollectionByHandleParams {
  handle: string
  numberOfProducts?: number
  isForceLoadNewData?: boolean
  after?: string
  before?: string
  sortKey?: ProductCollectionSortKey
  filters?: ProductFilter[]
  reverse?: boolean
  productIds?: string[]
  productHandle?: string
}

const useCollectionStore = defineStore('collection', {
  state: (): CollectionState => ({
    collection: null,
    isCollectionNotFound: false,
    utmProducts: [],
    upsellCollection: null,
  }),
  getters: {},
  actions: {
    async setUtmProductsOnCollection(products: Product[]) {
      this.utmProducts = products
    },

    // collection zone
    async getCollectionByHandle(
      payload: GetCollectionByHandleParams
    ): Promise<NullOrType<Collection>> {
      try {
        let collection = null
        const {
          handle,
          numberOfProducts = 30,
          isForceLoadNewData = false,
          after,
          before,
          sortKey,
          reverse,
          filters,
        } = payload
        timeStart('Get collection by handle ' + handle)
        const cacheKey = buildCollectionCacheKeyByHandle({
          handle,
          numberOfProducts,
          after,
          before,
          sortKey,
          reverse,
          filters,
        })
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const collectionFromCache = getJSONfromCacheResponse(cacheResponse)
          if (collectionFromCache) {
            collection = collectionFromCache
          }
        }

        if (!collection) {
          const provider = await Provider.getInstance()
          collection = await provider.getCollectionByHandle({
            handle,
            numberOfProducts,
            after,
            before,
            sortKey,
            reverse,
            filters,
          })
          collection = fillupCollectionProperties(collection)

          if (collection) {
            CacheService.instance?.set(cacheKey, collection)
            collection.__cacheKey = cacheKey
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }

        timeEnd('Get collection by handle ' + handle)
        return collection
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error when get collection by handle', {
          handle: payload.handle,
          error,
        })
        throw error
      }
    },

    async getCollectionByHandleAndProductByIds(
      payload: GetCollectionByHandleParams
    ): Promise<CollectionAndProducts> {
      try {
        const {
          handle,
          numberOfProducts = 30,
          after,
          before,
          sortKey,
          reverse,
          filters,
          productIds,
        } = payload
        timeStart(
          'Get collection by handle and product by ids' + { handle, productIds }
        )
        const provider = await Provider.getInstance()
        const data = await provider.getCollectionByHandleAndProductByIds({
          handle,
          numberOfProducts,
          after,
          before,
          sortKey,
          reverse,
          filters,
          productIds,
        })
        if (data) {
          data.collection = fillupCollectionProperties(data.collection)
          data.products = data.products?.map(
            (product) => fillUpProductProperties(product) as Product
          )
        }
        timeEnd(
          'Get collection by handle and product by ids' + { handle, productIds }
        )
        return data
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error when get collection by handle and product by ids', {
          handle: payload.handle,
          productIds: payload.productIds,
          error,
        })
        throw error
      }
    },

    async getCollectionByHandleAndProductByHandle(
      payload: GetCollectionByHandleParams
    ): Promise<CollectionAndProduct> {
      try {
        const {
          handle,
          numberOfProducts = 30,
          after,
          before,
          sortKey,
          reverse,
          filters,
          productHandle,
        } = payload
        timeStart(
          'Get collection by handle and product by handle' +
            { handle, productHandle }
        )
        const provider = await Provider.getInstance()
        const data = await provider.getCollectionByHandleAndProductByHandle({
          handle,
          numberOfProducts,
          after,
          before,
          sortKey,
          reverse,
          filters,
          productHandle,
        })
        if (data) {
          data.collection = fillupCollectionProperties(data.collection)
          data.product = fillUpProductProperties(data.product)
        }
        timeEnd(
          'Get collection by handle and product by handle' +
            { handle, productHandle }
        )
        return data
      } catch (error: any) {
        Analytics.error(error)
        Logger.error(
          'Error when get collection by handle and product by handle',
          {
            handle: payload.handle,
            productHandle: payload.productHandle,
            error,
          }
        )
        throw error
      }
    },

    // get and set collection to store
    async fetchCollection(
      payload: GetCollectionByHandleParams
    ): Promise<
      NullOrType<CollectionAndProducts | CollectionAndProduct | Collection>
    > {
      const { productHandle, productIds } = payload
      this.isCollectionNotFound = false
      if (productIds && productIds.length) {
        const data = await this.getCollectionByHandleAndProductByIds(payload)
        this.collection = data.collection
        this.utmProducts = data.products?.length ? data.products : []
        return data
      } else if (productHandle) {
        const data = await this.getCollectionByHandleAndProductByHandle(payload)
        this.collection = data.collection
        this.utmProducts = data.product ? [data.product] : []
        return data
      } else {
        const data = await this.getCollectionByHandle(payload)
        this.collection = data
        return data
      }
    },

    async getUpsellCollectionByHandle(
      payload: GetCollectionByHandleParams
    ): Promise<NullOrType<Collection>> {
      try {
        let collection = null
        const {
          handle,
          numberOfProducts = 30,
          isForceLoadNewData = false,
          after,
          before,
          sortKey,
          reverse,
          filters,
        } = payload
        timeStart('Get collection by handle ' + handle)
        const cacheKey = buildCollectionCacheKeyByHandle({
          handle,
          numberOfProducts,
          after,
          before,
          sortKey,
          reverse,
          filters,
        })
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const collectionFromCache = getJSONfromCacheResponse(cacheResponse)
          if (collectionFromCache) {
            collection = collectionFromCache
          }
        }

        if (!collection) {
          const provider = await Provider.getInstance()
          collection = await provider.getCollectionByHandle({
            handle,
            numberOfProducts,
            after,
            before,
            sortKey,
            reverse,
            filters,
          })
          collection = fillupCollectionProperties(collection)

          if (collection) {
            CacheService.instance?.set(cacheKey, collection)
            collection.__cacheKey = cacheKey
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }

        timeEnd('Get collection by handle ' + handle)

        this.upsellCollection = collection

        return collection
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error when get upsell collection by handle', {
          handle: payload.handle,
          error,
        })
        throw error
      }
    },
  },
})

export default useCollectionStore

export function fillupCollectionProperties(
  collection: NullOrType<Collection>
): NullOrType<Collection> {
  if (!collection) return null
  if (collection.products && collection.products.length > 0) {
    collection.products = collection.products.map((product) =>
      fillUpProductProperties(product)
    )
  }
  return collection
}
