import { defineStore } from 'pinia'

interface DeviceState {
  isMobile: boolean
  isPhone: boolean
  showingPopups: string[]
  firstInputFired: boolean
  preloadImageLoaded: boolean
}

const useDeviceStore = defineStore('device', {
  state: (): DeviceState => ({
    isMobile: true,
    isPhone: false,
    showingPopups: [],
    firstInputFired: false,
    preloadImageLoaded: false,
  }),
})

export default useDeviceStore
