<template>
  <div class="sib-input-wrapper">
    <component
      :is="type"
      ref="input"
      v-bind="$attrs"
      :value="currentValue"
      @input="onChange"
      :placeholder="placeholder"
      :type="currentTypeValue"
      :class="classes"
      :maxlength="maxlength"
      :required="required"
      :readonly="readonly"
    />
    <div
      class="sib-input__clear-button"
      v-if="isShowClearButton"
      v-show="currentValue"
      @click="clearInput"
    >
      <slot name="close">
        <IconClose />
      </slot>
    </div>
    <div
      class="sib-input__password-icon"
      v-if="isShowPasswordIcon"
      @click="toggle"
    >
      <slot name="password-icon">
        <EyePasswordShow v-show="currentTypeValue == 'password'" />
        <EyePasswordHide v-show="currentTypeValue == 'text'" />
      </slot>
    </div>

    <!-- we use this input tag to push attribute and value to cart lineItem -->
    <input
      v-if="attributeKey"
      type="hidden"
      :name="`properties[${attributeKey}]`"
      :value="currentValue"
    />
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { UIKitRole, UIKitRound, UIKitSize } from './types'
import IconClose from '@/assets/images/icons/icon-close.svg'
import EyePasswordShow from '@/assets/images/icons/eye-password-show.svg'
import EyePasswordHide from '@/assets/images/icons/eye-password-hide.svg'

interface InputProps {
  placeholder?: string
  role?: UIKitRole
  size?: UIKitSize
  round?: UIKitRound
  isShowClearButton?: boolean
  modelValue?: string
  type?: 'input' | 'textarea'
  valueType?: 'text' | 'password'
  maxlength?: number
  required?: boolean
  attributeKey?: string
  isShowPasswordIcon?: boolean
  readonly?: boolean
}

const props = withDefaults(defineProps<InputProps>(), {
  role: 'default',
  size: 'md',
  round: 'none',
  isShowClearButton: false,
  type: 'input',
  valueType: 'text',
  required: false,
  isShowPasswordIcon: false,
  readonly: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const input = ref<HTMLInputElement | null>(null)
const currentValue = ref(props.modelValue)
const currentTypeValue = ref(props.valueType)

defineExpose({
  input,
  focus,
  clearInput,
})

const baseClass = 'sib-input'

const classes = computed(() => {
  const classes = [baseClass]
  if (props.role) {
    classes.push(`${baseClass}--role-${props.role}`)
  }
  if (props.size) {
    classes.push(`${baseClass}--size-${props.size}`)
  }
  if (props.round) {
    classes.push(`${baseClass}--round-${props.round}`)
  }
  if (props.round) {
    classes.push(`${baseClass}--type-${props.type}`)
  }
  return classes
})

function onChange(event: Event) {
  currentValue.value = (event.target as HTMLInputElement)?.value
  emit('update:modelValue', currentValue.value)

  if (props.type == 'textarea') {
    const target = event.target as HTMLTextAreaElement
    target.style.height = 'auto'
    target.style.height = target.scrollHeight + 'px'
  }
}

function clearInput() {
  currentValue.value = ''
  emit('update:modelValue', currentValue.value)
}

function focus() {
  input.value?.focus()
}

function toggle() {
  if (currentTypeValue.value == 'text') {
    currentTypeValue.value = 'password'
  } else {
    currentTypeValue.value = 'text'
  }
}
</script>
