import { isOnServer } from './ssr'
import { IdleQueue } from 'idlize/IdleQueue.mjs'
import EventBus from '@/services/eventbus'
import { EVENT_APP_MOUNTED } from './constants'

const isSupportsRequestIdleCallback = typeof requestIdleCallback === 'function'

const idleQueue = isOnServer ? [] : new IdleQueue()
let isAppMounted = false

// all these task that run on Idle time should be prepared for the case to run many times
export const runTaskOnIdleTime = (task: () => void, ...args: any[]) => {
  if (isOnServer) {
    task()
    return
  }

  if (isSupportsRequestIdleCallback) {
    // wrap the task into timeout so Vue can update DOM between task
    const timeOutTask = () => setTimeout(task, 0)
    idleQueue.pushTask(timeOutTask, ...args)
  } else {
    // if the browser does not support idle callback
    // we will add the callback to queue after the app fully loaded
    // the task will be run on a setTimeout
    if (isAppMounted) {
      idleQueue.pushTask(task, ...args)
    } else {
      EventBus.on(EVENT_APP_MOUNTED, () => {
        isAppMounted = true
        idleQueue.pushTask(task, ...args)
      })
    }
  }
}
