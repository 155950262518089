export const CACHE_RESPONSE_TYPE_STRING = 'string'
export const CACHE_RESPONSE_TYPE_JSON = 'json'
export const CACHE_RESPONSE_TYPE_BLOB = 'blob'
export const CACHE_RESPONSE_TYPE_ARRAY_BUFFER = 'arrayBuffer'
export const CACHE_RESPONSE_TYPE_ANY = 'any'
export const CACHE_TTL_TYPE_EXPIRED = -2
export const CACHE_TTL_TYPE_FOREVER = -1

type CACHE_RESPONSE_TYPE =
  | typeof CACHE_RESPONSE_TYPE_STRING
  | typeof CACHE_RESPONSE_TYPE_JSON
  | typeof CACHE_RESPONSE_TYPE_BLOB
  | typeof CACHE_RESPONSE_TYPE_ARRAY_BUFFER
  | typeof CACHE_RESPONSE_TYPE_ANY

export type CacheTTLType =
  | typeof CACHE_TTL_TYPE_EXPIRED
  | typeof CACHE_TTL_TYPE_FOREVER
  | number

export interface CacheResponse {
  value: any
  type: CACHE_RESPONSE_TYPE
}

export abstract class MyCache {
  abstract init?(): void
  abstract get(key: string): Promise<CacheResponse>
  abstract set(key: string, value: any, ttl?: number): void
  abstract delete(key: string): void
  abstract getTTL(key: string): Promise<CacheTTLType>
}
