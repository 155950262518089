import fetch from 'cross-fetch'
import Analytics from './analytics'
import Logger from './log'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
export interface HttpHeader {
  [key: string]: string
}

export interface Payload {
  url: string
  body?: any
}

const Http = {
  async request(method: HttpMethod, payload: Payload, headers?: HttpHeader) {
    const response = await fetch(payload.url, {
      method,
      headers: headers,
      body: payload.body || undefined,
    })
    
    const body = await getResponseBody(response)
    if (response.ok) {
      return body
    }

    return Promise.reject({
      error: true,
      message: response.statusText,
      status: response.status,
      body,
    })
  },
  async get(url: string, headersInput?: HttpHeader) {
    return this.request('GET', { url }, headersInput)
  },
  async post(url: string, body?: any, headersInput?: HttpHeader) {
    return this.request('POST', { url, body }, headersInput)
  },
  async put(url: string, body?: any, headersInput?: HttpHeader) {
    return this.request('PUT', { url, body }, headersInput)
  },
}

function getResponseBody(response: Response) {
  try {
    // if response is json
    if (response.headers.get('content-type') === 'application/json') {
      return response.json()
    }
    // if response is blob
    if (response.headers.get('content-type') === 'application/octet-stream') {
      return response.blob()
    }

    if (response.headers.get('content-type') === 'application/xml') {
      return response.blob()
    }
    if (response.headers.get('content-type') === 'text/xml') {
      return response.text()
    }

    return response.json()
  } catch (error: any) {
    Logger.error('Error while parsing response body', error)
    Analytics.error(error)
  }
}

export default Http
