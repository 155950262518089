<template>
  <div class="account-popup">
    <Popup
      class="account-popup popup--zoom-default"
      v-model:show="showing"
      transition="zoom"
    >
      <div class="account-popup__body account-popup__layout">
        <div class="account-popup__close-icon" @click="close">
          <CloseIcon />
        </div>
        <div class="account-popup__title">{{ popupTitle }}</div>
        <div>
          <Verification
            v-show="component == VERIFICATION"
            @email-existed="onHandleEmail"
          />
          <SignIn
            v-if="component == SIGN_IN"
            type="popup"
            @sign-in="onHandleSignIn"
            @recover-password="onHandleRecoverPassword"
          />
          <SignUp
            v-if="component == SIGN_UP"
            type="popup"
            @sign-up="onHandleSignUp"
          />
          <Recover v-if="component == RECOVER" type="popup" />

          <div class="account-popup__note" v-show="component != VERIFICATION">
            <span v-show="component == SIGN_IN" @click="onSignUp">
              Create an account
            </span>
            <span
              v-show="component == SIGN_UP || component == RECOVER"
              @click="onSignIn"
            >
              Sign In
            </span>
            or
            <span @click="oncancel">Cancel</span>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script lang="ts" setup>
import Popup from '@/ui-kits/Popup.vue'
import Verification from './Verification.vue'
import SignIn from './SignIn.vue'
import SignUp from './SignUp.vue'
import Recover from './Recover.vue'
import { ref } from 'vue'
import CloseIcon from '@/assets/images/icons/icon-close.svg'

const SIGN_IN = 'SignIn'
const SIGN_UP = 'SignUp'
const VERIFICATION = 'Verification'
const RECOVER = 'Recover'
const SIGN_IN_TITLE = 'Sign in to your account'
const SIGN_UP_TITLE = 'Create your account'
const RECOVER_TITLE = 'Recover password'
const component = ref(VERIFICATION)
const showing = ref(false)
const popupTitle = ref(SIGN_IN_TITLE)

defineExpose({
  open,
})

function close() {
  component.value = VERIFICATION
  showing.value = false
}
function open() {
  showing.value = true
}
function onHandleEmail(isEmailExisted: boolean) {
  if (!isEmailExisted) component.value = SIGN_IN
}
function onHandleSignIn(success: boolean) {
  if (success) close()
}
function onHandleSignUp(success: boolean) {
  if (success) close()
}
function onSignUp() {
  component.value = SIGN_UP
  popupTitle.value = SIGN_UP_TITLE
}
function onSignIn() {
  component.value = SIGN_IN
  popupTitle.value = SIGN_IN_TITLE
}
function onHandleRecoverPassword() {
  component.value = RECOVER
  popupTitle.value = RECOVER_TITLE
}
function oncancel() {
  component.value = VERIFICATION
  popupTitle.value = SIGN_IN_TITLE
}
</script>

<style lang="scss">
.account-popup {
  $S: &;

  &__layout {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 2rem;
    @include media-md-down {
      height: 100%;
      width: auto;
    }
  }
  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
  }
  &__note {
    margin-top: 2rem;
    text-align: center;
    span {
      font-weight: 600;
      color: var(--color-primary);
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline;
    }
  }
  &__close-icon {
    position: absolute;
    height: 30px;
    width: 30px;
    top: -2.5em;
    right: 0.3em;
    border-radius: 50%;
    background: rgb(70, 70, 70);
    z-index: var(--popup-z-index);
    cursor: pointer;
    svg {
      position: absolute;
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      path {
        fill: #cecece;
      }
    }
    &:hover {
      background: rgb(70, 70, 70, 0.8);
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
</style>
