<template>
  <div class="lightbox-popup">
    <div
      class="lightbox-popup__trigger"
      :data-sib-fancybox="group"
      :data-src="'#' + contentId"
      ref="trigger"
    >
      <slot name="trigger"></slot>
    </div>
    <div
      class="lightbox-popup__content"
      :class="[`lightbox-popup__content--${size}`]"
      :id="contentId"
      style="display: none"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Fancybox as FancyboxType } from '@fancyapps/ui'
import { onMounted, onUnmounted, Ref, ref } from 'vue'

interface PopupProps {
  group?: string
  contentId?: string
  size?: 'small' | 'medium' | 'full'
}

const props = withDefaults(defineProps<PopupProps>(), {
  group: 'popup',
  contentId: 'popup-content',
  size: 'medium',
})

const emit = defineEmits<{
  (e: 'close', fancybox: any): void
}>()

defineExpose({
  close,
  show,
})

let Fancybox: FancyboxType | null = null
const trigger: Ref<HTMLDivElement | null> = ref(null)

onMounted(async () => {
  if (!Fancybox) Fancybox = (await import('@fancyapps/ui')).Fancybox
  Fancybox.bind(`[data-sib-fancybox=${props.group}]`, {
    Thumbs: false,
    groupAttr: 'data-sib-fancybox',
    on: {
      closing: (fancybox: any) => {
        emit('close', fancybox)
      },
    },
  })
})

onUnmounted(() => {
  if (Fancybox) {
    Fancybox.close()
    Fancybox.unbind(`[data-sib-fancybox=${props.group}]`)
  }
})

function close() {
  if (Fancybox) {
    Fancybox.close()
  }
}

function show() {
  if (trigger.value) {
    trigger.value.click()
  }
}
</script>

<style lang="scss">
.lightbox-popup {
  &__trigger {
    display: inline-block;
  }

  &__content {
    &.fancybox__content {
      padding: 1em;
      width: 100%;
    }

    &--small {
      &.fancybox__content {
        width: auto;
      }
    }
  }
}
</style>
