<template>
  <div class="wishlist-icon" @click="goToWishlist">
    <HeartIcon />
    <span class="wishlist-badge" v-if="totalWishlistItems">{{
      totalWishlistItems
    }}</span>
  </div>
</template>
<script setup lang="ts">
import HeartIcon from '@/assets/images/icons/icon-heart.svg'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import EventBus from '@/services/eventbus'
import { Product } from '@/types'
import {
  CACHE_KEY_WISH_LIST_ITEMS,
  EVENT_SAVE_PRODUCT_WISH_LIST,
} from '@/utils'
import { onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const totalWishlistItems = ref(0)
let timeout: any = 0

onMounted(async () => {
  totalWishlistItems.value = await getProductWishlist()

  EventBus.on(EVENT_SAVE_PRODUCT_WISH_LIST, saveProductWishlistHandler)
})

onUnmounted(() => {
  clearTimeout(timeout)
  EventBus.off(EVENT_SAVE_PRODUCT_WISH_LIST, saveProductWishlistHandler)
})

function saveProductWishlistHandler() {
  timeout = setTimeout(async () => {
    totalWishlistItems.value = await getProductWishlist()
  }, 250)
}

async function getProductWishlist() {
  const wishlistItemsFromCache = await CacheService.instance?.get(
    CACHE_KEY_WISH_LIST_ITEMS
  )
  const wishlistItems: Product[] =
    getJSONfromCacheResponse(wishlistItemsFromCache) || []

  return wishlistItems.length
}

function goToWishlist() {
  router.push(`/pages/wishlist`)
}
</script>

<style lang="scss">
.wishlist-icon {
  cursor: pointer;
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 7px;
  svg {
    width: inherit;
    height: inherit;
    stroke: #000000;
  }
  @include media-desktop {
    margin-right: 0;
  }

  .wishlist-badge {
    position: absolute;
    top: -7px;
    right: -8px;
    width: 15px;
    height: 15px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 50%;
  }
}
</style>
