<template>
  <div class="sign-up">
    <div class="sign-up-form">
      <form @submit.prevent="onSubmit" ref="form">
        <div>
          <sib-form-item
            label="First Name"
            input-id="sign-up-form__first-name"
            :value="customer.firstName"
            is-required
            ref="itemFirstName"
            error-message="First Name is required"
          >
            <sib-input
              id="sign-up-form__first-name"
              size="sm"
              placeholder="Enter your first name"
              v-model="customer.firstName"
              name="firstName"
            ></sib-input>
          </sib-form-item>

          <sib-form-item
            label="Last Name"
            input-id="sign-up-form__last-name"
            :value="customer.lastName"
            is-required
            ref="itemLastName"
            error-message="Last Name is required"
          >
            <sib-input
              id="sign-up-form__last-name"
              size="sm"
              placeholder="Enter your last name"
              v-model="customer.lastName"
              name="lastName"
            ></sib-input>
          </sib-form-item>
        </div>

        <sib-form-item
          label="Email"
          input-id="sign-up-form__email"
          :value="customer.email"
          is-required
          :validator="isEmail"
          ref="itemEmail"
          error-message="Email is invalid"
        >
          <sib-input
            id="sign-up-form__email"
            size="sm"
            placeholder="Enter your email"
            v-model="customer.email"
            name="email"
          ></sib-input>
        </sib-form-item>

        <sib-form-item
          label="Password"
          input-id="sign-up-form__password"
          :value="customer.password"
          is-required
          ref="itemPassword"
        >
          <sib-input
            id="sign-up-form__password"
            size="sm"
            placeholder="Enter your password"
            v-model="customer.password"
            name="password"
            valueType="password"
            isShowPasswordIcon
          ></sib-input>
        </sib-form-item>

        <sib-button
          class="sib-button--width-full mt-sm"
          role="primary"
          :loading="loading"
        >
          CREAT ACCOUNT
        </sib-button>
        <p v-show="isError" class="sign-up-form__error-message">
          {{
            errorMessage?.includes(ERROR_MESSAGE_ACTIVATE_ACCOUNT_INCLUDE)
              ? `${
                  errorMessage.split(',')[0]
                }, please verify your email address to activate your account.`
              : errorMessage
          }}
        </p>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  reactive,
  nextTick,
  onUnmounted,
  computed,
  onMounted,
  watch,
} from 'vue'
import Analytics from '@/services/analytics'
import Logger from '@/services/log'
import FormItem from '@/ui-kits/FormItem.vue'
import { FORM_ITEM_INVALID_SELECTOR, HEADER_HEIGHT, isEmail } from '@/utils'
import SibFormItem from '@/ui-kits/FormItem.vue'
import useCustomerStore from '@/store/customer'
import { useRouter } from 'vue-router'
import { usePage } from '@/composables/page'

const props = withDefaults(defineProps<{ type?: string }>(), {
  type: 'page',
})

const emit = defineEmits<{
  (e: 'sign-up', value: boolean): void
}>()

const { setPageTitle } = usePage()
const router = useRouter()
const customerStore = useCustomerStore()

const errorMessage = computed(() => customerStore.errorMessage)
const customerEmail = computed(() => customerStore.customerEmail)

const customer = reactive({
  firstName: '',
  lastName: '',
  email: customerEmail.value || '',
  password: '',
})
const itemFirstName = ref<typeof FormItem | null>(null)
const itemLastName = ref<typeof FormItem | null>(null)
const itemEmail = ref<typeof FormItem | null>(null)
const itemPassword = ref<typeof FormItem | null>(null)

const form = ref<HTMLFormElement | null>(null)

const loading = ref(false)
const isError = ref(false)

// the customer will get this message when they create new account but the email existed
const ERROR_MESSAGE_ACTIVATE_ACCOUNT_INCLUDE = 'We have sent an email to'

onMounted(() => {
  watch(
    () => customerEmail.value,
    (value) => {
      if (value) {
        customer.email = value
      }
    },
    { immediate: true }
  )
})

onUnmounted(() => {
  customerStore.errorMessage = null
})

async function onSubmit() {
  const isFirstNameValid = itemFirstName.value?.validate()
  const isLastNamelValid = itemLastName.value?.validate()
  const isEmailValid = itemEmail.value?.validate()
  const isPasswordValid = itemPassword.value?.validate()

  const isValid =
    isFirstNameValid && isLastNamelValid && isEmailValid && isPasswordValid

  if (!isValid) {
    // wait a tick for Vue applying the classes
    await nextTick()
    const firstInvalidElement: HTMLElement | undefined | null =
      form.value?.querySelector(FORM_ITEM_INVALID_SELECTOR)
    if (firstInvalidElement) {
      window.scrollTo({
        top:
          window.scrollY +
          firstInvalidElement.getBoundingClientRect().top -
          HEADER_HEIGHT,
        behavior: 'smooth',
      })
    }
    return
  }

  if (!form.value) return

  loading.value = true
  isError.value = false

  try {
    const response = await customerStore.createCustomer(customer)

    if (response) {
      const signInCustomer = {
        email: customer.email,
        password: customer.password,
      }
      const accessToken = await customerStore.createCustomerAccessToken(
        signInCustomer
      )
      if (accessToken) {
        const response = await customerStore.getCurrentCustomer()
        if (response) {
          emit('sign-up', true)
          if (props.type == 'page') {
            router.push(`/account/customer`)
            setPageTitle('Customer')
          }
        } else {
          emit('sign-up', false)
        }
      }
    } else {
      isError.value = true
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error: any) {
    Logger.error('Error when sign up', error)
    Analytics.error(error)
  }
  loading.value = false
}
</script>

<style lang="scss">
.sign-up {
  &-form {
    &__error-message {
      color: var(--color-secondary);
    }
    .sib-form-item {
      margin: 1em 0;
      @include media-md-up {
        margin-top: 1.75em;
      }
    }
  }
}
</style>
