<template>
  <button class="sib-button" :class="classes">
    <div class="sib-button__content">
      <slot></slot>
    </div>

    <slot name="loading">
      <div class="sib-button__loading lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </slot>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { UIKitRole, UIKitRound, UIKitSize } from './types'
interface ButtonProps {
  role?: UIKitRole
  size?: UIKitSize
  loading?: boolean
  width?: 'full' | 'auto' | 'default'
  round?: UIKitRound
  bold?: boolean
}

const props = withDefaults(defineProps<ButtonProps>(), {
  role: 'default',
  size: 'md',
  loading: false,
  width: 'default',
  round: 'md',
})

const classes = computed(() => {
  const classes = []
  if (props.role) {
    classes.push(`sib-button--${props.role}`)
  }
  if (props.size) {
    classes.push(`sib-button--${props.size}`)
  }
  if (props.loading) {
    classes.push('sib-button--loading')
  }
  if (props.width) {
    classes.push(`sib-button--width-${props.width}`)
  }
  if (props.round) {
    classes.push(`sib-button--round-${props.round}`)
  }
  if (props.bold) {
    classes.push(`sib-button--bold`)
  }
  return classes
})
</script>
