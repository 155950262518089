export function injectScript(
  src: string,
  onload?: (event: Event) => any,
  isAsync?: boolean
) {
  const script = document.createElement('script')
  script.src = src
  if (onload && typeof onload === 'function') {
    script.addEventListener('load', onload)
  }
  if (isAsync) {
    script.async = true
  }

  document.head.appendChild(script)
}

export function injectStyle(href: string, onload?: (event: Event) => any) {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = href
  if (onload && typeof onload === 'function') {
    link.addEventListener('load', onload)
  }

  document.head.appendChild(link)
}

export function appendChildElementIntoParentElement(
  parent: Element,
  child: Element,
  index: number
): void {
  if (!parent || !child) return
  if (!index) index = 0

  if (index >= parent.children.length) {
    parent.appendChild(child)
    return
  }

  parent.insertBefore(child, parent.children[index])
}

export function getElementhWithText(parent: any, tag: string, text: string) {
  const elements = parent.getElementsByTagName(tag)
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i]
    if (element.textContent.includes(text)) {
      return element
    }
  }
  return null
}
