<template>
  <!-- TrustBox widget - Micro Review Count -->
  <div
    class="trustpilot-widget"
    data-locale="en-US"
    :data-template-id="templateId"
    :data-businessunit-id="businessUnitId"
    data-style-height="24px"
    data-style-width="100%"
    data-theme="light"
    :data-min-review-count="minReviewCount || 20"
    data-style-alignment="left"
    v-if="shouldShowTrustpilot"
  >
    <a :href="url" target="_blank" rel="noopener">Trustpilot</a>
  </div>
  <!-- End TrustBox widget -->
</template>

<script setup lang="ts">
import { injectScript } from '@/utils/dom'
import { computed, onMounted } from 'vue'

interface TrustpilotProps {
  templateId: string
  businessUnitId: string
  minReviewCount?: number
  url: string
}
const props = defineProps<TrustpilotProps>()
const shouldShowTrustpilot = computed(
  () => !!props.businessUnitId && props.templateId && props.url
)
const trustpilotScriptURL =
  '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'

onMounted(() => {
  if (shouldShowTrustpilot.value) {
    injectScript(trustpilotScriptURL, undefined, true)
  }
})
</script>

<style lang="scss">
.trustpilot-widget {
  position: relative;
  margin-top: 2em;
  border: solid 1px var(--border-color);
  padding: 8px 0;
  border-radius: 6px;
}
</style>
