<template>
  <select
    class="select"
    :class="classes"
    :value="currentValue"
    @change="onChange"
  >
    <option
      :value="option.value"
      :selected="currentValue == option.value ? true : undefined"
      v-for="(option, index) of options"
      :key="index"
    >
      {{ option.label }}
    </option>

    <!-- we use input tag to push attribute and value to cart lineItem -->
    <input
      v-if="attributeKey"
      type="hidden"
      :name="`properties[${attributeKey}]`"
      :value="currentValue"
    />
  </select>
</template>

<script lang="ts" setup>
import { computed } from '@vue/reactivity'
import { ref } from 'vue'

interface SelectProps {
  value: string
  options: Array<{
    value: string
    label: string
  }>
  role?: string
  size?: string
  attributeKey?: string
}
const props = withDefaults(defineProps<SelectProps>(), {
  value: '',
  options: () => [],
  role: 'default',
  size: 'md',
})

const classes = computed(() => {
  const classes = []
  if (props.role) {
    classes.push(`select--${props.role}`)
  }
  if (props.size) {
    classes.push(`select--${props.size}`)
  }
  return classes
})

const emit = defineEmits<{
  (e: 'input', value: string): void
}>()

const currentValue = ref(props.value)

function onChange(event: Event) {
  currentValue.value = (event.target as HTMLInputElement)?.value
  emit('input', currentValue.value)
}
</script>
