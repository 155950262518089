import { isOnServer } from './ssr'

export interface QueryStringParameters {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | undefined
    | null
}

export function buildQueryString(
  params: QueryStringParameters,
  currentParams?: string
): string {
  let urlSearchParams
  if (currentParams) {
    urlSearchParams = new URLSearchParams(currentParams)
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const element = params[key]
        urlSearchParams.set(key, element as string)
      }
    }
  } else {
    urlSearchParams = new URLSearchParams(params as Record<string, string>)
  }
  return urlSearchParams.toString()
}

export function buildCanonicalURL(url: string) {
  return `<link rel="canonical" href="${url}">`
}

export function getDomainName(domainInput?: string) {
  let domain = domainInput
  if (!domain && !isOnServer) {
    domain = window.location.hostname
  }
  if (domain) {
    const domainParts = domain.split('.')
    domain = domainParts.slice(domainParts.length - 2).join('.')
  }
  return domain || ''
}
