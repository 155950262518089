import { DefineComponent } from 'vue'
import Button from './Button.vue'
import Image from './Image.vue'
import Carousel from './Carousel.vue'
import Select from './Select.vue'
import SkeletonLoading from './SkeletonLoading.vue'
import Input from './Input.vue'
import Link from './Link.vue'
import Checkbox from './Checkbox.vue'
import Video from './Video.vue'
import Media from './Media.vue'

interface UIKits {
  [key: string]: DefineComponent<any, any, any>
}
const components: UIKits = {
  'sib-button': Button,
  'sib-image': Image,
  'sib-carousel': Carousel,
  'sib-select': Select,
  'sib-skeleton-loading': SkeletonLoading,
  'sib-input': Input,
  'sib-link': Link,
  'sib-checkbox': Checkbox,
  'sib-video': Video,
  'sib-media': Media,
}
export default components
