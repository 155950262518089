import { ProductFilter } from '@/provider/type'
import { GetCollectionByHandleParams } from '@/store/collection'
import {
  CACHE_KEY_COLLECTION_BY_HANDLE,
  CACHE_KEY_PREFIX,
  COOKIE_KEY_PREFIX,
} from './constants'

// TODO check usage of this function and remove all consumers that is not in cache services
export const buildCacheKey = (key: string) => `${CACHE_KEY_PREFIX}${key}`
export const buildCollectionCacheKeyByHandle = (
  payload: GetCollectionByHandleParams
) => {
  let key = `${CACHE_KEY_COLLECTION_BY_HANDLE}:${payload.handle}`
  const cacheKeys: Array<keyof GetCollectionByHandleParams> = [
    'after',
    'before',
    'sortKey',
    'reverse',
    'numberOfProducts',
  ]
  cacheKeys.forEach((cacheKey) => {
    if (payload[cacheKey] !== null && payload[cacheKey] !== undefined) {
      key += `_${cacheKey}:${payload[cacheKey]}`
    }
  })
  if (payload.filters) {
    payload.filters.forEach((filter, index) => {
      for (const filterKey in filter) {
        if (Object.prototype.hasOwnProperty.call(filter, filterKey)) {
          const element = filter[filterKey as keyof ProductFilter]
          key += `_filter${index}.${filterKey}:${element}`
        }
      }
    })
  }
  return key
}

export const buildCookieKey = (key: string) => `${COOKIE_KEY_PREFIX}${key}`
