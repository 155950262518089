import { defineStore } from 'pinia'
import { NullOrType } from '@/types'
import Logger from '@/services/log'
import Provider from '@/provider'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import Analytics from '@/services/analytics'
export interface BlogState {
  articleBlog: NullOrType<any>
  listArticleBlog: NullOrType<any>
  isArticleBlogNotFound: boolean
  isListArticleBlogNotFound: boolean
}

const useBlogStore = defineStore('blog', {
  state: (): BlogState => ({
    articleBlog: null,
    listArticleBlog: null,
    isArticleBlogNotFound: false,
    isListArticleBlogNotFound: false,
  }),

  actions: {
    resetBlogState() {
      this.articleBlog = null
      this.isArticleBlogNotFound = false
    },
    resetListArticleBlogState() {
      this.listArticleBlog = null
      this.isListArticleBlogNotFound = false
    },
    async getArticleBlogByHandle({
      blogHandle,
      articleHandle,
      isForceLoadNewData,
    }: {
      blogHandle: string
      articleHandle: string
      isForceLoadNewData?: boolean
    }) {
      this.resetBlogState()
      try {
        let articleBlog = null
        const cacheKey = `blog_getArticleBlogByHandle_${blogHandle}_${articleHandle}`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const blogFromCache = getJSONfromCacheResponse(cacheResponse)

          if (blogFromCache) {
            articleBlog = blogFromCache
          }
        }

        if (!articleBlog) {
          const provider = await Provider.getInstance()
          const respone = await provider.getArticleBlogByHandle(
            blogHandle,
            articleHandle
          )
          if (respone) articleBlog = respone.blogByHandle?.articleByHandle
          if (articleBlog) {
            CacheService.instance?.set(cacheKey, articleBlog)
            articleBlog.__cacheKey = cacheKey
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }

        this.articleBlog = articleBlog
        return articleBlog
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error on getArticleBlogByHandle', {
          error,
          blogHandle,
          articleHandle,
        })
        throw error
      }
    },

    async getListArticleBlogByHandle(payload: {
      handle: string
      numberOfArticles?: number
      after?: string
      before?: string
      isForceLoadNewData?: boolean
    }): Promise<any> {
      try {
        let listArticleBlog = null
        const { handle, after, before, numberOfArticles, isForceLoadNewData } =
          payload

        const cacheKey = `blog_getListArticleBlogByHandle_${handle}`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const listArticleBlogFromCache =
            getJSONfromCacheResponse(cacheResponse)
          if (listArticleBlogFromCache) {
            listArticleBlog = listArticleBlogFromCache
          }
        }

        if (!listArticleBlog) {
          const provider = await Provider.getInstance()
          listArticleBlog = await provider.getListArticleBlogByHandle({
            handle,
            after,
            before,
            numberOfArticles,
          })

          if (listArticleBlog) {
            CacheService.instance?.set(cacheKey, listArticleBlog)
            listArticleBlog.__cacheKey = cacheKey
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }
        this.listArticleBlog = listArticleBlog

        return listArticleBlog
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error when get list article blog by handle', {
          handle: payload.handle,
          error,
        })
        throw error
      }
    },
  },
})

export default useBlogStore
