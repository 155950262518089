<template>
  <Popup
    class="confirmation-popup"
    :group="group"
    :contentId="contentId"
    size="small"
    ref="popup"
    @close="onClose"
  >
    <template #trigger>
      <slot> </slot>
    </template>
    <template #default>
      <h3 class="confirmation-popup__header">
        {{ title }}
      </h3>
      <div class="confirmation-popup__message mb-md" v-if="message">
        {{ message }}
      </div>
      <div class="confirmation-popup__buttons">
        <sib-button
          width="auto"
          class="confirmation-popup__button"
          @click="close"
        >
          {{ cancelButton }}
        </sib-button>
        <sib-button
          class="confirmation-popup__button"
          role="primary"
          width="auto"
          @click="onConfirm"
        >
          {{ deleteButton }}
        </sib-button>
      </div>
    </template>
  </Popup>
</template>

<script setup lang="ts">
import { Ref, ref } from 'vue'
import Popup from './LightBoxPopup.vue'

interface PopupProps {
  group?: string
  contentId?: string
  title?: string
  message?: string
  deleteButton?: string
  cancelButton?: string
}

withDefaults(defineProps<PopupProps>(), {
  group: 'confirmation-popup',
  contentId: 'confirmation-popup-content',
  title: 'Are you sure you want to remove this item?',
  message: '',
  deleteButton: 'Delete',
  cancelButton: 'Cancel',
})

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
}>()

let currentResolve: (e: boolean) => void

defineExpose({
  confirm,
})

const popup: Ref<typeof Popup | null> = ref(null)
function close() {
  if (popup.value) {
    popup.value.close()
  }
}
function show() {
  if (popup.value) {
    popup.value.show()
  }
}
function onConfirm() {
  if (currentResolve) {
    currentResolve(true)
  }
  emit('confirm')
  close()
}

function onClose() {
  if (currentResolve) {
    currentResolve(false)
  }
  emit('close')
}

function confirm() {
  return new Promise<boolean>((resolve) => {
    currentResolve = resolve
    if (!popup.value) resolve(false)
    show()
  })
}
</script>

<style lang="scss">
.confirmation-popup {
  &__header {
    margin: 0 0 1em;
    line-height: 1.2;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    gap: 1em;
  }
}
</style>
