import { defineStore } from 'pinia'
import Logger from '@/services/log'
import Provider from '@/provider'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import Analytics from '@/services/analytics'
import {
  NullOrType,
  RequestKolCampaignCodeTracking,
  RequestKolCampaignTracking,
} from '@/types'

interface OrderKolCampaignTracking {
  order_name: string
  email: string
  campaign_code: string
  campaign_name: string
  total_amount: number
}

interface KolCampaignTracking {
  customer: OrderKolCampaignTracking[]
  all: OrderKolCampaignTracking[]
  sumAmount: number
  totalCount: number
}

interface KolCampaign {
  code: string
  name: string
  totalAmountPercentage: number
  donateConfiguration: number[]
  description: string
  blogUrl: string
}

interface photoAlbum {
  page: string | number
  image: string
}

export interface MediaCampaignState {
  greetingCardChoosed: boolean
  greetingCardFlow: boolean
  blogContent: any
  isGreetingCardBlogNotFound: boolean
  kolCampaign: NullOrType<KolCampaign>
  kolCampaignCode: NullOrType<string>
  kolCampaignTracking: NullOrType<KolCampaignTracking>
  allKolCampaignTrackingByCode: NullOrType<KolCampaignTracking>
  requestTracking: RequestKolCampaignTracking
  requestTrackingByCode: RequestKolCampaignCodeTracking
  errorKolCampaignTracking: any
  errorKolCampaignCodeTracking: any
  donationCode: NullOrType<string>
  photoAlbum: photoAlbum[]
  photoAlbumPageNumber: number
}

const useMediaCampaignStore = defineStore('mediaCampaign', {
  state: (): MediaCampaignState => ({
    greetingCardChoosed: false,
    greetingCardFlow: false,
    blogContent: null,
    isGreetingCardBlogNotFound: false,
    kolCampaign: null,
    kolCampaignCode: null,
    kolCampaignTracking: null,
    allKolCampaignTrackingByCode: null,
    requestTracking: { email: '', orderName: '' },
    requestTrackingByCode: { campaignCode: '' },
    errorKolCampaignTracking: null,
    errorKolCampaignCodeTracking: null,
    donationCode: null,
    photoAlbum: [],
    photoAlbumPageNumber: 7,
  }),
  actions: {
    resetPageState() {
      this.blogContent = null
      this.isGreetingCardBlogNotFound = false
    },

    async getBlogGreetingCard({
      handle,
      isForceLoadNewData,
    }: {
      handle: string
      isForceLoadNewData?: boolean
    }) {
      this.resetPageState()
      try {
        let blogContent = null
        const cacheKey = `media_getBlogGreetingCard_${handle}`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const blogContentFromCache = getJSONfromCacheResponse(cacheResponse)

          if (blogContentFromCache) {
            blogContent = blogContentFromCache
          }
        }

        if (!blogContent) {
          const provider = await Provider.getInstance()
          blogContent = await provider.getBlogGreetingCard({ url: handle })
          if (blogContent) {
            CacheService.instance?.set(cacheKey, blogContent)
            blogContent.__cacheKey = cacheKey
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }

        this.blogContent = blogContent
        return blogContent
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error on getBlogGreetingCard', { error, handle })
        throw error
      }
    },

    async getKolCampaign({
      code,
      isForceLoadNewData,
    }: {
      code: string
      isForceLoadNewData?: boolean
    }) {
      try {
        let kolCampaign = null
        const cacheKey = `media_getKolCampaign_${code}`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const kolCampaignFromCache = getJSONfromCacheResponse(cacheResponse)

          if (kolCampaignFromCache) {
            kolCampaign = kolCampaignFromCache
          }
        }

        if (!kolCampaign) {
          const provider = await Provider.getInstance()
          kolCampaign = await provider.getKolCampaign(code)
          if (kolCampaign) {
            CacheService.instance?.set(cacheKey, kolCampaign)
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }

        this.kolCampaign = kolCampaign
        return kolCampaign
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error on getKolCampaign', { error, code })
        throw error
      }
    },

    async getKolCampaignTracking({
      email,
      orderName,
    }: RequestKolCampaignTracking) {
      try {
        this.requestTracking = { email, orderName }

        const provider = await Provider.getInstance()
        const kolCampaignTracking = await provider.getKolCampaignTracking({
          email,
          orderName,
        })

        this.kolCampaignTracking = kolCampaignTracking
        if (!kolCampaignTracking) this.errorKolCampaignTracking = true
        return kolCampaignTracking
      } catch (error: any) {
        this.errorKolCampaignTracking = error
        Analytics.error(error)
        Logger.error('Error on get kol campaign tracking', { error })
        throw error
      }
    },

    async getAllKolCampaignTrackingByCode(campaignCode: string) {
      try {
        this.requestTrackingByCode = { campaignCode }

        const provider = await Provider.getInstance()
        const allKolCampaignTrackingByCode =
          await provider.getAllKolCampaignTrackingByCode(campaignCode)

        this.allKolCampaignTrackingByCode = allKolCampaignTrackingByCode
        if (!allKolCampaignTrackingByCode) this.errorKolCampaignTracking = true
        return allKolCampaignTrackingByCode
      } catch (error: any) {
        this.errorKolCampaignCodeTracking = error
        Analytics.error(error)
        Logger.error('Error on get all kol campaign tracking by code', {
          error,
        })
        throw error
      }
    },
  },
})

export default useMediaCampaignStore
