<template>
  <div class="greeting-card-box">
    <div class="greeting-card-box__container">
      <div class="greeting-card-box__img">
        <LightBoxPopup
          group="greeting-card-guide"
          contentId="greeting-card-popup-content"
        >
          <template #trigger>
            <img
              :style="`width: ${imageWidth}px`"
              src="https://cdn.shopify.com/s/files/1/0595/6042/7716/files/giftbox_300x.webp?v=1695630901"
              alt="greeting card image"
            />
          </template>
          <div class="product__option product__description">
            <h2 class="bordered-bottom">Greeting Card Guide</h2>
          </div>
        </LightBoxPopup>
      </div>
      <div class="greeting-card-box__content">
        <div>
          <label for="greetingCardCheckBox" class="checkbox">
            <input
              class="checkbox__input"
              type="checkbox"
              id="greetingCardCheckBox"
              @input="onChange"
              :checked="isGreetingCardChoosed"
            />
            <svg
              class="checkbox__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22 22"
            >
              <rect
                width="21"
                height="21"
                x=".5"
                y=".5"
                fill="#FFF"
                stroke="#32a867"
                rx="3"
              />
              <path
                class="tick"
                stroke="#32a867"
                fill="none"
                stroke-linecap="round"
                stroke-width="4"
                d="M4 10l5 5 9-9"
              />
            </svg>
            <span class="ml-xs">Choose to buy</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import LightBoxPopup from '@/ui-kits/LightBoxPopup.vue'
import { computed, onMounted, watch } from 'vue'
import useMediaCampaignStore from '@/store/media'

interface GreetingCardBox {
  imageWidth?: number
}

const mediaCampaignStore = useMediaCampaignStore()

withDefaults(defineProps<GreetingCardBox>(), {
  imageWidth: 120,
})

const isGreetingCardChoosed = computed(
  () => mediaCampaignStore.greetingCardChoosed
)
const GREETING_CART_CACHE_KEY = 'greeting_card_choosed'

onMounted(async () => {
  const cacheResponse = await CacheService.instance?.get(
    GREETING_CART_CACHE_KEY
  )
  mediaCampaignStore.greetingCardChoosed =
    getJSONfromCacheResponse(cacheResponse)

  watch(
    () => isGreetingCardChoosed.value,
    () => {
      CacheService.instance?.set(
        GREETING_CART_CACHE_KEY,
        isGreetingCardChoosed.value
      )
    }
  )
})

function onChange(event: Event) {
  mediaCampaignStore.greetingCardChoosed = (
    event.target as HTMLInputElement
  )?.checked
}
</script>

<style lang="scss">
.greeting-card-box {
  background: var(--background-color);
  border: solid 1px var(--border-color);
  border-radius: 3px;
  padding: 1em;
  &__title {
    font-weight: 700;
    margin-bottom: 1em;
    font-size: 16px;
  }
  &__container {
    display: flex;
    gap: 1em;
  }
  &__img {
    img {
      height: 100%;
      aspect-ratio: 1 / 1;
      border: 1px solid var(--border-color);
      cursor: pointer;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 1px;
    }
  }
  .checkbox {
    $block: &;
    cursor: pointer;
    display: flex;
    align-items: center;

    &__input {
      position: absolute;
      width: 1.375em;
      height: 1.375em;
      opacity: 0;
      cursor: pointer;
      &:checked + .checkbox__icon .tick {
        stroke-dashoffset: 0;
      }
    }
    &__icon {
      width: 1.375em;
      height: 1.375em;
      flex-shrink: 0;
      overflow: visible;
      .tick {
        stroke-dasharray: 20px;
        stroke-dashoffset: 20px;
        transition: stroke-dashoffset 0.2s ease-out;
      }
    }
    &:last-child {
      font-weight: 500;
    }
  }
}
</style>
