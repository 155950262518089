<template>
  <div class="product-grid" v-if="products">
    <ProductListItem
      @item-click="$emit('item-click', $event)"
      :is-lazy-loading-image="getLazyLoadAttribute(index)"
      v-for="(product, index) of products"
      :key="product ? product.id : index"
      :product="product"
      :isShowProductListOldVersion="isShowProductListOldVersion"
      :isProductListTM="isProductListTM"
      :isImagesSlider="isImagesSlider"
      :isWishlist="isWishlist"
      :showWishlistIcon="showWishlistIcon"
    >
      <slot :product="product"></slot>
    </ProductListItem>
  </div>
</template>

<script setup lang="ts">
import { Product, NullOrType } from '@/types'
import ProductListItem from './ProductListItem.vue'

interface ProductGridProp {
  products?: NullOrType<Product>[]
  lazyloadStartIndex?: number
  isShowProductListOldVersion?: boolean
  isProductListTM?: boolean
  isImagesSlider?: boolean
  isWishlist?: boolean
  showWishlistIcon?: boolean
}

const props = withDefaults(defineProps<ProductGridProp>(), {
  products: () => [],
  isShowProductListOldVersion: false,
  isProductListTM: false,
  isImagesSlider: false,
  isWishlist: false,
  showWishlistIcon: false,
})

function getLazyLoadAttribute(index: number) {
  if (props.lazyloadStartIndex === 0) {
    return true
  }
  if (!props.lazyloadStartIndex || isNaN(props.lazyloadStartIndex)) return false
  if (index >= props.lazyloadStartIndex) return true
  return false
}
</script>
<style lang="scss">
.product-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5em 1em;

  @include media-sm-up {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5em 1em;
  }

  @include media-lg-up {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1.5em 1.5em;
  }

  @include media-xxl-up {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 1.5em 1.5em;
  }
}
</style>
