<template>
  <div class="container">
    <div class="page-404">
      <div class="ring">
        <div class="ring__one"></div>
        <div class="ring__two"></div>
        <div class="ring__content">
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>
            The link you clicked may be broken or the page may have been removed
            or renamed
          </p>
          <sib-link :to="'/'" title="" class="back-button sib-button">
            Go back
          </sib-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.page-404 {
  margin: 4em auto;
  width: 100%;

  --ring1-size: 87%;
  --ring2-size: 83%;
  --content-size: 78%;

  .ring {
    position: relative;
    border-radius: 50%;
    background-color: var(--background-color);
    padding-bottom: 100%;

    &__one {
      background-image: url(//cdn.shopify.com/s/files/1/0560/7233/9630/t/6/assets/round-border.svg?v=145215069697438703451636518530);
      width: var(--ring1-size);
      padding-bottom: var(--ring1-size);
      background-size: 100% 100%;
    }
    &__two {
      width: var(--ring2-size);
      padding-bottom: var(--ring2-size);
      background-color: #fff;
    }
    &__one,
    &__two {
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__content {
      background-image: url(//cdn.shopify.com/s/files/1/0560/7233/9630/t/6/assets/round-dotted-border.svg?v=129211273166982323681636518530);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10%;
      width: var(--content-size);
      height: var(--content-size);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--text-color-secondary);
      background-size: 100% 100%;

      h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        margin: 0 auto 19px;
      }
      h2 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        margin: 0 auto 32px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        margin: 0 auto 37px;
      }
      .back-button {
        width: auto;
        height: 30px;
        min-height: 30px;
        padding: 0 18px;
        font-size: 14px;
      }
    }
  }

  @include media-md-down {
    .ring {
      &__content {
        padding: 5%;

        h1,
        h2 {
          margin: 0;
        }
        p {
          margin: 0 auto 14px;
        }
      }
    }
  }

  @include media-sm-up {
    width: 90%;

    .ring {
      &__one {
        --ring-one-size: 70%;
      }
      &__two {
        --ring2-size: 65.5%;
      }
      &__content {
        --content-size: 60.5%;
      }
    }
  }

  @include media-md-up {
    width: 70%;
  }

  @include media-xl-up {
    width: 60%;
  }

  @include media-sm-up {
    width: 90%;

    .ring {
      &__one {
        --ring1-size: 70%;
      }
      &__two {
        --ring2-size: 65.5%;
      }
      &__content {
        --content-size: 60.5%;
      }
    }
  }

  @include media-md-up {
    width: 70%;
  }

  @include media-xl-up {
    width: 60%;
  }

  @include media-xxl-up {
    width: 50%;
  }
}
</style>
