import { createApp } from './main'
import { LOG_LEVEL_QUERY_STRING, getQueryString, cloneDeep } from './utils'
import Logger from '@/services/log'
import { runTaskOnIdleTime } from './utils/idlize'
import Analytics from './services/analytics'
import LocationService from './services/location'
import Provider from './provider'
import CacheService from './services/cache'

runTaskOnIdleTime(() => {
  Analytics.init()
})
runTaskOnIdleTime(() => {
  LocationService.init()
})
runTaskOnIdleTime(() => {
  Provider.setProviderName('shopify')
  Provider.init()
})
CacheService.init()

const logLevel = getQueryString(LOG_LEVEL_QUERY_STRING)
// because we use store from server so we dont care the value of isMobile. It will be replaced by the value from the server
const { app, router, store } = createApp({
  isMobile: true,
  isPhone: true,
  logLevel: parseInt(logLevel || '') || 0,
  url: window.location.href,
})
const storeInitialState = (window as any).__INITIAL_STATE__
if (storeInitialState) {
  const clonedStoreInitialState = cloneDeep(storeInitialState)
  Logger.log('Store initial state', storeInitialState)
  store.state.value = clonedStoreInitialState
}

// wait until router is ready before mounting to ensure hydration match
router.isReady().then(() => {
  app.mount('#app')
})
