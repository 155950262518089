import Logger from '@/services/log'
import { isOnServer } from './ssr'

const logLevel = isOnServer ? 1 : 3
const timeLogList = new Map<string, number>()

export function timeStart(label: string) {
  const now = performance.now()
  timeLogList.set(label, now)
}

export function timeEnd(label: string) {
  const now = performance.now()
  const start = timeLogList.get(label)
  Logger.log('[Timing End]', label, `${now - (start || 0)}ms`, now, logLevel)
  timeLogList.delete(label)
}

export function timeLog(label: string) {
  const now = performance.now()
  const start = timeLogList.get(label)
  Logger.log('[Timing log]', label, `${now - (start || 0)}ms`, now, logLevel)
}
