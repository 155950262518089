import { computed } from '@vue/reactivity'
import {
  onServerPrefetch,
  onMounted,
  watch,
  ref,
  onUnmounted,
  useSSRContext,
  Ref,
} from 'vue'
import {
  useRoute,
  useRouter,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router'
import { ProductVariant, Image, Product, NullOrType, Collection } from '@/types'
import {
  getRawIdFromShopifyId,
  isCollectionHasTag,
  isProductHasType,
  isRestrictedInLocation,
  extractProductSKUFromHandle,
  isPersonalizeProduct,
  getCustomPersonalization,
  isProductHasTags,
  isProductHasTagStartWith,
  isProductTagRestrictedInLocation,
} from '@/utils/product'
import useProductStore from '@/store/product'
import useDiscountStore from '@/store/discount'
import useSettingStore from '@/store/setting'
import LocationService, { UserLocation } from '@/services/location'
import {
  CURRENCY,
  EVENT_TRACKING_PRODUCT_VIEWED,
  OPTION_TYPE_SIZE,
  OPTION_TYPE_STYLE,
  US_COUNTRY_CODE,
  SSR_CONTEXT_APPEND_TO_HEAD_META_TAGS,
  SSR_CONTEXT_APPEND_TO_HEAD_IMAGE_META_TAGS,
  SSR_CONTEXT_APPEND_TO_HEAD_JSON_LD,
  QUERY_STRING_PRODUCT_HANDLE,
  QUERY_STRING_UTM_COLLECTION_HANDLE,
  QUERY_STRING_NO_CACHE,
  SSR_CONTEXT_APPEND_TO_HEAD_CANONICAL_LINK,
  QUERY_STRING_UTM_TYPE_COLLECTION,
  SEVEN_DAYS_IN_MILLISECONDS,
  AB_TEST_CODE_ENABLE_CUSTOMILY_SAVE_PERSONALIZATION,
  AB_TEST_VARIANT_DISABLE,
  EVENT_ABTEST_ASSIGNED,
  unique,
  EVENT_TRACKING_PRODUCT_BLOCKED,
  EVENT_TRACKING_LOCATION_BLOCKED,
  AB_TEST_CODE_SHOW_PRODUCT_SHIPPED_FROM_OPTION,
  AB_TEST_VARIANT_DEFAULT_US,
  OPTION_TYPE_SHIPPED_FROM,
  AB_TEST_VARIANT_DEFAULT_OVERSEA,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
  GOOGLE_BUSINESS_VERTICAL,
  DONATION_PREFIX,
  AB_TEST_NEW_PRICE_BASE_ON_PRODUCT_TYPE,
  AB_TEST_VARIANT_NEW_PRICE,
  PRODUCT_TAG_EXPRESS_SHIPPING_PREFIX,
  PRODUCT_ID_TRACKING_BASE_ONE_PRODUCT_VARIANT_SKU,
  PRODUCT_TAG_EXPRESS_SHIPPING_CHINA,
  PRODUCT_HANDLE_CAMP_WIN,
  NEW_VARIANTS_CAMP_WIN_SIP,
  NEW_VARIANT_DEFAULT_CAMP_WIN_SIP,
  NEW_VARIANTS_CAMP_WIN_SIB,
  NEW_VARIANT_DEFAULT_CAMP_WIN_SIB,
} from '@/utils'
import { buildCanonicalURL, buildQueryString } from '@/utils/http'
import { buildCollectionUrl } from '@/utils/product'
import Analytics from '@/services/analytics'
import { usePage } from '@/composables/page'
import { appendToHead } from '@/composables/appendToHead'
import { useMetaTag } from '@/composables/metaTag'
import { isOnServer } from '@/utils/ssr'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import Logger from '@/services/log'
import { useFetchData } from '@/composables/fetchData'
import useRouteStore from '@/store/route'
import { useABTest } from '@/composables/useABTest'
import { useProductRecentlyViewedItems } from './ProductRecentlyViewed'
import useUserStore from '@/store/user'
import { useProductShipping } from './useProductShipping'
import useMediaCampaignStore from '@/store/media'
import useRequestLocationStore from '@/store/requestLocation'
import { useUser } from '@/composables/useUser'

export const useProduct = () => {
  const isProductRestrictedInLocation = ref(false)
  const route = useRoute()
  const router = useRouter()
  const productHandle = computed(() => route.params.productHandle || '')
  const productStore = useProductStore()
  const discountStore = useDiscountStore()
  const settingStore = useSettingStore()
  const userStore = useUserStore()
  const routeStore = useRouteStore()
  const mediaCampaignStore = useMediaCampaignStore()
  const requestLocation = useRequestLocationStore()
  const { segmentingUser } = useUser()

  const { getRecentlyViewedItems } = useProductRecentlyViewedItems()
  const { getABTestActivedVariantByCode } = useABTest()
  const { renderImageMetaTags } = useMetaTag()
  const { setPageTitle, setPageDescription } = usePage()
  const { initiateProductShipping, isEnableShippedFromOption } =
    useProductShipping()

  const product = computed(() => productStore.product)
  const selectedVariant = computed(() => productStore.selectedVariant)
  const isDefaultVariant = computed(() => {
    const firstVariant = product.value.variants[0]
    if (firstVariant && selectedVariant.value) {
      return firstVariant.id == selectedVariant.value.id
    }
    return false
  })

  const discountApplied = computed(() => {
    if (!product.value) return null
    if (!selectedVariant.value) return null

    if (
      discountStore.tagDiscountCode &&
      discountStore.tagDiscountCodeApplication &&
      isProductHasTags(product.value, [discountStore.tagDiscountCode])
    ) {
      return discountStore.applyTagDiscountCode(
        product.value,
        selectedVariant.value.priceV2?.amount || 0,
        selectedVariant.value.compareAtPriceV2?.amount || 0,
        isDefaultVariant.value
      )
    }
    return discountStore.applyDiscount(
      product.value,
      selectedVariant.value.priceV2?.amount || 0,
      selectedVariant.value.compareAtPriceV2?.amount || 0,
      selectedVariant.value.sku
    )
  })

  const discountAppliedFirstProductVariant = computed(() => {
    if (!product.value) return null
    const firstProductVariant = product.value.variants[0]
    if (!firstProductVariant) return null

    if (
      discountStore.tagDiscountCode &&
      discountStore.tagDiscountCodeApplication &&
      isProductHasTags(product.value, [discountStore.tagDiscountCode])
    ) {
      return discountStore.applyTagDiscountCode(
        product.value,
        firstProductVariant.priceV2?.amount || 0,
        firstProductVariant.compareAtPriceV2?.amount || 0,
        firstProductVariant
      )
    }
    return discountStore.applyDiscount(
      product.value,
      firstProductVariant.priceV2?.amount || 0,
      firstProductVariant.compareAtPriceV2?.amount || 0,
      firstProductVariant.sku
    )
  })

  const isExpressShipping = computed(() => {
    return (
      isProductHasTagStartWith(
        productStore.product,
        PRODUCT_TAG_EXPRESS_SHIPPING_PREFIX
      ) &&
      requestLocation.countryCode?.toLowerCase() ==
        US_COUNTRY_CODE.toLowerCase()
    )
  })

  const isExpressShippingChina = computed(() => {
    return (
      isProductHasTagStartWith(
        productStore.product,
        PRODUCT_TAG_EXPRESS_SHIPPING_PREFIX
      ) && hasExpressShippingChinaProductTag.value
    )
  })

  const hasExpressShippingChinaProductTag = computed(() => {
    return isProductHasTags(productStore.product, [
      PRODUCT_TAG_EXPRESS_SHIPPING_CHINA,
    ])
  })

  const collectionTagType = computed(
    () => settingStore.shop?.collectionTagType || ''
  )
  const collectionTagNiche = computed(
    () => settingStore.shop?.collectionTagNiche || ''
  )
  const shouldLoadProductDescriptionAndShippingByVariantType = computed(
    () => productStore.shouldLoadProductDescriptionAndShippingByVariantType
  )
  const isNewPriceBaseOnProductType = computed(() => {
    const abtestVariant = getABTestActivedVariantByCode(
      AB_TEST_NEW_PRICE_BASE_ON_PRODUCT_TYPE
    )
    return abtestVariant == AB_TEST_VARIANT_NEW_PRICE
  })

  const isTrackingProductIdBaseOnVariantSku = computed(() => {
    return (
      settingStore.shop?.productTrackingProductIdBaseOn ==
      PRODUCT_ID_TRACKING_BASE_ONE_PRODUCT_VARIANT_SKU
    )
  })

  const isTrackingProductViewedSuffixId = computed(() => {
    if (!product.value) return false
    const productHandleItem =
      settingStore.productPage?.productViewedTrackingSetting?.productHandles?.find(
        (item) => item == product.value.handle
      )
    if (productHandleItem) return true
    return false
  })

  const suffixIdTypes = computed(() => {
    return (
      settingStore.productPage?.productViewedTrackingSetting?.suffixIdType || []
    )
  })

  const ssrContext = isOnServer && useSSRContext()
  const { fetchData } = useFetchData({
    fetchDataFunction: fetchProduct,
    checkNotFoundFunction: checkProductNotFound,
    setNotFoundFunction: setProductNotFound,
  })

  const utmCollection: Ref<Collection | null> = ref(null)

  const productSeeMoreCollections = computed(() => {
    const product = productStore.product
    if (!product) return undefined
    let typeCollection: Collection | undefined
    if (utmCollection.value) {
      // type collection from utm query string
      typeCollection = utmCollection.value
    }

    if (!typeCollection) {
      // type collection is the collection that has type
      typeCollection = product.collections.find((collection) => {
        return isCollectionHasTag(collection, collectionTagType.value)
      })
    }

    // if can't find any type collection, find the collection that have type in the handle
    if (!typeCollection) {
      typeCollection = product.collections.find((colleciton) => {
        return colleciton.handle.includes('type')
      })
    }
    let nicheCollection: Collection | undefined

    // if product is TM show TM collection else show niche collection
    if (productStore.isTMProduct) {
      nicheCollection = product.collections.find((collection) => {
        return settingStore.tmProductTags.some(
          (tag) =>
            isCollectionHasTag(collection, tag) &&
            !isCollectionHasTag(collection, 'type')
        )
      })
    }

    if (!nicheCollection) {
      nicheCollection = product.collections.find((collection) => {
        return isCollectionHasTag(collection, collectionTagNiche.value)
      })
    }

    if (!nicheCollection) {
      nicheCollection = product.collections.find(
        (collection) => collection.id != typeCollection?.id
      )
    }

    let similarNicheCollection: Collection | undefined

    const similarNicheCollectionHandles =
      settingStore.productPage?.similarNicheCollectionHandles || []

    if (similarNicheCollectionHandles?.length) {
      product.collections.forEach((collection) => {
        const item = similarNicheCollectionHandles.find(
          (item) => item.handle == collection.handle
        )
        if (item) {
          similarNicheCollection = item
          productStore.similarNicheCollectionHandle = item.handle || ''
          return
        }
      })
    }

    return {
      nicheCollection,
      typeCollection,
      similarNicheCollection,
    }
  })

  onServerPrefetch(async () => {
    await fetchData()
    chooseSelectedVariant()
    if (product.value) {
      setPageTitle(product.value.title)
      getDonationCodeFromProductTags()
    } else {
      return
    }
    segmentingUser()
    checkBlockedProduct()

    if (product.value.description) {
      setPageDescription(product.value.description)
    }

    injectMetaTagsAndProductJsonLdIntoHeadTag()
  })

  async function fetchProduct() {
    if (productHandle.value) {
      return await productStore.fetchProduct({
        handle: productHandle.value.toString(),
        isForceLoadNewData: !!route.query[QUERY_STRING_NO_CACHE],
      })
    }
    return null
  }

  function checkBlockedProduct() {
    if (
      product.value &&
      userStore.userBlockedProductTags &&
      userStore.userBlockedProductTags.length
    ) {
      if (isProductHasTags(product.value, userStore.userBlockedProductTags)) {
        Logger.log(
          'Locking product based on tags',
          userStore.userBlockedProductTags.join(),
          product.value.handle,
          1
        )
        setProductNotFound(true)
        setProductBlockedByUserSegment(true)
      }
    }
  }

  function checkProductNotFound(product?: NullOrType<Product>) {
    return !product
  }

  function setProductNotFound(isNotFound: boolean) {
    productStore.isProductNotFound = isNotFound
  }

  function updateSelectedVariant(
    selectedVariantInput: ProductVariant | undefined
  ) {
    productStore.selectedVariant = selectedVariantInput
  }

  function setProductBlockedByUserSegment(isBlocked: boolean) {
    productStore.isProductBlockedByUserSegment = isBlocked
  }

  function getDonationCodeFromProductTags() {
    if (!product.value?.tags?.length) return
    const donationTag = product.value.tags.find((tag) =>
      tag.includes(DONATION_PREFIX)
    )
    if (!donationTag) {
      mediaCampaignStore.donationCode = null
      return
    }
    const donationCode = donationTag.split('-')[1]

    if (donationCode) {
      mediaCampaignStore.donationCode = donationCode
    } else {
      mediaCampaignStore.donationCode = null
    }
    return donationCode
  }

  onMounted(async () => {
    trackingProductBlockedByUserSegment()

    if (productStore.isProductNotFound) return

    // re-fetch product if it is not exists
    if (!product.value) {
      await fetchData()
      getDonationCodeFromProductTags()
    } else {
      if (product.value.__cacheKey) {
        Logger.log('Product Page', 'Set product from server side to cache', 3)
        CacheService.instance?.set(
          product.value.__cacheKey,
          JSON.parse(JSON.stringify(product.value))
        )
      }
    }

    checkBlockedProduct()
    trackingProductBlockedByUserSegment()

    // after fetching data, check the product is notfound again
    if (productStore.isProductNotFound) {
      return
    }

    // all of these code below should not need to watch the product value
    // beacause it is guraanted that the product is loaded successfully by the code above
    fakeWindowMetaForSomeApps()
    setTypeCollectionFromUtm()

    // check if the product is restricted in this location
    LocationService.onLocationReady((location) => {
      try {
        isProductRestrictedInLocation.value = false
        checkNicheIsRestrcitedInLocation(location)
        checkProductSKUIsRestrictedInLocation(location)
        checkProductTagIsRestrcitedInLocation(location)
        if (isProductRestrictedInLocation.value) {
          trackingLocationBlockedProduct(location)
        }
      } catch (error: any) {
        Logger.error('Error on location ready', error)
        Analytics.error(error)
      }
    })

    chooseSelectedVariant()

    LocationService.onLocationReady((location) => {
      try {
        productStore.isProductOnlySellInUSA = false
        checkProductIsOnlySellInUSA(location)
      } catch (error: any) {
        Logger.error('Error on location ready', error)
        Analytics.error(error)
      }
    })

    watch(
      selectedVariant,
      () => {
        if (!selectedVariant.value) return
        router.replace({
          query: {
            ...route.query,
            variant: getRawIdFromShopifyId(selectedVariant.value.id),
          },
        })
        productStore.isSelectedVariantOnlySellInUSA = false
        LocationService.onLocationReady((location) => {
          if (checkVariantIsOnlySellInUSA(location)) {
            productStore.isSelectedVariantOnlySellInUSA = true
          }
        })
      },
      { immediate: true, deep: true }
    )

    // only fetch product shipping here just in case enable shipped from option
    if (isEnableShippedFromOption.value) {
      initiateProductShipping()
    }

    trackingABTestShowShippedFromOption()

    // wait a bit for the selected variant is updated then tracking the events
    setTimeout(() => {
      if (!product.value) return
      const selectedVariant = productStore.product?.variants?.length
        ? productStore.product?.variants[0]
        : null // Use the first variant for tracking instead of using selectedVariant
      if (!selectedVariant) return

      let item = {
        product_id: isTrackingProductIdBaseOnVariantSku.value
          ? selectedVariant?.sku
          : +getRawIdFromShopifyId(product.value.id),
        name: product.value.title,
        handle: product.value.handle,
        url: window.location.href,
        image_url: product.value.featuredImage?.src,
        type: product.value.productType,
        tags: product.value.tags,
        variant: getRawIdFromShopifyId(selectedVariant?.id),
        price: product.value.price || 0,
        value: product.value.price || 0,
        collections: product.value.collections.map(
          (collection) => collection.title
        ),
        is_personalize: isPersonalizeProduct(
          product.value,
          settingStore.shop?.productTagPersonalize || ''
        ),
        is_cloned_custom: !!getCustomPersonalization(
          product.value,
          settingStore.productPage?.customPersonalizations
        ),
        category: product.value.productType,
      }

      if (discountStore.campaignDiscountCode) {
        item = {
          ...item,
          ...{
            campaign_discount: discountStore.campaignDiscountCode,
            compare_price_discount: discountApplied.value?.compareAtPrice,
            price_discount: discountApplied.value?.price,
          },
        }
      }

      Analytics.track(EVENT_TRACKING_PRODUCT_VIEWED, {
        ...item,
        currency: CURRENCY,
        products: [item],
        items: [
          {
            id: item.product_id,
            name: item.name,
            price: item.price,
            google_business_vertical:
              settingStore.shop?.googleBusinessVertical ||
              GOOGLE_BUSINESS_VERTICAL,
          },
        ],
      })

      if (isTrackingProductViewedSuffixId.value && suffixIdTypes.value.length) {
        let suffixIds = []
        suffixIdTypes.value.forEach((item: string) => {
          const ids = item.split(',')
          suffixIds = [...suffixIds, ...ids]
        })

        if (suffixIds.length) {
          suffixIds.forEach((suffixId: string) => {
            const productId = Number(`${item.product_id}${suffixId}`)
            Analytics.track(EVENT_TRACKING_PRODUCT_VIEWED, {
              ...item,
              product_id: productId,
              currency: CURRENCY,
              products: [{ ...item, product_id: productId }],
              items: [
                {
                  id: productId,
                  name: item.name,
                  price: item.price,
                  google_business_vertical:
                    settingStore.shop?.googleBusinessVertical ||
                    GOOGLE_BUSINESS_VERTICAL,
                },
              ],
            })
          })
        }
      }
    })
  })

  onUnmounted(() => {
    productStore.product = null
    productStore.productDescription = null
    productStore.productShipping = null
    productStore.selectedVariant = null
    productStore.productRecommendation = null
    productStore.isProductNotFound = false
    productStore.isProductBlockedByUserSegment = false
    productStore.didUserChooseVariant = false
    productStore.similarNicheCollectionHandle = ''
    window.meta = undefined
  })

  function trackingABTestShowShippedFromOption() {
    if (!product.value) return
    const abtestVariant = getABTestActivedVariantByCode(
      AB_TEST_CODE_SHOW_PRODUCT_SHIPPED_FROM_OPTION
    )
    if (abtestVariant === null) return
    const payload = {
      name: AB_TEST_CODE_SHOW_PRODUCT_SHIPPED_FROM_OPTION,
      variant: abtestVariant,
    }
    Analytics.track(
      EVENT_ABTEST_ASSIGNED,
      payload,
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
    Logger.log('Assigned AB Test', payload, 1)
  }

  function getSelectedVariantWithABTest() {
    if (!product.value) return
    const abtestVariant = getABTestActivedVariantByCode(
      AB_TEST_CODE_SHOW_PRODUCT_SHIPPED_FROM_OPTION
    )
    if (abtestVariant === null) return

    let shippedFromValue = ''
    if (abtestVariant === AB_TEST_VARIANT_DEFAULT_US) {
      shippedFromValue = 'us'
    } else if (abtestVariant === AB_TEST_VARIANT_DEFAULT_OVERSEA) {
      shippedFromValue = 'overseas'
    } else {
      return
    }

    const selectedVartiant = product.value.variants?.find((variant) => {
      const shippedFromOption = variant.selectedOptions?.find(
        (option) => option.name === OPTION_TYPE_SHIPPED_FROM
      )
      if (!shippedFromOption) return
      return shippedFromOption.value.toLowerCase() === shippedFromValue
    })
    return selectedVartiant
  }

  function fakeWindowMetaForSomeApps() {
    const abtestVariant = getABTestActivedVariantByCode(
      AB_TEST_CODE_ENABLE_CUSTOMILY_SAVE_PERSONALIZATION
    )
    if (abtestVariant === null) return
    const payload = {
      name: AB_TEST_CODE_ENABLE_CUSTOMILY_SAVE_PERSONALIZATION,
      variant: abtestVariant,
    }
    Analytics.track(
      EVENT_ABTEST_ASSIGNED,
      payload,
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
    Logger.log('Assigned AB Test', payload, 1)

    if (abtestVariant === AB_TEST_VARIANT_DISABLE) {
      return
    }

    if (!product.value) return
    window.meta = {
      page: {
        pageType: 'product',
        resourceType: 'product',
        resourceId: +getRawIdFromShopifyId(product.value.id),
      },
      product: {
        id: +getRawIdFromShopifyId(product.value.id),
        gid: product.value.id,
        vendor: product.value.vendor,
        type: product.value.productType,
        variants: product.value.variants?.map((variant) => ({
          id: +getRawIdFromShopifyId(variant.id),
          price: +(variant.price || 0) * 100,
          name: variant.title,
          public_title: variant.title,
          sku: variant.sku,
        })),
      },
    }
  }

  function chooseSelectedVariant() {
    let newSelectedVariant = undefined
    if (!product.value?.variants?.length) {
      newSelectedVariant = undefined
      updateSelectedVariant(newSelectedVariant)
      return
    }
    let variantId = route.query.variant
    if (!variantId) {
      if (isEnableShippedFromOption) {
        newSelectedVariant = getSelectedVariantWithABTest()
      }
      if (!newSelectedVariant) {
        if (isNewPriceBaseOnProductType.value) {
          // get variant selected default for logic new price base on product type
          const newSelectedVariantDefault = product.value.variants.find(
            (variant) => variant.title.split(' / ')[0].endsWith('.')
          )
          newSelectedVariant =
            newSelectedVariantDefault || product.value.variants[0]
        } else {
          newSelectedVariant = product.value.variants[0]
        }
      }

      updateSelectedVariant(newSelectedVariant)
      return
    }
    variantId = `gid://shopify/ProductVariant/${variantId}`
    newSelectedVariant =
      product.value.variants.find((variant) => variant.id === variantId) ||
      product.value.variants[0]
    updateSelectedVariant(newSelectedVariant)
    productStore.didUserChooseVariant = true
  }

  function checkNicheIsRestrcitedInLocation(location: UserLocation | null) {
    if (!productStore.productNiche) return
    const restrictedNiches =
      settingStore.locationRestricted?.TMRestricted.niches

    if (
      isRestrictedInLocation(
        productStore.productNiche,
        restrictedNiches,
        location
      )
    ) {
      isProductRestrictedInLocation.value = true
    }
  }

  function checkProductSKUIsRestrictedInLocation(
    location: UserLocation | null
  ) {
    const restrctedProductSKUs =
      settingStore.locationRestricted?.TMRestricted.products
    if (
      isRestrictedInLocation(
        productStore.productSKU,
        restrctedProductSKUs,
        location
      )
    ) {
      isProductRestrictedInLocation.value = true
    }
  }

  function checkProductTagIsRestrcitedInLocation(
    location: UserLocation | null
  ) {
    const restrctedProductTags =
      settingStore.locationRestricted?.TMRestricted.tags

    if (
      isProductTagRestrictedInLocation(
        productStore.product?.tags,
        restrctedProductTags,
        location
      )
    ) {
      isProductRestrictedInLocation.value = true
    }
  }

  function checkProductIsOnlySellInUSA(location: UserLocation | null) {
    const productTypesOnlySellInUSA =
      settingStore.locationRestricted?.onlySellInUSA.productTypes
    if (!productTypesOnlySellInUSA || !product.value || !location) return
    if (
      isProductHasType(product.value, productTypesOnlySellInUSA) &&
      location.country_code.toLowerCase() !== US_COUNTRY_CODE.toLowerCase()
    ) {
      productStore.isProductOnlySellInUSA = true
    }
  }

  function checkVariantIsOnlySellInUSA(location: UserLocation | null) {
    const variantOnlySellInUSA =
      settingStore.locationRestricted?.onlySellInUSA.productVariants
    if (!variantOnlySellInUSA || !product.value || !location) return
    const optionTypeStyle = selectedVariant.value?.selectedOptions?.find(
      (selectedOption) => selectedOption.type === OPTION_TYPE_STYLE
    )
    const optionTypeSize = selectedVariant.value?.selectedOptions?.find(
      (selectedOption) => selectedOption.type === OPTION_TYPE_SIZE
    )
    let isVariantSellOnUSAOnly = false
    if (
      productStore.product?.productType
        ?.toLowerCase()
        .includes('canvas and poster')
    ) {
      if (!optionTypeStyle || !optionTypeSize) return

      for (let i = 0; i < variantOnlySellInUSA.length; i++) {
        const element = variantOnlySellInUSA[i]
        const typeIndex = element.variantTypes.findIndex(
          (elm) => elm.toLowerCase() == optionTypeStyle.value.toLowerCase()
        )
        if (typeIndex == -1) continue
        const sizeIndex = element.variantSizes.findIndex(
          (elm) => elm.toLowerCase() == optionTypeSize.value.toLowerCase()
        )
        if (sizeIndex == -1) continue
        isVariantSellOnUSAOnly = true
        break
      }
    } else {
      isVariantSellOnUSAOnly = checkVariantSelectedOptionTypeSellOnlyUSA()
    }

    if (
      isVariantSellOnUSAOnly &&
      location.country_code.toLowerCase() !== US_COUNTRY_CODE.toLowerCase()
    ) {
      return true
    }

    return false
  }

  function checkVariantSelectedOptionTypeSellOnlyUSA() {
    if (!shouldLoadProductDescriptionAndShippingByVariantType) return false
    const productTypesOnlySellInUSA =
      settingStore.locationRestricted?.onlySellInUSA.productTypes
    if (!productTypesOnlySellInUSA || !product.value) return false
    const optionTypeStyle = selectedVariant.value?.selectedOptions?.find(
      (selectedOption) => selectedOption.type === OPTION_TYPE_STYLE
    )
    if (!optionTypeStyle) return false

    const index = productTypesOnlySellInUSA.findIndex(
      (item) => item.toLowerCase() == optionTypeStyle.value.toLowerCase()
    )
    if (index != -1) return true

    return false
  }

  function renderProductJsonLd(product: Product) {
    const productJsonLd = JSON.stringify({
      ...{
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: product.title,
        image: product.images?.map((image: Image) => image.src),
        description: product.description,
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue:
            product.metafields &&
            getAttributeValue(
              product.metafields[0]?.value,
              'data-average-rating'
            ),
          reviewCount:
            product.metafields &&
            getAttributeValue(
              product.metafields[0]?.value,
              'data-number-of-reviews'
            ),
        },
        offers: product.variants?.map((item: ProductVariant) => ({
          '@type': 'Offer',
          sku: item.sku,
          priceCurrency: CURRENCY,
          price: item.price + '',
          availability: item.availableForSale
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
        })),
      },
      ...(routeStore.currentDomain
        ? {
            brand: {
              '@type': 'Brand',
              name: routeStore.currentDomain.split('.').slice(-2)[0],
            },
          }
        : {}),
    })

    const productJsonLdScript = `<script type="application/ld+json">${productJsonLd}</script>`

    return productJsonLdScript
  }

  function getAttributeValue(html: string, attribute: string) {
    if (!html || !html.length) return

    const attributeIndex = html.indexOf(attribute)
    if (attributeIndex < 0) return

    const EQUAL_SIGN_LENGTH = 1
    const WRAPPER_CHARACTER_ATRRIBUTE_VALUE_LENGTH = 1 // it is the character length of the single or double quotes: ' or "

    const wrapperCharacterAttributeValue = html.charAt(
      attributeIndex + attribute.length + EQUAL_SIGN_LENGTH
    )

    const attributeValueIndex =
      attributeIndex +
      attribute.length +
      EQUAL_SIGN_LENGTH +
      WRAPPER_CHARACTER_ATRRIBUTE_VALUE_LENGTH

    const closeWrapperCharacterIndex = html.indexOf(
      wrapperCharacterAttributeValue,
      attributeValueIndex
    )

    const attributeValue = html.slice(
      attributeValueIndex,
      closeWrapperCharacterIndex
    )

    return attributeValue
  }

  function injectMetaTagsAndProductJsonLdIntoHeadTag() {
    // inject meta tags and product json ld into head tag
    if (product.value && ssrContext) {
      const firstProductImage = product.value.images && product.value.images[0]
      const firstProductPriceAmount =
        product.value.variants && product.value.variants[0]?.priceV2?.amount

      let productMetaTags = `<meta property="og:type" content="product" />`

      if (productStore.isTMProduct) {
        productMetaTags += `<meta name="robots" content="noindex">`
      }

      productMetaTags += renderPriceMetaTag(firstProductPriceAmount)
      appendToHead(
        productMetaTags,
        SSR_CONTEXT_APPEND_TO_HEAD_META_TAGS,
        ssrContext
      )

      const productImageMetaTags = renderImageMetaTags(firstProductImage)
      appendToHead(
        productImageMetaTags,
        SSR_CONTEXT_APPEND_TO_HEAD_IMAGE_META_TAGS,
        ssrContext
      )

      const canonicalLink = buildCanonicalURL(
        `${routeStore.currentOrigin}/products/${product.value.handle}`
      )
      appendToHead(
        canonicalLink,
        SSR_CONTEXT_APPEND_TO_HEAD_CANONICAL_LINK,
        ssrContext
      )

      const productJsonLdScript = renderProductJsonLd(product.value)
      appendToHead(
        productJsonLdScript,
        SSR_CONTEXT_APPEND_TO_HEAD_JSON_LD,
        ssrContext
      )
    }
  }

  function renderPriceMetaTag(price: number | undefined) {
    if (!price) return

    return `<meta property="product:price:amount" content="${price}" />
           <meta property="product:price:currency" content="${CURRENCY}" />`
  }

  const setTypeCollectionFromUtm = async function () {
    const productHandle = productStore.product?.handle
    if (!productHandle) return
    const cacheKey = `spy_type_collection_${productHandle}`

    let utmTypeCollection = route.query[QUERY_STRING_UTM_TYPE_COLLECTION]

    if (utmTypeCollection) {
      CacheService.instance?.set(
        cacheKey,
        utmTypeCollection,
        SEVEN_DAYS_IN_MILLISECONDS
      )
    } else {
      const utmTypeCollectionCache = await CacheService.instance?.get(cacheKey)
      if (utmTypeCollectionCache) {
        utmTypeCollection = getJSONfromCacheResponse(utmTypeCollectionCache)
      }
    }

    if (utmTypeCollection) {
      const collection = productStore.product?.collections.find(
        (item) => item.handle === utmTypeCollection
      )
      if (collection) {
        utmCollection.value = collection
      }
    }
  }

  async function trackViewedNichesAndViewedProductType() {
    const recentlyViewedItems = await getRecentlyViewedItems()
    if (!recentlyViewedItems?.length) return

    const recentlyViewedItemsSKUs = recentlyViewedItems.map((product) => {
      const skuFromHandle = extractProductSKUFromHandle(product.handle)

      return skuFromHandle
    })

    const recentlyViewedItemsCode = recentlyViewedItemsSKUs.map((sku) => {
      // if skuFromHandle has last character is the letter, we will get 8 character
      const characterNumber = isNaN(Number(sku.slice(-1))) ? -8 : -7
      return sku.slice(characterNumber)
    })

    const recentylyViewedItemsCodeCompact = recentlyViewedItemsCode.slice(0, 5)

    let recentlyViewedNiches = recentlyViewedItemsCode.map((sku) => {
      return sku.substring(0, 3)
    })

    let recentlyViewedProductType = recentlyViewedItems
      .filter((product) => product.productType)
      .map((product) => product.productType)

    const recentlyViewedLimit = 3
    // remove duplicate then get 3 niches and productType recently viewed
    recentlyViewedNiches = unique(recentlyViewedNiches).slice(
      0,
      recentlyViewedLimit
    )
    recentlyViewedProductType = unique(recentlyViewedProductType).slice(
      0,
      recentlyViewedLimit
    )

    const VIEWED_NICHES_KEY = 'Viewed Niches'
    const VIEWED_PRODUCT_TYPE_KEY = 'Viewed Product Type'
    const VIEWED_PRODUCT_ITEMS_CODE_RECENTLY = 'recentlyViewedItemsCode'

    const userTraits = Analytics.getUserTraits()

    const isContainedViewedNiches = recentlyViewedNiches.every((item) =>
      userTraits[VIEWED_NICHES_KEY]?.includes(item)
    )
    const isContainedViewedProductType = recentlyViewedProductType.every(
      (item) => userTraits[VIEWED_PRODUCT_TYPE_KEY]?.includes(item)
    )
    const isContainedViewedItems =
      JSON.stringify(recentylyViewedItemsCodeCompact) ==
      JSON.stringify(userTraits[VIEWED_PRODUCT_ITEMS_CODE_RECENTLY])
    if (
      isContainedViewedNiches &&
      isContainedViewedProductType &&
      isContainedViewedItems
    )
      return

    const viewedNichesAndProductType = {
      [VIEWED_NICHES_KEY]: unique([
        ...recentlyViewedNiches,
        ...(userTraits[VIEWED_NICHES_KEY] || []),
      ]).slice(0, recentlyViewedLimit),
      [VIEWED_PRODUCT_TYPE_KEY]: unique([
        ...recentlyViewedProductType,
        ...(userTraits[VIEWED_PRODUCT_TYPE_KEY] || []),
      ]).slice(0, recentlyViewedLimit),
      [VIEWED_PRODUCT_ITEMS_CODE_RECENTLY]: recentylyViewedItemsCodeCompact,
    }
    Analytics.identify(viewedNichesAndProductType)
  }

  function trackingProductBlockedByUserSegment() {
    if (!product.value || !productStore.isProductBlockedByUserSegment) return

    let userSegmentsKey: string[] = []
    if (userStore.segments?.length) {
      userSegmentsKey = userStore.segments.map((segment) => segment.key)
    }

    Analytics.track(
      EVENT_TRACKING_PRODUCT_BLOCKED,
      {
        path: route.path,
        tags: product.value.tags,
        user_segments: userSegmentsKey,
        load_type: routeStore.loadType,
      },
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
  }

  function trackingLocationBlockedProduct(location: UserLocation | null) {
    if (!location) return

    Analytics.track(
      EVENT_TRACKING_LOCATION_BLOCKED,
      {
        path: route.path,
        user_ip: location.ip,
        country_code: location.country_code,
        country_name: location.country_name,
        region_code: location.region_code,
        region_name: location.region_name,
        city: location.city,
      },
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
  }

  return {
    product,
    productHandle,
    selectedVariant,
    discountApplied,
    discountAppliedFirstProductVariant,
    isProductRestrictedInLocation,
    productSeeMoreCollections,
    isExpressShipping,
    isExpressShippingChina,
    trackViewedNichesAndViewedProductType,
  }
}

export const redirectToUtmCollectionHandle = function (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const routeStore = useRouteStore()

  // const requestLocation = useRequestLocationStore()

  // const requestCountry = requestLocation?.countryCode
  // const productVariant = to.query?.variant
  const productHandle = to.params['productHandle']
  // try {
  //   if (PRODUCT_HANDLE_CAMP_WIN == productHandle && requestCountry != 'US') {
  //     const newVariantDefaultCampWin = routeStore.currentOrigin.includes(
  //       'silveryprints.com'
  //     )
  //       ? NEW_VARIANT_DEFAULT_CAMP_WIN_SIP
  //       : routeStore.currentOrigin.includes('silverybrand.com')
  //       ? NEW_VARIANT_DEFAULT_CAMP_WIN_SIB
  //       : NEW_VARIANT_DEFAULT_CAMP_WIN_SIP

  //     const newVariantsCampWin = routeStore.currentOrigin.includes(
  //       'silveryprints.com'
  //     )
  //       ? NEW_VARIANTS_CAMP_WIN_SIP.split(',')
  //       : routeStore.currentOrigin.includes('silverybrand.com')
  //       ? NEW_VARIANTS_CAMP_WIN_SIB.split(',')
  //       : [NEW_VARIANT_DEFAULT_CAMP_WIN_SIP]
  //     if (
  //       newVariantDefaultCampWin &&
  //       newVariantsCampWin.length &&
  //       !newVariantsCampWin.includes(productVariant)
  //     ) {
  //       const params = Object.assign({}, to.query, {
  //         ['variant']: newVariantDefaultCampWin,
  //       })
  //       next(`/products/${productHandle}?${buildQueryString(params)}`)
  //       return
  //     }
  //   }
  // } catch {
  //   // do nothing
  // }

  const utmCollectionHandle = to.query[
    QUERY_STRING_UTM_COLLECTION_HANDLE
  ] as string
  if (utmCollectionHandle) {
    routeStore.redirectFromPath = to.path

    const params = Object.assign({}, to.query, {
      [QUERY_STRING_UTM_COLLECTION_HANDLE]: utmCollectionHandle as string,
      [QUERY_STRING_PRODUCT_HANDLE]: productHandle,
    })
    next(
      `${buildCollectionUrl(utmCollectionHandle)}?${buildQueryString(params)}`
    )
    return
  }
  next()
  return
}
