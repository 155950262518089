import { useCookier } from '@/services/cookie'
import useSettingStore from '@/store/setting'
import useUserStore from '@/store/user'
import { UserSegment } from '@/types/user'
import { ONE_MONTH_IN_SECONDS } from '@/utils'
import { buildCookieKey } from '@/utils/cache'
import { useRoute } from 'vue-router'
import useRouteStore from '@/store/route'
import { isProductHasTags } from '@/utils/product'
import useProductStore from '@/store/product'
import { NullOrType, Product } from '@/types'

export function useUser() {
  const settingStore = useSettingStore()
  const route = useRoute()
  const userStore = useUserStore()
  const routeStore = useRouteStore()
  const productStore = useProductStore()

  const cookier = useCookier()
  const userSegmentCookieKey = buildCookieKey('user_segment_keys')

  function getUserSegments(product: NullOrType<Product>): UserSegment[] {
    const userSegmentKeysStringFromCookie =
      cookier.getCookie(userSegmentCookieKey)

    if (!settingStore.userSegments?.segments) return []
    // user is considered in a segment when:
    // - were segmented to that segment before
    // - OR visit the product that has tags satisfied the required tags of the segment
    // - AND has the query string of the current URL that satisfied the required query string
    return settingStore.userSegments.segments.filter((segment) => {
      // check if user were segmented before
      if (userSegmentKeysStringFromCookie) {
        const userSegmentKeysFromCookie =
          userSegmentKeysStringFromCookie.split(',')
        if (userSegmentKeysFromCookie.includes(segment.key)) return true
      }
      // check if current url or redirect url has required tag or product has tag
      if (segment.requiredProductTags && segment.requiredProductTags.length) {
        const pathHasRequiredTags = segment.requiredProductTags.some(
          (tag) =>
            route.path.includes(tag.toLowerCase()) ||
            routeStore.redirectFromPath.includes(tag.toLowerCase())
        )

        let productHasTags
        if (product) {
          productHasTags = isProductHasTags(
            product,
            segment.requiredProductTags
          )
        }
        if (!pathHasRequiredTags && !productHasTags) return false
      }
      // check if current url contains required query string
      if (segment.requiredQueryStrings && segment.requiredQueryStrings.length) {
        const requiredQueryStringsStandardize =
          segment.requiredQueryStrings.map((query) => {
            return {
              key: query.key.toLocaleLowerCase(),
              values: query.values.map((value) => value.toLocaleLowerCase()),
            }
          })
        for (let i = 0; i < requiredQueryStringsStandardize.length; i++) {
          const requriedQueryString = requiredQueryStringsStandardize[i]
          const queryStringValue = route.query[
            requriedQueryString.key
          ] as string
          if (!queryStringValue) return false

          const queryStringValueStandardize =
            queryStringValue.toLocaleLowerCase()
          const isContainsQueryString = requriedQueryString.values.some(
            (value) => {
              return queryStringValueStandardize.startsWith(value)
            }
          )
          if (!isContainsQueryString) return false
        }
      }

      return true
    })
  }

  function segmentingUser() {
    const userSegments = getUserSegments(productStore.product)
    const userSegmentKeysString = userSegments
      .map((segment) => segment.key)
      .join()
    cookier.setCookie(userSegmentCookieKey, userSegmentKeysString, {
      maxage: ONE_MONTH_IN_SECONDS,
    })
    userStore.segments = userSegments
  }

  return { getUserSegments, segmentingUser }
}
