import { isOnServer } from '@/utils/ssr'
import { parse, stringify } from 'worktop/cookie'
import { inject } from 'vue'
import type { Plugin } from 'vue'

export const cookieManagerInjectKey = '__sib-cookier__'

export interface CookieOptions {
  maxage?: number // in second
  expires?: Date | number | string
  samesite?: 'Lax' | 'Strict' | 'None'
  secure?: boolean
  httponly?: boolean
  domain?: string
  path?: string
}

const defaultCookieOptions = {
  path: '/',
}

export class CookieManager {
  currentCookies: Record<string, string>
  settedCookies: Array<{
    name: string
    value: string
    options?: CookieOptions
  }> = []

  constructor(cookieInput?: string) {
    const cookie = cookieInput || (isOnServer ? '' : document.cookie)
    this.currentCookies = parse(cookie)
  }

  getCookie(name: string) {
    if (!isOnServer) {
      this.currentCookies = parse(document.cookie)
    }
    return this.currentCookies[name]
  }

  setCookie(
    name: string,
    value: string,
    options: CookieOptions = defaultCookieOptions
  ) {
    if (isOnServer) {
      this.settedCookies.push({
        name,
        value,
        options,
      })
      return
    }
    document.cookie = stringify(name, value, options)
  }

  deleteCookie(name: string, options: CookieOptions = defaultCookieOptions) {
    // set a cookie to current date to make it expired immediately
    this.setCookie(
      name,
      '',
      Object.assign({}, options, {
        maxage: 0,
      })
    )
  }
}

export function createCookier(cookieInput?: string) {
  const cookieManager = new CookieManager(cookieInput)
  const cookier: Plugin & {
    cookier: CookieManager
  } = {
    cookier: cookieManager,
    install(app) {
      app.provide(cookieManagerInjectKey, cookieManager)
    },
  }
  return cookier
}

export function useCookier(): CookieManager {
  return inject(cookieManagerInjectKey)!
}
