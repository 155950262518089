import Analytics from '@/services/analytics'
import Logger from '@/services/log'
import { NullOrType } from '@/types'
import { getCurrentTimeStamp } from '@/utils'
import { ref, Ref } from 'vue'

interface FetchDataResponse<T> {
  fetchData: (...args: any[]) => Promise<T | null>
  loading: Ref<boolean>
  error: Ref<string | null>
  notFound: Ref<boolean>
  resetVariables: () => void
  data: Ref<NullOrType<T>>
}

interface FetchDataInput<T> {
  fetchDataFunction: (...args: any[]) => Promise<NullOrType<T>>
  withTimeStamp?: boolean
  checkNotFoundFunction?: (data: NullOrType<T>) => boolean
  setNotFoundFunction?: (isNotFound: boolean) => void
}

export function useFetchData<T>({
  fetchDataFunction,
  withTimeStamp,
  checkNotFoundFunction,
  setNotFoundFunction,
}: FetchDataInput<T>): FetchDataResponse<T> {
  const loading = ref(false)
  const error: Ref<string | null> = ref(null)
  const notFound = ref(false)
  const setNotFound = (isNotFound: boolean) => {
    notFound.value = isNotFound
    if (setNotFoundFunction && typeof setNotFoundFunction === 'function') {
      setNotFoundFunction(isNotFound)
    }
  }
  let lastActionTimeStamp = 0
  const data: Ref<NullOrType<T>> = ref(null)

  const fetchData = async (...args: any[]): Promise<T | null> => {
    const currentTimeStamp = getCurrentTimeStamp()
    lastActionTimeStamp = currentTimeStamp
    try {
      loading.value = true
      setNotFound(false)
      error.value = null
      const response = await fetchDataFunction(...args)
      if (withTimeStamp) {
        if (currentTimeStamp != lastActionTimeStamp) return null
      }
      loading.value = false
      if (
        typeof checkNotFoundFunction == 'function' &&
        checkNotFoundFunction(response)
      ) {
        setNotFound(true)
        data.value = null
        return data.value
      }
      data.value = response
      return data.value || null
    } catch (err: any) {
      Logger.error(
        'Failed to fetch data',
        { error: err, params: args },
        err.stack
      )
      Analytics.error(err)
      if (withTimeStamp) {
        if (currentTimeStamp != lastActionTimeStamp) return null
      }
      loading.value = false
      setNotFound(false)
      data.value = null

      if (typeof err === 'string') {
        error.value = err
        return data.value
      }
      if (err && err.message) {
        error.value = err.message
        return data.value
      }
      error.value = 'Some thing went wrong'
      return data.value
    }
  }

  const resetVariables = () => {
    loading.value = false
    // don't need to call setNotFound here because setNotFound function
    // usually aim to update store data that can be hydrate on the client
    notFound.value = false
    error.value = null
  }

  return {
    fetchData,
    loading,
    error,
    notFound,
    resetVariables,
    data,
  }
}
