<template>
  <div class="search">
    <div class="search__input-bar-desktop">
      <sib-input @focus="isShowPopup = true" placeholder="Search"></sib-input>
      <sib-button role="primary">
        <SearchIcon />
      </sib-button>
    </div>

    <div
      class="search__input-bar-mobile no-line-height"
      :class="isNewMenuIcon ? 'search__input-bar-mobile--primary' : ''"
    >
      <SearchIcon @click="isShowPopup = !isShowPopup" />
    </div>
    <client-only>
      <SearchPopup :show="isShowPopup" @update:show="isShowPopup = $event" />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import SearchIcon from '@/assets/images/icons/search.svg'
import SearchPopup from './SearchPopup.vue'
import useSettingStore from '@/store/setting'
import useRequestLocationStore from '@/store/requestLocation'
import { VN_COUNTRY_CODE } from '@/utils'

const settingStore = useSettingStore()
const requestLocation = useRequestLocationStore()

const isNewMenuIcon = computed(
  () =>
    !!(
      requestLocation.countryCode &&
      requestLocation.countryCode.toUpperCase() != VN_COUNTRY_CODE &&
      settingStore.pages?.general?.homePageVersion?.toUpperCase() == 'V2'
    )
)

const isShowPopup = ref(false)
</script>

<style lang="scss">
.search {
  display: flex;
  justify-content: center;
  align-items: center;

  &__input-bar-mobile--primary {
    svg path {
      --new-color-primary: #05a871;
      fill: var(--new-color-primary);
    }
  }

  @include media-mobile {
    &__input-bar-desktop {
      display: none;
    }
  }

  @include media-desktop {
    justify-content: flex-end;
    max-width: 450px;
    width: 100%;

    &__input-bar-mobile {
      display: none;
    }
  }

  &__input-bar-desktop {
    display: flex;
    width: 100%;
    padding-left: 2em;

    .sib-button {
      padding: 0;
      width: 3.5em;
      margin-left: 10px;

      &__content {
        display: flex;
      }
    }
  }
}
</style>
